import { PageProps, navigate } from "gatsby";
import * as React from "react";
import { FC, ReactNode, cloneElement, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import WS10Dark from "@cyberhub/theme";
import WS10Theme from "@vfuk/core-theme-ws10";

import { getStoredUserData } from "../../context/auth/authStorage";
import { useCampaignFormContext } from "../../context/forms/useForms";
import {
  PageWrapperProviders,
  Protected,
  capitalizeFactor,
  capitalizeFirstLetter,
  downtimePageStatus,
  isServer,
  setDowntimePageInformation,
  trackPageView
} from "../../lib/";
import mockServer from "../../mockServer";
import { PageContext } from "../../types";
import ErrorBoundary from "../ErrorBoundary";
import Tour from "../Tour";
import ExternalLibraries from "./components/ExternalLibraries/ExternalLibraries";
import { PageWrapperFooter } from "./components/PageWrapperFooter";
import PageWrapperHeader from "./components/PageWrapperHeader/PageWrapperHeader";
import { useTourQuery } from "./queries";
import * as Styled from "./styles/PageWrapper.styles";

WS10Theme.setBaseAssetLocation("/assets/ws10/");

WS10Dark.setBaseAssetLocation("/assets/ws10/");

interface PageWrapperProps extends Omit<PageProps, "children"> {
  // for unit testing  only
  tour?: boolean;
  children?: ReactNode;
  pageContext: PageContext;
}

type savedThemeType = "WS10" | "WS10Dark" | string | null;

interface TourStateInterface {
  isOpen: boolean;
  startTour: boolean | null;
}

const GlobalOverrides = ({ path }: { path: string }) => {
  const form = useCampaignFormContext();

  const isFormOpen = form?.formState?.show;
  const isModalOpen = form?.isModalOpen;
  const hasScore = form?.hasScore;

  return (
    <Styled.GlobalOverrides
      isFormOpen={isModalOpen || isFormOpen}
      isDashboard={path.includes("dashboard")}
      hasScore={hasScore}
    />
  );
};

const PageWrapper: FC<PageWrapperProps> = ({
  children,
  pageContext,
  tour = false,
  location,
  ...props
}: PageWrapperProps) => {
  const tourQuery = useTourQuery();
  const { pathname: path } = location || {
    pathname: "dashboard"
  };

  const params = !isServer ? new URLSearchParams(window.location.search) : null;
  const channelParam = params?.get("channel") ?? "";

  const pageName = capitalizeFirstLetter(path.split("/", 3)[2]);

  const [theme, toggleTheme] = useState<any>(WS10Dark);
  const [isLoading, setIsLoading] = useState(true);
  const [initialTour, setInitialTour] = useState<TourStateInterface>({
    isOpen: false,
    startTour: false
  });

  const { i18n } = useTranslation();

  const isDark = theme.name !== "WS10";
  const tourQueryTheme = isDark ? tourQuery.darkMode : tourQuery.lightMode;

  const tourSteps = tourQueryTheme?.edges.find((edge) => {
    return edge.node.node_locale === pageContext.locale;
  })?.node.tourSteps;

  const GlobalStyles = theme.globalStyles;

  useEffect(() => {
    if (process.env.GATSBY_USE_MOCKS === "true") {
      console.info("Starting Mock Rest Server");
      mockServer();
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      const savedTheme: savedThemeType = localStorage.getItem("CyberHub-Theme");
      if (savedTheme && ["WS10", "WS10Dark"].includes(savedTheme)) {
        toggleTheme(savedTheme === "WS10" ? WS10Theme : WS10Dark);
      }
      setIsLoading(false);
    }
    const isAuthenticated = getStoredUserData()?.isAuthenticated;

    if (!isLoading && isAuthenticated) {
      const tour = JSON.parse(localStorage.getItem("did-tour")!) || false;
      setInitialTour({ startTour: false, isOpen: !tour });
    }
  }, [isLoading, path]);

  useEffect(() => {
    localStorage.setItem("CyberHub-Theme", theme.name);
  }, [theme]);

  useEffect(() => {
    i18n.changeLanguage(pageContext.formattedLocale);
  }, [i18n, pageContext.formattedLocale]);

  useEffect(() => {
    const isDown = JSON.parse(process.env.GATSBY_IS_CYBERHUB_DOWN ?? "false");

    if (isDown) {
      navigate(`/${pageContext.formattedLocale}/downtime`);
      setDowntimePageInformation(downtimePageStatus.planned);
    }

    trackPageView({
      page_locale: pageContext.locale,
      page_name: pageName,
      page_channel: channelParam
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName]);

  const themeNotificationCallback = ({ action }: { action: string }): void => {
    if (action === "reset") {
      setInitialTour({ startTour: true, isOpen: false });
    }
  };

  const childrenProps: any = {
    theme,
    ...props,
    isLoading: isLoading,
    tourStarted: initialTour.startTour
  };

  return !isLoading ? (
    <PageWrapperProviders
      formattedLocale={pageContext?.formattedLocale}
      theme={theme}
      expirationModal={pageContext.expirationModal}
    >
      <GlobalStyles />
      <GlobalOverrides path={path} />
      <Helmet>
        <html lang={pageContext?.formattedLocale} />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          httpEquiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />

        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />

        <meta
          name="description"
          content="A digital platform giving Vodafone customers the ability to manage their security services, learn and buy with a seamless customer experience."
        />
        <meta name="robots" content="noindex" />

        <title>CyberHub - {capitalizeFactor(pageName)}</title>
      </Helmet>
      <Styled.Body>
        <PageWrapperHeader
          {...{
            pageContext,
            path,
            toggleTheme: () =>
              toggleTheme(theme.name === "WS10" ? WS10Dark : WS10Theme)
          }}
        />

        {React.isValidElement(children) ? (
          <ErrorBoundary>
            <Protected
              formattedLocale={pageContext.formattedLocale}
              isLoading={isLoading}
              path={path}
            >
              {/* TODO: Commenting for now
                {!path.includes("signIn") && (
                  <GetSupport locale={pageContext.formattedLocale} />
                )} */}
              {cloneElement(children, childrenProps)}
            </Protected>
          </ErrorBoundary>
        ) : null}

        {!path.includes("basket") && pageContext?.footerLinks && (
          <PageWrapperFooter
            footerLinks={pageContext.footerLinks}
            formattedLocale={pageContext.formattedLocale}
          />
        )}

        <Tour
          spotlightClicks
          containerId="notification"
          isClosableOnClick={true}
          show={(initialTour.isOpen || tour) && path.includes("dashboard")}
          inverse={isDark}
          steps={tourSteps || []}
          callback={(callbackProps) => themeNotificationCallback(callbackProps)}
          theme={theme}
        />

        <ExternalLibraries />
      </Styled.Body>
    </PageWrapperProviders>
  ) : null;
};

export default PageWrapper;
