import axios, { AxiosResponse } from "axios";

import { AuthState } from "../../context/auth/auth.types";
import { getStoredUserData } from "../../context/auth/authStorage";
// Todo: fix logic for DXL error later
import { errorMessages } from "../utilities/constants";

type FetchJsonResponseType = { response?: any; error?: any };

export const api = axios.create();

const baseContentfulPath = "https://api.contentful.com";

export const createError = (args: any): FetchJsonResponseType["error"] => {
  const { status, message } = args;
  return {
    error: {
      message: message,
      status: status || "error"
    }
  };
};

export function buildApiUrl(
  relativeUrl: string | undefined,
  replaceMainUrl: string | null = null,
  isMiddlewareCall: boolean = false
): string {
  if (replaceMainUrl) return `${replaceMainUrl}${relativeUrl}`;
  if (isMiddlewareCall)
    return `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/${relativeUrl}`;

  return `/api${relativeUrl}`;
}

/*
  Parse the options object and return with a request header 
*/
export function createOptionsWithApiHeaders(options: any): Record<string, any> {
  return {
    ...options,
    headers: {
      ...options.headers
    }
  };
}

/*
  handshake the API endpoint. 
*/

export async function fetchResponse(
  relativeUrl: string,
  options: any,
  replaceMainUrl: string | null = null
): Promise<FetchJsonResponseType> {
  try {
    const response = await api(
      buildApiUrl(relativeUrl, replaceMainUrl),
      createOptionsWithApiHeaders(options)
    );
    return { response };
  } catch (error) {
    throw error;
  }
}

// from other tools
export async function fetchJsonContentful(
  relativeUrl: string,
  options: Record<string, any>
): Promise<any> {
  return await fetch(
    buildApiUrl(relativeUrl, baseContentfulPath),
    createOptionsWithApiHeaders({
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${process.env.CONTENTFUL_API_KEY}`
      }
    })
  );
}

/*
  Fetch client with error handling 
*/
export async function fetchJson(
  relativeUrl: string,
  options: Record<string, any>
): Promise<any> {
  const { response, error } = await fetchResponse(relativeUrl, options);

  if (response) {
    return {
      data: response.data
    };
  }

  return createError(error);
}

/*
  run any api call with user rights
*/

export async function fetchJsonAuthorized(
  relativeUrl: string,
  options: any
): Promise<any> {
  const authState = getStoredUserData();
  const userId = (authState as AuthState)?.user?.userId;

  return fetchJson(`${relativeUrl}/${userId}`, options);
}

/**
 * since we're using mocked endpoints, below this comment
 * we'll find the real integration with the middleware
 * the functions above will be deleted after the
 * integration completion
 * @param relativeUrl:string This property is the url of the api call.
 * @param options:any This property overrides fetch parameters.
 * @param returnRequest?: boolean This property returns the entire request.
 * @example return await fetchCyberhubMiddleware("urlAPi", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/octet-stream"
    },
    body: JSON.stringify({
      fileName: fileName
    })
  }
 */
export async function fetchCyberhubMiddleware(
  relativeUrl: string,
  options?: any,
  returnRequest: boolean = false
): Promise<AxiosResponse["data"] | { error: any } | null> {
  const requestUrl = buildApiUrl(relativeUrl, null, true);
  const prepareOptions = createOptionsWithApiHeaders({
    ...options,
    credentials: "include",
    headers: {
      Accept: "application/json; charset=utf-8",
      ...options?.headers
    }
  });

  const response = await fetch(requestUrl, { ...prepareOptions });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(JSON.stringify(errorData));
  }

  const text = await response.text();
  if (response.status === 200 || response.status === 204) {
    if (text.trim() === "") {
      return null;
    }
  }

  return returnRequest ? response : JSON.parse(text);
}

export async function handleReactQueryError(response: Response) {
  if (!response.ok) {
    throw new Error(errorMessages.unexpected);
  }

  return await response.json();
}
