/* eslint-disable @typescript-eslint/no-unused-vars  */

/*
 * disabling eslint to make sure we can comment endpoints as needed
 */
import { Response, createServer } from "miragejs";

import { cybsafeMock } from "./lib/mocks/mockResponses";

const mockServer = (environment: string = "development") =>
  createServer({
    trackRequests: true,
    environment,
    useDefaultPassthroughs: true,
    routes() {
      // Allow unhandled requests on the current domain to pass through. https://md-scp.kampyle.com
      this.passthrough();
      this.logging = true;

      this.timing = 2000;

      /**
       * Back-end based Mocks down bellow!
       * change the GATSBY_USE_MOCKS env variable to true and start using it!
       * make sure to uncomment and comment as necessary.
       */

      // Cybsafe Mocks

      this.get(
        `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/cybsafe/reports/phishing`,
        () => {
          return cybsafeMock.phishing;
        }
      );
      this.get(
        `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/cybsafe/reports/awareness/overview`,
        () => {
          return cybsafeMock.awarenessOverview;
        }
      );
      this.get(
        `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/cybsafe/reports/awareness`,
        () => {
          return cybsafeMock.awareness;
        }
      );
      this.get(
        `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/cybsafe/reports/culture`,
        () => {
          return cybsafeMock.culture;
        }
      );

      // this.post(
      //   `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/authentication/token`,
      //   () => {
      //     return { id: "14", name: "Cyberhub Test" };
      //   }
      // );

      // this.get(
      //   `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/marketplace/product-offering`,
      //   () => {
      //     return productList;
      //   }
      // );

      // this.get(
      //   `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/threat-bulletin`,
      //   () => threatBulletin
      // );

      // this.get(
      //   `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/marketplace/subscriptions`,
      //   () => {
      //     const { user } = getStoredUserData();

      //     return user ? subscriptionListById[user.userId] : null;
      //   }
      // );

      // this.get(
      //   `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/scores/assessment`,
      //   (_, { queryParams }) => {
      //     if (queryParams.role === "industry") return assessmentIndustry;
      //     return assessmentCompany;
      //   }
      // );

      // this.get(
      //   `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/lookout/insights/`,
      //   () => lookoutResponse
      // );

      // this.get("/api/trendmicro", () => mockedTrendMicro());

      // this.get("/api/auth/companyInformation", () => {
      //   return companyInformation;
      // });

      // this.get("/marketplace/api/marketplace/v1/products/:id", () => product);

      // this.post("/api/snow/campaign", (_a, b) => {
      //   const { heading, ...formData } = JSON.parse(b.requestBody);
      //   captureCampaignInformation(formData, heading);
      //   return new Response(200, { response: "Success" });
      // });

      // this.get("/api/snow/agents", agentSlots);

      // this.get("api//snow/statuses/:id", async (_, request: any) => {
      //   const userId = request?.params?.id;
      //   return serviceStatusesByUserId[userId];
      // });

      // this.get("/api/snow/appointments/:id", async (_, request: any) => {
      //   const userId = request?.params?.id;

      //   return getBookedAppointments[userId];
      // });
      // ``;
      // this.delete("/snow/appointments/:id", async (request: any) => {
      //   const userId = request?.url?.split("/")[4];
      //   return getBookedAppointments[userId];
      // });

      // this.put("/snow/appointments/change", () => ({
      //   appointment: "123"
      // }));

      // this.post("/api/raise-support", () => ({ isSuccess: true }), {
      //   timing: 2000
      // });

      // this.post("/api/snow/service-request", () => {
      //   return new Response(200, { response: "Success" });
      // });

      // this.get("/request-status", () => requestStatusData);

      // this.post(
      //   "/cart/complete",
      //   () => new Response(200, { response: "Success" })
      // );

      // this.post("/api/cart/start", () => mockedCart);

      // this.get("/api/:userId/appointments/:serviceName", async (_, request) => {
      //   const params = {
      //     service: request.url.split("/")[4],
      //     userId: request.url.split("/")[2]
      //   };

      //   return await getCapturedCampaignInformation(params)
      //     .then((res) => res.json())
      //     .then((res) => res?.fields);
      // });

      this.passthrough("https://api.contentful.com/**");
      this.passthrough("https://smetrics.vodafone.co.uk/**");
      this.passthrough("https://udc-neb.kampyle.com/**");
      this.passthrough("https://tags.tiqcdn.com/**");
      this.passthrough("http://nebula-cdn.kampyle.com/**");
      this.passthrough("https://nebula-cdn.kampyle.com/**");
      this.passthrough("https://md-scp.kampyle.com/**");
      this.passthrough("https://cdn.cookielaw.org/**");
      this.passthrough("https://cdn.cookielaw.org//scripttemplates/**");
      this.passthrough("https://privacyportal-eu.onetrust.com/**");
      this.passthrough("https://dpm.demdex.net/**");
      this.passthrough("https://smetrics.vodafone.co.uk/**");
      this.passthrough(`${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/**`);
      this.passthrough("https://s206829829.t.eloqua.com/**");
      this.passthrough("https://s1525.t.eloqua.com/**");
    }
  });

export default mockServer;
