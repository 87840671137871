import { navigate } from "gatsby";

import { createSlice } from "@reduxjs/toolkit";

import { snowApi } from "../apis/snow";

const defaultState = {
  submitted: false,
  currentStep: 0,
  subStep: 0,
  error: false,
  values: [{}],
  newRequest: [""],
  required: [""]
};

export const servicesPage = createSlice({
  name: "servicesPage",
  initialState: {
    isFormVisible: false,
    cleanValues: [],
    form: defaultState
  },
  reducers: {
    resetState: (state) => {
      state.isFormVisible = false;
      state.cleanValues = [];
      state.form = defaultState;
    },
    initState: (state, { payload }) => {
      if (payload && payload) {
        state.form = { ...state.form, ...payload };
      }
    },
    navigateAction: (state, { payload }) => {
      if (payload && payload.location) {
        navigate(payload.location);
      }
    },
    changeRequestType: (state, { payload }) => {
      if (payload && payload) {
        const newState: any = { ...state };
        newState.form.newRequest = [];
        const arrayRequired: any = payload.dj$arguments[0].split(",");
        newState.form.requestType = arrayRequired[0];
        const required = arrayRequired.splice(1, arrayRequired.length);
        newState.form.required = required;
        state.form = newState.form;
      }
    },
    addAdditionalRequest: (state, { payload }) => {
      if (payload && payload.values) {
        const newState: any = { ...state };
        const filtered = [];

        if (
          payload.values.index === 0 &&
          newState.form.newRequest.length === 0
        ) {
          newState.form.newRequest = [];
          newState.form.newRequest.push(payload.values.newRequest);
          filtered.push(payload.values.newRequest);
        } else {
          newState.form.newRequest.forEach((el: string) => {
            filtered.push(el);
          });
          filtered.push(payload.values.newRequest);
          newState.form.newRequest = filtered;
        }
        state.form.newRequest = filtered;
        if (payload.values.required) {
          state.form.required = payload.values.required;
        }
      }
    },
    deleteAdditionalRequest: (state, { payload }) => {
      if (payload && payload.values) {
        const newState: any = { ...state };
        const filtered: any = [];
        newState.form.newRequest.forEach((el: unknown) => {
          if (el !== payload.values.newRequest) {
            filtered.push(el);
          }
        });
        newState.form.newRequest = filtered;
        state.form = newState.form;
        state.cleanValues = payload.cleanValues;
        if (payload.values.required) {
          state.form.required = payload.values.required;
        }
      }
    },
    addFields: (state, { payload }) => {
      if ((payload && payload.values) || payload.dj$arguments) {
        if (state.form.currentStep === 0) {
          state.form.values[0] = payload.values;
        } else {
          state.form.values[state.form.currentStep - 1] =
            payload.values || payload.dj$arguments[0];
        }
      }
    },
    changeStep: (state, { payload }) => {
      if (payload && payload.values) {
        const step = payload.asyncStep
          ? state.form.currentStep
          : state.form.currentStep - 1;

        state.form.values[step - 1] = payload.values;
      }

      state.form.currentStep =
        payload.step !== undefined ? payload.step : payload.dj$arguments[0];
    },
    changeSubStep: (state, { payload }) => {
      state.form.subStep =
        payload.step !== undefined ? payload.step : payload.dj$arguments[0];
    },
    hideFullScreenModal: (state, { payload }) => {
      state.isFormVisible = false;
      if (payload && payload.values) {
        state.form.values[state.form.currentStep - 1] = payload.values;
      }
    },
    showFullScreenModal: (state) => {
      state.isFormVisible = true;
      state.form.submitted = false;
    },
    setFormSubmitted: (state) => {
      state.form.submitted = true;
      state.form.error = false;
    },
    setFormError: (state) => {
      state.form.submitted = true;
      state.form.error = true;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      snowApi.endpoints.requestVirtualAdvisor.matchFulfilled,
      (state) => {
        state.form.submitted = true;
        state.form.error = false;
      }
    );
    builder.addMatcher(
      snowApi.endpoints.createCampaign.matchFulfilled,
      (state) => {
        state.form.submitted = true;
        state.form.error = false;
      }
    );
    builder.addMatcher(
      snowApi.endpoints.createServiceRequest.matchFulfilled,
      (state) => {
        state.form.submitted = true;
        state.form.error = false;
      }
    );
    builder.addMatcher(
      snowApi.endpoints.requestVirtualAdvisor.matchRejected,
      (state) => {
        state.form.submitted = true;
        state.form.error = true;
      }
    );
    builder.addMatcher(
      snowApi.endpoints.createCampaign.matchRejected,
      (state) => {
        state.form.submitted = true;
        state.form.error = true;
      }
    );
    builder.addMatcher(
      snowApi.endpoints.createServiceRequest.matchRejected,
      (state) => {
        state.form.submitted = true;
        state.form.error = true;
      }
    );
    builder.addMatcher(
      snowApi.endpoints.getAvailableAgentSlots.matchFulfilled,
      (state) => {
        state.form.currentStep = state.form.currentStep + 1;
      }
    );
    builder.addMatcher(
      snowApi.endpoints.getAvailableAgentSlots.matchRejected,
      (state) => {
        state.form.error = true;
      }
    );
  }
});

export const {
  hideFullScreenModal,
  navigateAction,
  showFullScreenModal,
  changeStep,
  setFormSubmitted,
  setFormError,
  changeSubStep,
  addFields,
  initState,
  changeRequestType,
  addAdditionalRequest,
  deleteAdditionalRequest,
  resetState
} = servicesPage.actions;
