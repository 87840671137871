import styled, { createGlobalStyle, css } from "styled-components";

import { Theme } from "@source-web/themes";

// @ts-ignore
import desktopLogoInverse from "../../../../../assets/darkTheme/brand/desktop/VFBusinessDesktopDark.svg";
// @ts-ignore
import mobileLogoInverse from "../../../../../assets/darkTheme/brand/mobile/VFBusinessMobileDark.svg";
// @ts-ignore
import tabletLogoInverse from "../../../../../assets/darkTheme/brand/tablet/VFBusinessTabletDark.svg";
// @ts-ignore
import desktopLogoLight from "../../../../../assets/lightTheme/brand/desktop/VFBusinessDesktopLight.svg";
// @ts-ignore
import mobileLogoLight from "../../../../../assets/lightTheme/brand/mobile/VFBusinessMobileLight.svg";
// @ts-ignore
import tabletLogoLight from "../../../../../assets/lightTheme/brand/tablet/VFBusinessTabletLight.svg";

// Corrects the font colour within the modals and the footer
export const GlobalOverrides = createGlobalStyle(
  (props: { theme: Theme; isFormOpen: boolean }) => css`
    html {
      [role="tablist"] {
        padding-left: 12px;
        & span,
        div {
          color: ${props.theme.name === "WS10" ? "black" : "white"};
        }
      }
    }
    body {
      overflow-x: auto;
      overflow-y: ${props?.isFormOpen ? `hidden` : `scroll`} !important;
      @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
        background-color: ${props.theme.name === "WS10"
          ? props.theme.color.monochrome1.default
          : props.theme.inverseColor.monochrome1.default};
      }
    }

    body * {
      color: ${props.theme.baseFont.color};
    }

    footer * {
      color: ${props.theme.name === "WS10"
        ? props.theme.color.monochrome4.default
        : props.theme.baseFont.color};
    }

    input:-webkit-autofill {
      transition: background-color 600000s 0s, color 600000s 0s;
    }

    .__floater__body {
      // tooltip body
      & div > div > div {
        // close icon
        & div:nth-child(1) {
          padding-bottom: 0px;
          padding-top: 0px;
        }

        // title and content
        & div:nth-child(2),
        div:nth-child(3) {
          padding-left: ${props.theme.spacing.fixed[2]}px;
        }

        // footer
        & div:nth-child(4) {
          //next button
          button:last-of-type {
            margin-left: ${props.theme.spacing.fixed[3]}px;
            background: ${props.theme.color.error.default};
          }
          //previous button
          button:first-of-type {
            margin-right: ${props.theme.spacing.fixed[3]}px;
          }
        }
        // content paragraph
        p {
          white-space: break-spaces;
        }
      }
    }
  `
);

export const LoginPlaceholder = styled.div`
  ${(props: any) => css`
    & > div {
      display: flex;
      visibility: hidden;
    }
    @media (max-width: ${props.theme.breakpoints.lg}px) {
      display: none;
    }
  `}
`;

export const VFBusinessLogo = styled.div`
  ${(props) => css`
    background-repeat: no-repeat;
    @media (width >= ${props.theme.breakpoints.lg}px) {
      height: 100px;
      width: 373px;
      background-image: ${props.theme.name === "WS10"
        ? `url(${desktopLogoLight})`
        : `url(${desktopLogoInverse})`};
    }
    @media (width >= ${props.theme.breakpoints.md}px) and (width < ${props.theme
        .breakpoints.lg}px) {
      height: 76px;
      width: 342px;
      background-image: ${props.theme.name === "WS10"
        ? `url(${tabletLogoLight})`
        : `url(${tabletLogoInverse})`};
    }
    @media (width < ${props.theme.breakpoints.md}px) {
      height: 64px;
      width: 199px;
      background-image: ${props.theme.name === "WS10"
        ? `url(${mobileLogoLight})`
        : `url(${mobileLogoInverse})`};
    }
  `}
`;

interface StyledOptionsContainerProps {
  theme: any;
  marginBottom?: number;
}

export const FixedContainer = styled.div`
  ${() => css`
    width: 100%;
    position: relative;
    z-index: 4;
    transition: all 0.6s;
    &.navigationFixed {
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 16%);
    }
  `}
`;

export const Header = styled.header`
  ${({ theme, marginBottom }: StyledOptionsContainerProps) => css`
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 16%);
    z-index: 10;
    position: relative;
    & > div,
    & > div > .fixedContainer {
      background: ${theme.color.monochrome1.default};
    }
    @media (max-width: ${theme.breakpoints.md}px) {
      & > div > .fixedContainer {
        background: ${theme.color.monochrome2.default};
        display: flex;
        justify-content: space-between;
      }
    }

    &.navigationFixed {
      margin-bottom: ${marginBottom}px;
    }
  `}
`;

export const OptionsContainer = styled.div`
  ${(props: any) => css`
    background-color: ${props.theme.name === "WS10"
      ? props.theme.color.monochrome6.default
      : props.theme.color.monochrome2.default};

    @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
      display: none;
    }

    & > div {
      gap: ${props.theme.spacing.fixed[3]}px;
      display: flex;
      justify-content: flex-end;
      margin: auto;
      max-width: ${props.theme.gridConfig.springWidth}px;

      & > div:first-of-type {
        border: none;
      }

      & > div:last-of-type {
        margin-right: ${props.theme.spacing.fixed[3]}px;
      }
    }
    & > div > div > div > span {
      color: white;
      & > span > span > svg {
        stroke: white;
      }
    }
    & > div > div > div > span:nth-child(2) {
      color: white;
    }
    & > div > div > div > a {
      color: white !important;
    }
  `}
`;

export const TitleContainer = styled.div`
  ${(props) => css`
    max-width: ${props.theme.gridConfig.springWidth}px;
    padding: 0 ${props.theme.spacing.fixed[3]}px;
    display: flex;
    justify-content: space-between;
    margin: auto;
  `}
`;

export const NavIconWrapper = styled.div(
  ({ theme, isActive }: { theme: Theme; isActive: boolean }) => {
    const color =
      theme.name === "WS10"
        ? theme.color.primary1.default
        : theme.color.monochrome6.default;

    const conditionalColor = isActive ? color : "transparent";
    return css`
      border-bottom: 3px solid ${conditionalColor};
      justify-content: center;
      height: 70px;
      display: flex;
    `;
  }
);
