import { navigate } from "gatsby";
import { FC, ReactElement, useEffect } from "react";

import { useAuth } from "../../context/auth/useAuth";

export const Protected: FC<{
  children: ReactElement;
  formattedLocale: string;
  path: string;
  isLoading: boolean;
}> = ({ children, formattedLocale, path, isLoading }) => {
  const auth = useAuth();

  useEffect(() => {
    const loginPath = `/${formattedLocale || "en"}/signIn`;
    const downtimePath = `/${formattedLocale || "en"}/downtime`;

    async function getAuthenticationState() {
      if (
        !auth?.authState?.isAuthenticated &&
        path !== "/" &&
        path !== loginPath &&
        path !== downtimePath &&
        !isLoading
      ) {
        navigate(loginPath);
      }
    }
    getAuthenticationState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.authState?.isAuthenticated, path, isLoading]);

  return children;
};
