import dayjs, { extend } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import * as ics from "ics";

import { Enums, INotification, notificationActions } from "../../lib";
import {
  ContentfulQueryNodeArray,
  FormattedNotification,
  NotificationContent,
  ServicesContentful
} from "./NotificationBuilder.types";

extend(utc);
extend(timezone);

export const dateTimeToDate = (dateTime: string) =>
  dayjs(dateTime, "YYYY-MM-DDTHH:mm:ssZ");

export function getNotificationAction({
  status = "default"
}: {
  status: string;
  isProfessionalService: boolean;
}) {
  return {
    [Enums.REACHED_LICENSE_LIMIT]: notificationActions.buy,
    [Enums.LOOKOUT_LICENSE_AVAILABLE]: notificationActions.redirect,
    [Enums.LOOKOUT_ALL_LICENSE_ACTIVATED]: notificationActions.buy,
    [Enums.VSDM_ALL_LICENSE_ENROLLED]: notificationActions.buy,
    [Enums.SSC_AVAILABLE]: notificationActions.redirect,
    normal: notificationActions.normal
  }[status];
}

const replaceCharacter = (
  replaceFor: string,
  entry: any,
  onboardingCall?: INotification["onboardingCall"]
) => {
  if (onboardingCall) {
    const date = dateTimeToDate(onboardingCall);
    return entry.replaceAll(
      "{onboarding_call}",
      `${date.date()}/${
        date.month() + 1
      }/${date.year()} at ${date.hour()}:${date.minute()}`
    );
  }
  return entry?.replaceAll("{service_name}", replaceFor);
};

export const NotificationBuilder = (
  notification: INotification,
  allNotifications: ContentfulQueryNodeArray<NotificationContent>,
  allServices: ContentfulQueryNodeArray<ServicesContentful>
): FormattedNotification | null => {
  if (notification?.read) return null;
  // Find the current service by serviceHlId
  const currentService: ServicesContentful = allServices?.nodes.find(
    (services) => String(services.serviceHlId) === notification.serviceId
  )!;

  // Find the current notification by notificationTypeId
  const filteredNotification: NotificationContent =
    allNotifications?.nodes?.find(
      (notifications) => notifications.status === notification.status
    )!;

  const action = getNotificationAction({
    isProfessionalService: currentService?.isProfessionalService,
    status: notification.status
  });

  // Returns the full Object
  return {
    action,
    href: filteredNotification?.href,
    notificationUniqueKey: notification.notificationUniqueKey,
    heading: replaceCharacter(
      currentService?.heading,
      filteredNotification?.heading
    ),
    dateReceived: dayjs.tz(notification.dateReceived).toString(),
    subHeading: replaceCharacter(
      currentService?.heading,
      filteredNotification?.subheading
    ),
    caption:
      filteredNotification?.caption &&
      replaceCharacter(
        currentService?.heading,
        filteredNotification?.caption,
        notification.onboardingCall
      ),
    buttonText: filteredNotification?.buttonText,
    serviceName: currentService?.heading,
    onboardingCall: notification.onboardingCall,
    status: notification.status,
    isProfessionalService: currentService?.isProfessionalService,
    serviceId: parseInt(notification.serviceId),
    read: notification.read
  };
};

export const generateIcs = (onboardingCall: string | any) => {
  const date = dateTimeToDate(onboardingCall);
  const dateArr: ics.DateArray = [
    date.year(),
    date.month(),
    date.date(),
    date.hour(),
    date.minute()
  ];

  ics.createEvent(
    {
      title: "Onboarding call with Accenture",
      start: dateArr,
      duration: { minutes: 60 }
    },
    (error, value) => {
      if (error !== undefined) {
        window.open("data:text/calendar;charset=utf8," + value);
      }
    }
  );
};
