import { graphql, useStaticQuery } from "gatsby";

export interface ContentfulTabEntry {
  label: string;
  order: number;
  contentfulid: string;
  node_locale: string;
}
export interface ContentfulTab {
  nodes: Array<ContentfulTabEntry>;
}

export interface ContentfulToggleEntry {
  node: {
    label: string;
    node_locale: string;
  };
}
export interface ContentfulToggle {
  edges: Array<ContentfulToggleEntry>;
}

export interface UseQueries {
  allContentfulTabs: ContentfulTab;
  allContentfulToggle: ContentfulToggle;
}

const useNavigationalTabsQuery = () => {
  const queries = useStaticQuery(graphql`
    query GetNavigationalTabsQuery {
      allContentfulTabs(filter: { page: { eq: "menu" } }) {
        nodes {
          label
          order
          contentfulid
          node_locale
        }
      }
      allContentfulToggle(filter: { friendlyName: { regex: "mode/" } }) {
        edges {
          node {
            label
            node_locale
          }
        }
      }
    }
  `);

  return queries;
};

export default useNavigationalTabsQuery;
