import styled from "styled-components";

import { Theme } from "@source-web/themes";

export const BasketItemContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1em;

  &:not(:last-child) {
    padding-bottom: 1.25em;
    margin-bottom: 1.25em;
    border-bottom: 1px solid
      ${(props: { theme: Theme }) => props.theme.color.monochrome6.default};
  }
`;

export const BasketItemContainerMobile = styled.div`
  display: flex;
  width: 100%;
  gap: 1em;

  &:not(:last-child) {
    padding-bottom: 1.25em;
    margin-bottom: 1.25em;
    border-bottom: 1px solid
      ${(props: { theme: Theme }) => props.theme.color.monochrome6.default};
  }
`;

export const BasketItemBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
export const BasketItemBodyMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
export const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  max-width: 50px;
  margin: 1.25em 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const QuantityAndPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1.25em;
`;

export const IconWrapperMobile = styled.div`
  width: 16px;
  height: 16px;
  max-width: 50px;
  margin: 0;
`;

export const QuantityAndPriceWrapperMobile = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  width: 100%;
  margin: 1.25em;
`;

export const PriceContainer = styled.div`
  max-width: 100%;
  text-align: center;
  display: flex;
  gap: 1em;
`;

export const PriceContainerMobile = styled.div`
  max-width: 100%;
  margin-top: 1.5em;
  text-align: center;
  display: flex;
  gap: 1.5em;
`;

export const QuantityContainerMobile = styled.div`
  @media (max-width: 768px) {
    padding-right: 1.5em;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    justify-content: flex-start;
    align-items: start;
    width: 65%;
    box-sizing: border-box;
  }

  @media only screen and (max-width: 375px) {
    padding-right: 2em;
    display: flex;
    flex-direction: row;
    margin-left: 0.75em;
    justify-content: flex-start;
    align-items: start;
    width: 65%;
    box-sizing: border-box;
  }
`;

export const PaymentType = styled.span`
  font-size: 14px;
  color: ${({ theme }: { theme: Theme }) => theme.color.monochrome5.default};
`;
