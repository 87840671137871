//@ts-nocheck

/*
#####################################
  TS check is disabled.
  MatrixTable throw TS errors 
  due to lack of TableRows/TableCells 
  not allowing Conditional Rendering
#####################################
*/
// Main Dependencies
import { useField } from "formik";
import * as React from "react";

import { LinkWithIcon } from "@source-web/link-with-icon";
// VFUK Components
import { MatrixTable } from "@source-web/matrix-table";
import { TableCell, TableRow } from "@source-web/table";
import { TextInput } from "@source-web/text-input";

import { WrapperSelectInput } from "../DynamicJourney/WrapperComponents/Inputs";
import { PointTableState, WrappedPointTableObjects } from "./PointTable.types";
// Local Imports
import * as Styled from "./style/PointTable.style";

interface PointTable {
  rowName: string;
  name: string;
  headings: {
    headingText: string;
    srVisible: boolean;
  };
  items: string[];
}

const EditableCellEntrie = ({
  entrie = null,
  inputProps,
  isEnabled,
  optionsSelectInput
}: WrappedPointTableObjects["EditableCell"]) => {
  const handleSelectInput = () => {
    if (isEnabled) {
      return (
        <Styled.SelectInputMFW>
          <WrapperSelectInput
            selectInput={{
              options: optionsSelectInput.options,
              placeholder: "Select a value",
              name: optionsSelectInput.name,
              value: inputProps.value,
              id: optionsSelectInput.name,
              onChange: (e) => inputProps.onChange(e)
            }}
          />
        </Styled.SelectInputMFW>
      );
    } else {
      return <span>{entrie}</span>;
    }
  };

  const handleTextInput = () => {
    if (isEnabled) {
      return <TextInput {...inputProps} />;
    } else {
      return <span>{entrie}</span>;
    }
  };

  return (
    <>
      <Styled.EditableCell isEnabled={isEnabled}>
        {optionsSelectInput?.options ? handleSelectInput() : handleTextInput()}
      </Styled.EditableCell>
    </>
  );
};

const WrappedPointTable = ({ name, headings, items, rowName }: PointTable) => {
  const [field, meta, { setValue }] = useField(name);
  const [typeFields, setFieldType] = React.useState([]);
  const defaultState: PointTableState = {
    tableDataObject: {},
    editMode: {
      isEditable: false,
      rowNumber: 0
    }
  };

  React.useEffect(() => {
    setFieldType(
      items.map((entry) =>
        typeof entry === "string"
          ? { name: entry }
          : { name: entry.name, options: entry.options }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editMode, setEditMode] = React.useState<PointTableState["editMode"]>(
    defaultState.editMode
  );
  const [tableDataObject, updateEntryValues] = React.useState<
    PointTableState["tableDataObject"]
  >(defaultState.tableDataObject);

  const updateTableEntries = (entries) => {
    setValue(entries);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateEntryValues({
      ...tableDataObject,
      [event.target.name]: event.target.value
    });
  };

  const handleDelete = (rowNumber, mode) => {
    if (mode === "cancel") {
      setEditMode(defaultState.editMode);
      updateEntryValues(defaultState.tableDataObject);
    }
    const currentData = field.value.filter(
      (entrie) => entrie.rowNumber !== rowNumber
    );

    currentData.forEach((el, index) => {
      if (editMode.rowNumber === el.rowNumber) {
        setEditMode({ rowNumber: index + 1 });
      }
      el.rowNumber = index + 1;
    });

    updateTableEntries(currentData);
  };

  const enterEditMode = ({ rowNumber }) => {
    setEditMode({ isEditable: true, rowNumber: rowNumber });
  };

  const handleUpdate = (mode, row) => {
    const currentData = [...field.value];
    if (mode === "save") {
      const entryIndex = field.value.findIndex(
        (entry) => entry.rowNumber === row.rowNumber
      );
      currentData[entryIndex] = tableDataObject;
      setEditMode(defaultState.editMode);
      updateEntryValues(defaultState.tableDataObject);
    } else {
      updateEntryValues(row);
      enterEditMode({ rowNumber: row.rowNumber });
    }
    setValue(currentData);
  };

  const onAddEntry = () => {
    if (editMode.isEditable) {
      alert(
        "A Cell is already being edited, save it before adding another one"
      );
      return;
    }
    const id = field?.value?.length + 1 || 1;

    const loopThroughItems = (total, current) => {
      let selected = current;

      if (typeof current !== "string") {
        selected = current.name;
        return { ...total, [selected]: current.options[0].value };
      }
      return { ...total, [selected]: "Insert a Value.." };
    };

    const newState = items.reduce(loopThroughItems, {});

    updateEntryValues({ ...newState, rowNumber: id });
    updateTableEntries([...field.value, { ...newState, rowNumber: id }]);
    enterEditMode({ rowNumber: id, isEditable: true });
  };

  return (
    <Styled.TableWrapper>
      <MatrixTable
        invertCollapse
        horizontalHeadings={field.value.map(
          (entry) => `${"#"}${entry.rowNumber}` || "#"
        )}
        verticalHeadings={headings}
      >
        {field.value.length ? (
          field.value?.map((entries) => {
            const editable =
              editMode.rowNumber === entries.rowNumber && editMode.isEditable;
            return (
              <TableRow key={entries?.rowNumber}>
                {Object.keys(entries).map((field, index) => {
                  if (field === "rowNumber") {
                    return (
                      <TableCell key={index}>
                        <Styled.ActionsWrapper>
                          <LinkWithIcon
                            appearance="primary"
                            text={editable ? "Save" : "Edit"}
                            icon={{
                              name: editable ? "save" : "edit",
                              justify: "left"
                            }}
                            onClick={() => {
                              handleUpdate(editable ? "save" : "edit", entries);
                            }}
                            size={1}
                          />
                          <LinkWithIcon
                            appearance="primary"
                            text={editable ? "Cancel" : "Delete"}
                            icon={{
                              name: editable ? "delete" : "delete",
                              justify: "left"
                            }}
                            onClick={() => {
                              handleDelete(
                                entries.rowNumber,
                                editable ? "cancel" : "delete"
                              );
                            }}
                            size={1}
                          />
                        </Styled.ActionsWrapper>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={field}>
                      <EditableCellEntrie
                        isEnabled={editable}
                        inputProps={{
                          state:
                            meta.touched && meta.error ? "error" : undefined,
                          name: field,
                          onChange,
                          placeholder: entries[field]
                        }}
                        entrie={entries[field]}
                        optionsSelectInput={
                          typeFields.filter(
                            (actualField) => actualField.name === field
                          )[0]
                        }
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableCell />
        )}
      </MatrixTable>
      <TableCell>
        <Styled.TableAction>
          <LinkWithIcon
            appearance="primary"
            text={`Add ${rowName ? rowName : "row"}`}
            icon={{
              name: "plus",
              justify: "left"
            }}
            onClick={() => onAddEntry()}
            size={1}
          />
        </Styled.TableAction>
      </TableCell>
    </Styled.TableWrapper>
  );
};

export default WrappedPointTable;
