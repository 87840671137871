import { SupportFormValues } from "../../../pageTemplates/support/components/SupportForm/SupportForm";
import {
  fetchCyberhubMiddleware,
  fetchJson,
  fetchJsonAuthorized
} from "../serviceUtils";
import { VirtualAdvisorArgs } from "../types";
import { SubscriptionStatus } from "./subscriptions.types";
import { IAppointment, INotification, ISubscription } from "./types";

export interface Report {
  id: String;
  title: String;
  format: String;
  report_type: String;
  created_at: String;
  completed_at: String;
  download_url: String;
}

export async function changeAppointment(arg: any) {
  return await fetchJson(`snow/appointments/change`, {
    method: "PUT",
    body: arg
  });
}

export async function getReports(
  userId: any,
  serviceName: string
): Promise<Report[]> {
  return await fetchJson(`/${userId}/appointments/${serviceName}`, {
    method: "GET"
  });
}

export function getProductSubscriptions(): Promise<Array<ISubscription>> {
  return fetchCyberhubMiddleware(
    `marketplace/subscriptions?status=${SubscriptionStatus.ACTIVE}`,
    {
      method: "GET"
    }
  );
}
export function updateNotification(
  notificationUniqueKey: string,
  requestBody: Record<string, any>
): Promise<INotification> {
  const encodedNotificationUniqueKey = encodeURIComponent(
    notificationUniqueKey
  );

  return fetchCyberhubMiddleware(
    `notifications/${encodedNotificationUniqueKey}`,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody)
    }
  );
}

export async function getRequestStatuses() {
  return await fetchJson("/request-status", {
    method: "GET"
  }).then((res) => res.data);
}

export async function getTrendMicroInsights() {
  return await fetchJson(`/trendmicro`, {
    method: "GET"
  });
}

export async function createServiceRequest(args: VirtualAdvisorArgs) {
  return await fetchJson("/snow/service-request", {
    method: "POST",
    data: JSON.stringify(args)
  });
}

export async function createCampaign(args: any) {
  return await fetchJson("/snow/campaign", {
    method: "POST",
    data: JSON.stringify(args)
  });
}

export async function requestVirtualAdvisor(args: VirtualAdvisorArgs) {
  return await fetchJson("/mail/v1/send/virtual-ciso", {
    method: "POST",
    body: JSON.stringify(args)
  });
}

export async function getAvailableAgentSlots() {
  return await fetchJson("/snow/agents", {
    method: "GET"
  });
}

export async function requestRaiseSupport(formData: SupportFormValues) {
  return await fetchJson("/raise-support", {
    method: "POST",
    data: JSON.stringify(formData)
  });
}

export function getActiveAppointments(): Promise<{
  data: { appointments: IAppointment[] };
}> {
  return fetchJsonAuthorized("/snow/appointments", {
    method: "GET"
  });
}

export async function submitCart(cartId: string) {
  return await fetchJson(`/cart/complete`, {
    method: "POST",
    data: JSON.stringify({
      cartId
    })
  });
}

export async function startCart() {
  return await fetchJson(`/cart/start`, {
    method: "POST"
  });
}

export async function getThreatBulletin() {
  try {
    return await fetchCyberhubMiddleware("threat-bulletin", {
      method: "GET"
    });
  } catch (e) {
    throw e;
  }
}
