export const Enums = {
  ACTIVATE: "CYB-001",
  ONBOARDING_IN_PROGRESS: "CYB-002",
  COMPLETE_ONBOARDING: "CYB-003",
  REPORT_IN_PROGRESS: "CYB-004",
  READY_TO_USE: "CYB-005",
  REPORT_COMPLETE: "CYB-006",
  BOOK_WALKTHROUGH: "CYB-007",
  WALKTHROUGH_COMPLETE: "CYB-008",
  ORDER_IN_PROGRESS: "CYB-009",
  ORDER_CANCELLED: "CYB-010",
  UNAVAILABLE: "CYB-011",
  REACHED_LICENSE_LIMIT: "CYB-012",
  SSC_AVAILABLE: "CYB-013",
  LOOKOUT_LICENSE_AVAILABLE: "CYB-014",
  LOOKOUT_ALL_LICENSE_ACTIVATED: "CYB-015",
  VSDM_LICENSE_AVAILABLE: "CYB-016",
  VSDM_ALL_LICENSE_ENROLLED: "CYB-017"
};

export enum Roles {
  company = "company",
  industry = "industry"
}
