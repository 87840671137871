import {
  eventActions,
  eventCategories,
  eventLabels,
  journeyTypes
} from "./constants";
import { trackEvent } from "./trackingUtils";
import { TrackPageView } from "./types";

export function trackNotificationClick({
  journey_type,
  page_locale = "en"
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    event_category: eventCategories.notification,
    event_action: eventActions.onClick,
    event_label: eventLabels.ssc
  });
}

export function trackSscClick({ page_locale = "en" }: TrackPageView): void {
  trackEvent({
    page_locale,
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label: eventLabels.ssc,
    journey_type: journeyTypes.ssc
  });
}

export function trackVca({ page_locale = "en" }: TrackPageView): void {
  trackEvent({
    page_locale,
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label: eventLabels.vca,
    journey_type: journeyTypes.vca
  });
}

export function trackLogin({ page_locale = "en" }: TrackPageView): void {
  trackEvent({
    page_locale,
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label: eventLabels.login,
    journey_type: journeyTypes.login
  });
}

export function trackServicePage({
  page_locale = "en",
  journey_type
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    event_category: eventCategories.button,
    page_name: journey_type,
    event_action: eventActions.onClick,
    event_label: eventLabels.services
  });
}

export function trackMenuClick({
  page_locale = "en",
  journey_type
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    page_name: journey_type,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: eventLabels.services
  });
}

export function trackCampaignButtonClick({
  page_locale = "en",
  journey_type,
  page_name
}: TrackPageView) {
  trackEvent({
    page_locale,
    journey_type,
    page_name,
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label: eventLabels.campaign
  });
}

export function trackTour({ page_locale = "en" }: TrackPageView) {
  trackEvent({
    page_locale,
    page_name: "Dashboard",
    journey_type: journeyTypes.tour,
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label: eventLabels.campaign
  });
}

export const triggerMedalliaView = () => {
  if (
    window.KAMPYLE_ONSITE_SDK &&
    typeof window.KAMPYLE_ONSITE_SDK.updatePageView === "function"
  ) {
    window.closeForm = true;
    window.KAMPYLE_ONSITE_SDK.updatePageView();
  }
};

export function trackViewOnlineClick({
  page_locale = "en"
}: TrackPageView): void {
  trackEvent({
    page_locale,
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label: eventLabels.viewOnline,
    journey_type: journeyTypes.viewOnline
  });
}

export function trackDownloadClick({
  page_locale = "en"
}: TrackPageView): void {
  trackEvent({
    page_locale,
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label: eventLabels.download,
    journey_type: journeyTypes.download
  });
}

export function trackLearnMoreCybershopClick({
  page_locale = "en",
  journey_type
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: `Learn More - ${journey_type}`
  });
}

export function trackRequestCallbackCybershopClick({
  page_locale = "en",
  journey_type
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: `Request Callback - ${journey_type}`
  });
}

export function trackSubmitCallbackCybershopClick({
  page_locale = "en",
  journey_type
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: `Submit Callback Request - ${journey_type}`
  });
}

export function primaryButtonServiceClick({
  page_locale = "en",
  journey_type
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: journey_type
  });
}

export function secondaryButtonServiceClick({
  page_locale = "en",
  journey_type
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: journey_type
  });
}

export function packageTilePrimaryButtonClick({
  page_locale = "en",
  journey_type
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: journey_type
  });
}

export function supportFAQLinkClick({
  page_locale = "en"
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type: journeyTypes.supportFaqLink,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: eventLabels.supportFaqLink
  });
}

export function supportFormButtomClick({
  page_locale = "en"
}: TrackPageView): void {
  trackEvent({
    page_locale,
    journey_type: journeyTypes.supportRaiseIncidentButton,
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label: eventLabels.supportRaiseIncidentButton
  });
}

export function messagePopupButtonClick({
  page_locale = "en",
  journey_type,
  page_name
}: TrackPageView) {
  trackEvent({
    page_locale,
    journey_type,
    page_name,
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label: eventLabels.messagePopupButton
  });
}

export function dashboardLinksClick({
  page_locale = "en",
  journey_type,
  event_label
}: TrackPageView) {
  trackEvent({
    page_locale,
    journey_type,
    page_name: "Dashboard",
    event_category: eventCategories.link,
    event_action: eventActions.onClick,
    event_label
  });
}

export function dashboardCTAsButtonClick({
  page_locale = "en",
  journey_type,
  event_label
}: TrackPageView) {
  trackEvent({
    page_locale,
    journey_type,
    page_name: "Dashboard",
    event_category: eventCategories.button,
    event_action: eventActions.onClick,
    event_label
  });
}
