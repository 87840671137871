import * as React from "react";

import { elementPluginType } from "@vf-djr/renderer";

import { initState } from "../../../store/slices/servicesPage";

const InitStatePlugin: elementPluginType = (
  { __initState },
  Element,
  setupProperties
) => {
  React.useEffect(() => {
    setupProperties?.store.dispatch(initState(__initState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [__initState]);

  return Element;
};

export default InitStatePlugin;
