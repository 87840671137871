import { AuthState } from "./auth.types";

/*
  retrieve the data stored under session storage and return
*/
const getStoredUserData = (): AuthState =>
  JSON.parse(sessionStorage.getItem("storedAuthState")! || "{}");

/*
  store data from args 
*/
const storeUserData = (data: AuthState) =>
  sessionStorage.setItem("storedAuthState", JSON.stringify(data));

const clearUserData = () => sessionStorage.removeItem("storedAuthState");

export { storeUserData, getStoredUserData, clearUserData };
