// Main Dependencies
import { useField } from "formik";
import React from "react";

import { FieldWrapper } from "@source-web/field-wrapper";
// VFUK Components
import TextArea from "@source-web/text-area-input";

// Types
import { TextAreaWithLabelProps } from "../../WrapperComponents.types";
// Local Imports
import * as Styles from "../../styles/Wrappers.style";

const WrappedTextAreaWithLabel = ({
  fieldWrapper,
  textArea
}: TextAreaWithLabelProps) => {
  const [field, meta] = useField<string>(textArea.id);
  const textAreaProps = { ...field, ...textArea };
  return (
    <Styles.InputWrapper>
      <Styles.TextAreaWrapper width={50}>
        <FieldWrapper
          {...fieldWrapper}
          state={meta.touched && meta.error ? "error" : undefined}
          helpText={meta.touched && meta.error ? meta.error : undefined}
        >
          <TextArea
            {...textAreaProps}
            state={meta.touched && meta.error ? "error" : undefined}
          />
        </FieldWrapper>
      </Styles.TextAreaWrapper>
    </Styles.InputWrapper>
  );
};

export default WrappedTextAreaWithLabel;
