import React, { useEffect, useState } from "react";

import { startCart, submitCart } from "../../lib";
import { CartContext } from "./cartContext";
import { deleteCart, getCart } from "./cartManager";
import { storeCart } from "./cartStorage";
import { CartItem, ICartProvider, ICartState } from "./types";

const defaultState: ICartState = {
  price: 0,
  cartId: "",
  items: []
};

export function CartProvider({
  initialCartState = defaultState,
  children
}: ICartProvider) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [cartState, setCartState] = useState<ICartState>(initialCartState);

  const cartExists = Boolean(cartState?.items.length);

  useEffect(() => {
    if (!cartExists) {
      // If no order is set on mount, retrieve the order from storage (if it exists)
      const previousCartState = getCart();
      if (previousCartState) {
        setCartState(previousCartState);
      }
      if (previousCartState?.cartId) setIsReady(true);
    }
  }, [cartExists]);

  function updateCartState(update: ICartState) {
    setCartState(update);
    storeCart(update);
  }

  const createShoppingCart = async (newState: ICartState | null = null) => {
    if (isSubmitted) setIsSubmitted(false);
    const { data } = await startCart();
    const stateToBeFilled = newState ? newState : cartState;

    updateCartState({ ...stateToBeFilled, cartId: data?.id });
  };

  function openMiniCart() {
    setIsMiniCartOpen(true);
  }
  function closeMiniCart() {
    setIsMiniCartOpen(false);
  }

  function cleanCart() {
    deleteCart();
    setCartState(defaultState);
  }

  function handleNewPrice(items: ICartState["items"]): number {
    const productsInCartPrices = items?.map(
      (productsInCart) => productsInCart.price * productsInCart.quantity
    );

    const summedProductsPrices = productsInCartPrices?.reduce(
      (accumulator, current) => accumulator + current,
      0
    );

    return summedProductsPrices;
  }

  function removeProductFromCart(productId: string) {
    const cartWithoutProduct = cartState.items.filter(
      (product) => product.id !== productId
    );

    updateCartState({
      ...cartState,
      price: handleNewPrice(cartWithoutProduct),
      items: cartWithoutProduct
    });
  }

  async function addProductToCart({
    quantity,
    ...product
  }: CartItem & { quantity: number }) {
    if (!quantity) {
      removeProductFromCart(product.id);
      return;
    }

    const previousAddedProducts = cartState?.items.filter(
      (items) => items.id !== product.id
    );

    const incrementedProduct = {
      ...product,
      quantity,
      priceUpfront: "0"
    };

    const newState = {
      ...cartState,
      price: handleNewPrice([...previousAddedProducts, incrementedProduct]),
      items: [...previousAddedProducts, incrementedProduct]
    };

    if (!cartState?.cartId?.length) {
      await createShoppingCart(newState);
      return;
    }

    updateCartState(newState);
  }

  function getTotalItems() {
    const summedProducts = cartState.items?.reduce(
      (accumulator, current) => accumulator + current.quantity,
      0
    );

    return summedProducts;
  }

  async function onSubmitCart() {
    setIsLoading(true);
    await submitCart(cartState.cartId).then(() => {
      setIsLoading(false);
      setIsSubmitted(true);
    });
    cleanCart();
  }

  return (
    <CartContext.Provider
      value={{
        isReady,
        cartState,
        itemsAdded: cartState?.items?.length,
        isMiniCartOpen,
        isSubmitted,
        isLoading,
        getTotalItems,
        addProductToCart,
        cleanCart,
        removeProductFromCart,
        updateCartState,
        openMiniCart,
        closeMiniCart,
        onSubmitCart,
        createShoppingCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
