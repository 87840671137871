import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

interface StyledProps {
  theme: Theme;
}

export const PageWrapperFooter = styled.div`
  [data-component-name="Paragraph"] {
    margin: 0 40px;
  }
  [data-component-name="MinFooter"] {
    padding-bottom: 0;
  }
`;

export const CopyRight = styled.div`
  font-size: 0.8em;
  max-width: 1440px;
  margin: auto;
  line-height: normal;
  text-align: center;
  padding: 0 40px;
`;

export const FooterParagraph = styled.div`
  ${(props: StyledProps) => css`
    color: ${props.theme.color.monochrome1.default};
    padding-bottom: ${props.theme.spacing.fixed[6]}px;
    margin: auto;
  `}
`;

export const Footer = styled.footer`
  ${(props: StyledProps) => css`
    width: 100%;
    bottom: auto;
    position: static;
    background-color: ${props.theme.color.monochrome6.default};
  `}
`;
