import styled from "styled-components";

export const CartContainer = styled.div`
  & > div {
    padding: 0.75em 0;
  }
  position: absolute;
  overflow-y: scroll;
  max-height: 100vh;
  width: 100%;
  padding-right: 40px;
  padding-bottom: 20vh;

  @media (min-width${(props) => props.theme.breakpoints.lg}px) {
    padding-right: 30px;
    padding-bottom: 100px;
  }
`;

export const PriceContainer = styled.div`
  max-width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  gap: 1.5em;
`;

export const ButtonContainer = styled.div`
  justify-content: center;
  display: flex;
  gap: 1.5em;
`;
