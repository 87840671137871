import React from "react";

import { closeWidget } from "../../../../store/slices/pageWrapper";
import { PageContext } from "../../../../types";
import { LanguageSelector } from "../LanguageSelector";
import { PageHeaderNode } from "../PageWrapperHeader/PageWrapperHeader";
import LogoutButton from "./LogoutButton";

export function PageWrapperOptions({
  pageContext,
  pageHeader
}: {
  pageContext: PageContext;
  pageHeader: PageHeaderNode;
}) {
  const { logoutTitle, languageTitle } = pageHeader;

  return (
    <div>
      <LanguageSelector pageContext={pageContext} title={languageTitle} />
      <LogoutButton closeWidget={closeWidget} title={logoutTitle} />
    </div>
  );
}
