import React from "react";

import { Heading } from "@source-web/heading";
import { Modal } from "@source-web/modal";
import { Spacing } from "@source-web/spacing";

import { PrimaryButton } from "../PrimaryButton/";
import { ConfirmationModalProps } from "./ConfirmationModal.types";
import * as Styled from "./styles/ConfirmationModal.style";

const ConfirmationModal = ({
  isOpen,
  isCloseable,
  children,
  heading,
  confirmBtnProps,
  cancelBtnProps,
  onCloseCb
}: ConfirmationModalProps) => {
  return (
    <Modal
      overlayBlur
      srName="Confirmation Modal"
      isOpen={isOpen}
      isClosable={isCloseable}
      animate
      onCloseCb={onCloseCb}
    >
      <Styled.ModalContainer>
        <Heading {...heading} />
        {children && (
          <Spacing {...{ spacingLevel: { bottom: 7 } }}>{children}</Spacing>
        )}
        {confirmBtnProps || cancelBtnProps ? (
          <Styled.ButtonContainer>
            {cancelBtnProps ? <PrimaryButton {...cancelBtnProps} /> : null}
            {confirmBtnProps ? <PrimaryButton {...confirmBtnProps} /> : null}
          </Styled.ButtonContainer>
        ) : null}
      </Styled.ModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;
