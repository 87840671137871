import styled, { css } from "styled-components";

interface StyledProps {
  theme: any;
  width?: number;
}

export const FileInputField = styled.div`
  ${({ theme, error }: StyledProps & { error?: boolean }) => `
  justify-content: space-between;
  transition: 0.4s box-shadow ease;
  border: 1px solid ${
    error ? theme.color.error.default : theme.color.monochrome4.default
  };
  border-radius: 6px;
  background-color: ${theme.color.monochrome1.default};
  padding: 0 16px;
  height: 48px;
  width: 100%;
  margin-top: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  
  &:hover {
    cursor: pointer;
  }
  `}
`;

export const FileInputContainer = styled.div`
  ${(props) => `
  min-width: 136px;
  max-width: 432px;
  width: 100%;
   & label {
    & > span:first-child {
      display:flex;
       font-size:18px;
       font-weight:400;
       margin-bottom: ${props.theme.spacing.fixed[2]}px
      }
      & > small {
        display: flex;
        margin-bottom: ${props.theme.spacing.fixed[2]}px
    }  
  }
  & input:last-of-type {
    display:none;
  }
`}
`;

export const CalendarWrapper = styled.div`
  ${(props: StyledProps) => `
      display:flex;
      gap: ${props.theme.spacing.fixed[6]}px;
      @media only screen and (max-width: ${props.theme.breakpoints.md}px){
      flex-direction: column;
      };
  `}
`;

export const InputWrapper = styled.div`
  ${(props: StyledProps) => `
  padding-top: ${props.theme.spacing.fixed[3]}px;
  padding-bottom: ${props.theme.spacing.fixed[3]}px;
`}
`;

export const ToggleListWrapper = styled.div`
  & > div {
    max-width: 100%;
  }
  & li {
    padding: ${(props: StyledProps) => props.theme.spacing.fixed[2]}px 0;
  }
`;

export const TextAreaWrapper = styled.div`
  ${(props: StyledProps) => `
  max-width: ${props.width}em;
`}
`;

export const StyledFooterContainer = styled.div(
  ({
    isValid,
    theme,
    name
  }: StyledProps & { isValid?: boolean; name: string }) => css`
    & button[type="submit"] {
      background: ${isValid
        ? theme.color.primary1.default
        : theme.color.primary1.disabled};
    }

    & > div:first-of-type {
      background: ${name === "WS10"
        ? theme.color.monochrome1.default
        : theme.color.monochrome5.default};
    }

    & svg > circle {
      stroke: ${theme.color.monochrome3.default};
    }

    &:active {
      color: white;
      background-color: #e60000;
    }
  `
);

export const TimeSlotWrapper = styled.div`
  ${({ theme }: StyledProps) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 900px;
  border-radius: 10px;
  background-color: ${
    theme.name === "WS10"
      ? theme.color.monochrome3.default
      : theme.color.monochrome1.default
  };
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 18px 
  `}
`;

export const ErrorWrapper = styled.div`
  ${({ theme }: StyledProps) => `
  max-width: 900px;

  div > div > div > div:nth-child(2) {
    width: 100%;
    div {
      justify-content: space-between;
      width: 100%;
      display: flex;
      color: ${
        theme.name === "WS10"
          ? theme.color.primary1.default
          : theme.color.primary2.default
      };
    button {
      color: ${
        theme.name === "WS10"
          ? theme.color.primary1.default
          : theme.color.primary2.default
      }; 
      font-weight: 600;
    }
  }
  `}
`;

export const ParagraphWrapper = styled.div`
  ${(props: StyledProps) => `
  max-width: ${props.width}em;
  color: ${props.theme.color.monochrome6.default};
  `}
`;

export const FormHeadingWrapper = styled.div`
  ${() => `
  max-width: 900px;  
    `}
`;

export const TableContainer = styled.div`
  ${(props: StyledProps) => `
      @media only screen and (max-width: ${props.theme.breakpoints.md}px){
        padding-bottom: 4rem;  
      };
  width: 100%;
  padding-right: 3rem;  
    `}
`;

export const TableContainerSideBySide = styled.div`
  ${(props: StyledProps) => `
   display: flex;
      @media only screen and (max-width: ${props.theme.breakpoints.md}px){
         flex-direction: column;
      };
    `}
`;

export const TimeSlotsDateContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StepperContainer = styled.div`
  ${(props: StyledProps) => `
    @media only screen and (min-width: ${props.theme.breakpoints.md}px){
      margin-left: -2em;  
    };

    div > ul:first-child > li > div:first-child {
        span {
          color: unset;
        }
        color: unset;
      }
    }

  `}
`;

export const FlexDisplay = styled.div`
  display: flex;
`;

export const CheckboxListContainer = styled.div`
  ${(props) => `
   padding-bottom: ${props.theme.spacing.fixed[3]}px;
  `}
`;

export const CheckboxContainer = styled.div`
  ${(props) => `
   margin-bottom: ${props.theme.spacing.fixed[3]}px;
  `}
`;

export const SubStepperContainer = styled.div`
  padding-right: 3rem;
`;

export const SpanContainer = styled.span`
  display: flex;
  padding-top: 1rem;
`;

export const OnboardingCardWrapper = styled.div`
  ${(props) => `
  width: 75%;
  margin-top:${props.theme.spacing.fixed[7]}px;
  margin-bottom:${props.theme.spacing.fixed[7]}px;
  @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
     width:100%;
  };
  `}
`;

export const CustomCalendarPickerWrapper = styled.div<any>(
  (props) => css`
    //main container background fixes
    .react-datepicker__month-container {
      color: ${props.theme.color.monochrome6.default};
      background: ${props.theme.color.monochrome1.default};
      z-index: 101;
    }

    // selected date background color fix
    .react-datepicker__day--selected {
      color: ${props.theme.color.monochrome1.default};
      background: ${props.theme.color.primary1.default};
    }

    // disabled dates
    .react-datepicker__day--disabled {
      color: ${props.theme.color.monochrome3.disabled};
    }

    // month name color fix
    .react-datepicker__current-month {
      color: inherit;
    }
  `
);

export const PillWrapper = styled.div(
  (props: any) => css`
    max-width: 300px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1.25em;

    & > span {
      display: flex;
      justify-content: center;
      color: ${props.theme.name === "WS10"
        ? props.theme.color.monochrome1.default
        : props.theme.color.monochrome6.default};
    }
  `
);
