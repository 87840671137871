import { useField } from "formik";
import React, { ReactNode, useEffect, useState } from "react";

import { Checkbox } from "@source-web/checkbox";

import * as Styles from "../../styles/Wrappers.style";

interface CheckboxListProps {
  label: string;
  value: string;
  helpText: string;
  checked: boolean;
}

interface WrapperCheckboxListProps {
  items: CheckboxListProps[];
  name: string;
  maxChecked?: number;
  minChecked?: number;
  children: ReactNode;
}

export const WrapperCheckboxList = ({
  items,
  name,
  maxChecked = undefined,
  children
}: WrapperCheckboxListProps) => {
  const [field, meta, { setValue }] = useField<CheckboxListProps[]>(name);
  const [checkboxes, setCheckBoxes] = useState<CheckboxListProps[]>([]);

  useEffect(() => {
    if (!checkboxes.length) {
      // Check if there are any checkboxes in the state, if not, set them from the props
      const checkboxes = items.map((propsCheckbox) => {
        const reduxCheckbox = field?.value?.find(
          (reduxCheckbox) => reduxCheckbox.value === propsCheckbox.value
        );

        return reduxCheckbox ? reduxCheckbox : propsCheckbox;
      });

      setCheckBoxes(checkboxes);
    }
  }, [checkboxes.length, field?.value, items]);

  const updateCheckboxStates = (event: React.ChangeEvent<HTMLInputElement>) => {
    const itemIndex = checkboxes.findIndex(
      (checkboxItem: CheckboxListProps) => {
        return checkboxItem.value === event.target.name;
      }
    );

    const currentValues = {
      ...checkboxes[itemIndex],
      checked: event.target.checked
    };
    const newCheckboxesState = [...checkboxes];

    newCheckboxesState.splice(itemIndex, 1, {
      ...currentValues
    });

    if (maxChecked) {
      const count = newCheckboxesState.filter((field) => field.checked).length;
      if (count > maxChecked) {
        alert(`You're only allowed to check ${maxChecked} options!`);
        return;
      }
    }

    setCheckBoxes(newCheckboxesState);
  };

  useEffect(() => {
    const checkedBoxes = checkboxes.filter((checkbox: CheckboxListProps) => {
      return checkbox.checked;
    });

    setValue(checkedBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxes]);

  return (
    <Styles.CheckboxListContainer>
      {checkboxes.map(
        (checkbox: CheckboxListProps, index: React.Key | null | undefined) => {
          return (
            <Styles.CheckboxContainer key={index}>
              <Styles.FlexDisplay>
                {/* @ts-ignore */}
                <Checkbox
                  state={meta.touched && meta.error ? "error" : undefined}
                  name={checkbox.value}
                  id={checkbox.value}
                  checked={checkbox?.checked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateCheckboxStates(e);
                  }}
                >
                  {checkbox.label}
                </Checkbox>
                {children && (
                  <>
                    &nbsp;
                    {children}
                  </>
                )}
              </Styles.FlexDisplay>
              <small>{checkbox.helpText}</small>
            </Styles.CheckboxContainer>
          );
        }
      )}
    </Styles.CheckboxListContainer>
  );
};
