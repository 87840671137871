import { Buffer } from "buffer";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";

import { Span } from "@source-web/span";

import loadable from "@loadable/component";

import { cleanDowntimePageInformation, useFetchWithTracking } from "../../lib";
import { ExpirationModal } from "../../types";
import { AuthState } from "./auth.types";
import { AuthContext } from "./authContext";
import { storeUserData } from "./authStorage";
import { extractErrorCode } from "./helpers";

const ConfirmationModal = loadable(
  () => import("../../components/ConfirmationModal/ConfirmationModal")
);

interface AuthProviderProps {
  initialAuthState?: AuthState | null;
  children: React.ReactElement;
  expirationModal?: ExpirationModal;
}

export const defaultAuthState: AuthState = {
  isAuthenticated: false,
  isExternal: false,
  user: null
};

/*
 *  use the same locale as the pageContext.
 */
const navigateWithLocale = (path: string) => {
  if (typeof window !== undefined) {
    const formattedLocale = window.location.pathname.split("/")[1];
    navigate(`/${formattedLocale}/${path}`);
  }
};

export function AuthProvider({
  children,
  initialAuthState,
  expirationModal
}: AuthProviderProps) {
  const [authState, setAuthState] = useState(
    initialAuthState || defaultAuthState
  );

  const [isReady, setIsReady] = useState(authState !== defaultAuthState);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { fetchCyberhubMiddlewareWithTracking } = useFetchWithTracking();
  const timeSaved = Number(sessionStorage.getItem("timeSaved"));

  /*
   *  Update all the states around AuthProvider,
   *  Both sessionStorage and useState
   */
  function updateAuthState(data: AuthState) {
    try {
      setAuthState(data);
      storeUserData(data);
    } catch (error) {
      // no error handling yet, just console.log it.
      console.error(error);
    } finally {
      setIsReady(true);
    }
  }

  /*
   *  Clear all the states around AuthProvider,
   *  Both sessionStorage and useState
   */
  async function logOut() {
    if (authState?.isExternal && authState?.externalInfo?.logoutUrl) {
      return await fetch(authState?.externalInfo?.logoutUrl, {
        credentials: "include",
        method: "GET"
      }).then(() => {
        sessionStorage.clear();
        updateAuthState(defaultAuthState);
      });
    }

    try {
      return await fetchCyberhubMiddlewareWithTracking(
        "authentication/logout",
        {
          method: "POST"
        }
      );
    } catch (e) {
      throw new Error("Logout failed");
    } finally {
      sessionStorage.clear();
      navigateWithLocale("signIn");
      updateAuthState(defaultAuthState);
    }
  }

  function handleAuthErrors(error: any) {
    const code = extractErrorCode(error.message);

    if (code) {
      switch (code) {
        case "Access denied.":
        case "40101":
          throw new Error(
            "40101: Invalid login credentials; Incorrect username/email/password"
          );
        case "40102":
          throw new Error("40102: User account locked");
        case "40103":
          throw new Error("40103: Invalid access token");
        case "40104":
          throw new Error("40104: Access token expired");
        default:
          throw new Error(`Unhandled error code ${code}`);
      }
    } else {
      throw error;
    }
  }

  function handleError(error: any) {
    if (error?.status === 404) {
      throw new Error("Data does not exist");
    }
    if (error?.status === 401) {
      return handleAuthErrors(error);
    }
  }

  window.onbeforeunload = function () {
    if (!authState.isAuthenticated) return;
    sessionStorage.setItem("reloaded", "reloaded");
  };

  function checkTimeExpirationAuthState(date: number, firstAccess?: boolean) {
    if (!authState.isAuthenticated && !firstAccess) return;
    if (timeSaved && date >= timeSaved) {
      if (sessionStorage.getItem("reloaded") !== null) {
        logOut();
        setOpenModal(false);
      } else {
        setOpenModal(true);
      }
    } else {
      const timeStamp = new Date(date + 30 * 60000).getTime();
      sessionStorage.setItem("timeSaved", JSON.stringify(timeStamp));
      sessionStorage.removeItem("reloaded");
    }
  }

  function callCheckTime(firstAccess?: boolean) {
    const date = Date.now();
    checkTimeExpirationAuthState(date, firstAccess);
  }

  /*
   * Access the SignIn endpoint,
   * receiving a token and user information from the response
   * we store the token on a HttpOnly Cookie.
   */
  async function onSignIn(
    data: AuthState["user"],
    urlDestination: string = "dashboard"
  ) {
    if (!data) throw new Error("Form values are empty");

    const { user, password } = data;

    const base64Creds = Buffer.from(`${user}:${password}`, "utf8").toString(
      "base64"
    );

    const response = await fetchCyberhubMiddlewareWithTracking(
      `authentication/token`,
      {
        method: "POST",
        headers: {
          Authorization: `Basic ${base64Creds}`
        }
      }
    );

    if (response.error) {
      sessionStorage.setItem("timeSaved", JSON.stringify(null));
      return handleError(response.error);
    }

    const newUser = {
      user: {
        user: user,
        userId: response.id,
        lastName: response.lastName,
        firstName: response.firstName,
        email: response.email,
        company: response.company
      },
      isAuthenticated: true
    };
    // update user information
    updateAuthState(newUser);
    navigateWithLocale(urlDestination);

    cleanDowntimePageInformation();
    callCheckTime(true);
    return newUser;
  }

  /*
   * check if the notification id passed by argument is already hidden
   */
  function isNotificationHidden(notificationId: string) {
    const previousHiddenNotifications =
      localStorage.getItem("removedNotifications") || "";

    if (!previousHiddenNotifications) return false;

    return previousHiddenNotifications.includes(notificationId);
  }

  /*
   * set the notification that needs to be excluded.
   * the removedNotifications object is a string of ids
   * separeted by commas
   */

  function setExcludeNotification(notificationId: string) {
    localStorage.setItem(
      "removedNotifications",
      JSON.stringify(notificationId)
    );
  }

  /*
   * removes a notification from the state
   */
  async function removeNotificationFromState(notificationId: string) {
    const isSelectedNotificationHidden = isNotificationHidden(notificationId);
    if (isSelectedNotificationHidden) return;

    let previousHiddenNotifications: string[] = [];
    const storedNotifications = localStorage.getItem("removedNotifications");
    if (storedNotifications) {
      try {
        previousHiddenNotifications = JSON.parse(storedNotifications);
      } catch (err) {
        console.error("Error parsing stored notifications:", err);
      }
    }
    if (previousHiddenNotifications.length > 0) {
      setExcludeNotification(
        previousHiddenNotifications + "," + notificationId
      );
      return;
    }

    setExcludeNotification(notificationId);
  }

  useEffect(() => {
    callCheckTime(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        onSignIn,
        logOut,
        removeNotificationFromState,
        isNotificationHidden,
        updateAuthState,
        checkTimeExpirationAuthState,
        isReady
      }}
    >
      {children}
      {expirationModal && (
        <ConfirmationModal
          isOpen={openModal}
          isCloseable
          onCloseCb={() => {
            logOut();
            setOpenModal(false);
          }}
          heading={{
            justify: "left",
            text: expirationModal.contentfulConfirmationModal.heading,
            size: 2
          }}
        >
          <Span>
            {expirationModal.contentfulConfirmationModal.expirationText}
          </Span>
        </ConfirmationModal>
      )}
    </AuthContext.Provider>
  );
}
