import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

export const QuantitySelection = styled.div(
  (props: { isLarge: boolean; theme: Theme }) => {
    return css`
      display: flex;
      align-items: center;
      padding: 0 0.75em 0 0;
      justify-content: center;
      flex-direction: row;
      & > div:first-of-type {
        display: flex;
        & button {
          &:hover {
            background: ${props.theme.color.monochrome5.default};
            color: ${props.theme.color.monochrome1.default};
          }
        }
      }

      & #unitSelector-textInput {
        max-width: 200px;
        width: ${props.isLarge ? `130px` : `72px`};
      }

      @media (max-width: 400px) {
        gap: 1.25em;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        & > div:first-of-type {
          display: flex;
          justify-content: center;
        }
      }
    `;
  }
);
