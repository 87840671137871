// Main Dependencies
import { useField } from "formik";
import React, { useContext } from "react";
import { ReactReduxContext } from "react-redux";

// VFUK Components
import { SelectInputWithLabel } from "@source-web/select-input-with-label";

// Types
import { SelectInputProps } from "../../WrapperComponents.types";
// Local Imports
import * as Styles from "../../styles/Wrappers.style";

const WrapperSelectInput = ({
  fieldWrapper,
  selectInput,
  availableOptions,
  onChangeRequestType,
  disabled
}: SelectInputProps & {
  disabled: boolean;
  availableOptions?: SelectInputProps["selectInput"]["options"];
  onChangeRequestType?: (value?: string | undefined) => void;
}) => {
  const { store } = useContext(ReactReduxContext);

  const [field, meta, { setValue }] = useField<any | undefined>(selectInput.id);

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (
      store.getState().servicesPage.cleanValues &&
      store.getState().servicesPage.cleanValues.includes(selectInput.name) &&
      onChangeRequestType
    ) {
      field.value = undefined;
    }
    setValue(event.target.value);
    onChangeRequestType && onChangeRequestType(event.target.value);
  };

  function handleValidation() {
    if (meta.touched && (meta.error || !field.value)) return "error";
    if (disabled) return "disabled";

    return undefined;
  }

  return (
    <Styles.InputWrapper>
      <SelectInputWithLabel
        fieldWrapper={{
          ...fieldWrapper,
          helpText: meta.error && meta.touched ? meta.error : undefined
        }}
        selectInput={{
          ...field,
          ...selectInput,
          name: selectInput.name,
          required: selectInput.required,
          value: field.value,
          options: availableOptions ? availableOptions : selectInput.options,
          onChange: (event) => {
            handleOnChange(event);
          }
        }}
        state={handleValidation()}
      />
    </Styles.InputWrapper>
  );
};

export default WrapperSelectInput;
