import { connect } from "react-redux";

import conditionalRenderingPlugin from "@vf-djr-plugins/conditional-rendering";
import mapStateToActionHandlersPlugin from "@vf-djr-plugins/map-state-to-action-handlers";
import mapStateToPropsPlugin from "@vf-djr-plugins/map-state-to-props";
import createDynamicJourneyRenderer from "@vf-djr/renderer";
import { componentParser } from "@vf-djr/utilities";

import { store } from "../../store/store";
import {
  SubmitForm,
  formatTime,
  formatToDate,
  getAgentSlotDates,
  getDates,
  getLoadingStateFromStatus,
  getMaxDates,
  getMinDates,
  getOptionsFromSlot,
  initInitialValues,
  isEqual,
  isEqualOr,
  isErrored,
  isNotEqual,
  isSubmitted,
  isSuccessful,
  not
} from "./Functions";
import FormPlugin from "./Plugins/FormPlugin";
import InitStatePlugin from "./Plugins/InitStatePlugin";
import MapFormikToProps from "./Plugins/MapFormikToProps";
import { registry } from "./WrapperComponents";

const renderer = createDynamicJourneyRenderer({
  registry: registry,
  plugins: {
    initialSetup: {
      selectors: {
        getLoadingStateFromStatus,
        not,
        isSuccessful,
        isSubmitted,
        isErrored,
        isEqual,
        isNotEqual,
        isEqualOr,
        initInitialValues,
        getMaxDates,
        getMinDates,
        getAgentSlotDates,
        getOptionsFromSlot,
        formatToDate,
        formatTime
      },
      onSubmitForm: SubmitForm,
      getAvailableDates: getDates,
      connect,
      store
    },
    preRender: {
      __component: componentParser({
        Formik: FormPlugin
      }),
      __render: conditionalRenderingPlugin,
      __mapStateToProps: mapStateToPropsPlugin,
      __mapFormikToProps: MapFormikToProps,
      __initState: InitStatePlugin
    },
    propsPlugin: mapStateToActionHandlersPlugin
  }
});

export default renderer;
