// import { AuthState } from "./auth.types";
import { FormConfigType } from "./form.types";

/*
  retrieve the data stored under session storage and return
*/
const getStoredFormData = (): FormConfigType =>
  JSON.parse(sessionStorage.getItem("storedFormConfig")! || "{}");

/*
  store data from args
*/
const storeFormData = (data: FormConfigType) =>
  sessionStorage.setItem("storedFormConfig", JSON.stringify(data));

export { storeFormData, getStoredFormData };
