import React from "react";
import { useTheme } from "styled-components";

import { Paragraph } from "@source-web/paragraph";
import {
  StateNotification,
  StateNotificationProps
} from "@source-web/state-notification";

import { errorMessages } from "../../lib";
import { SetButtonColor } from "./styles/AdviseNotification.styles";

export interface AdviseNotificationProps
  extends Partial<StateNotificationProps> {
  text: string;
  title?: string;
  state?: StateNotificationProps["state"];
}

export function AdviseNotification({
  text,
  title = errorMessages.unexpected,
  state = "warn",
  ...rest
}: AdviseNotificationProps) {
  const theme = useTheme();

  return (
    <div>
      <SetButtonColor isDark={theme.name === "WS10Dark"}>
        <StateNotification
          {...rest}
          state={state}
          heading={{ text: title, level: 2 }}
        >
          <Paragraph>{text}</Paragraph>
        </StateNotification>
      </SetButtonColor>
    </div>
  );
}
