import { useQuery } from "react-query";

import { useFetchWithTracking } from "../../../lib";

type VSDMKeys = "physical" | "devices";

export type VSDMData = {
  physical?: Physical;
  devices?: Devices;
} | null;

type Device = {
  id: string;
  serialNumber: string;
  lastSeen: string;
  operationSystem: string;
  operationSystemVersion: string;
};

type LastSeenBreakdown = {
  [range: string]: number;
};

type OSVersionCount = {
  [version: string]: number;
};

export type OSBreakdown = Record<string, OSVersionCount[]>;

export type Physical = {
  devices: Device[];
  lastSeenBreakdown: LastSeenBreakdown;
  operationSystemBreakdown: OSBreakdown;
};

export type DevicesByPlatform = {
  WindowsMobile: number;
  Apple: number;
  Android: number;
};

export type Enrollment = {
  enrol: string;
  unenrolled: string;
  preEnrollment: string;
};

export type Devices = {
  total: string;
  security: {
    compromised: string;
    noPassCode: string;
    notEncrypted: string;
  };
  ownership: {
    enrol: string;
    shared: string;
    dedicated: string;
  };
  enrollment: Enrollment;
  platform: {
    devicesByPlatform: DevicesByPlatform;
  };
};

type VSDMDataEnableType = Record<VSDMKeys, boolean>;

const defaultEnabled: VSDMDataEnableType = {
  physical: true,
  devices: true
};

export function useVSDMData(
  enabled: Partial<VSDMDataEnableType> | boolean = defaultEnabled
) {
  const enabledMapped = typeof enabled === "object" ? enabled : {};
  const { fetchCyberhubMiddlewareWithTracking } = useFetchWithTracking();

  const mergedPropEnabled = {
    ...defaultEnabled,
    ...enabledMapped
  };

  const {
    data: physicalData,
    isError: physicalError,
    isLoading: physicalLoading,
    isFetched: physicalClosed
  } = useQuery<Physical>("vsdm/devices/physical", {
    queryFn: () => fetchCyberhubMiddlewareWithTracking(`vsdm/devices/physical`),
    enabled: typeof enabled === "object" ? mergedPropEnabled.physical : enabled
  });

  const {
    data: devicesData,
    isError: devicesError,
    isLoading: devicesLoading,
    isFetched: devicesClosed
  } = useQuery<Devices>("vsdm/devices", {
    queryFn: () => fetchCyberhubMiddlewareWithTracking(`vsdm/devices`),
    enabled: typeof enabled === "object" ? mergedPropEnabled.devices : enabled
  });

  // Check for enabled-only values before setting isLoading
  const isLoadingValues = [
    mergedPropEnabled.physical ? physicalLoading : null,
    mergedPropEnabled.devices ? devicesLoading : null
  ].filter((item) => item !== null);

  const isClosedValues = [
    mergedPropEnabled.physical ? physicalClosed : null,
    mergedPropEnabled.devices ? devicesClosed : null
  ].filter((item) => item !== null);

  const isErrorValues = [
    mergedPropEnabled.physical ? physicalError : null,
    mergedPropEnabled.devices ? devicesError : null
  ].filter((item) => item !== null);

  const allDataLoaded = isLoadingValues.every((value) => !value);
  const allDataClosed = isClosedValues.every((value) => value);
  const isError = isErrorValues.some((value) => value);
  const isLoading = !allDataLoaded || !allDataClosed;

  const data =
    allDataLoaded && allDataClosed
      ? {
          physical: physicalData,
          devices: devicesData
        }
      : null;

  return { data, isLoading, isError };
}
