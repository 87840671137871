import React from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { InteractiveIcon } from "@source-web/interactive-icon";

import { CartContextType } from "../../../../context/cart/cartContext";
import { CartItem } from "../../../../context/cart/types";
import { priceFormatter } from "../../../../lib";
import { CustomQuantitySelector } from "../../../CustomQuantitySelector";
import * as Styled from "./styles/BasketItem.styles";

export const BasketItem = ({
  product,
  isMobile,
  removeProductFromCart,
  addProductToCart
}: {
  product: CartItem;
  isMobile?: boolean;
  addProductToCart: CartContextType["addProductToCart"];
  removeProductFromCart: (productId: string) => void;
}) => {
  const { name } = useTheme();

  function formatPrices(price: number) {
    return price ? priceFormatter.format(price) : "-,00";
  }

  return !isMobile ? (
    <Styled.BasketItemContainer>
      <Styled.IconWrapper>
        <img
          src={
            name === "WS10"
              ? product.lightIcon.file.url
              : product.darkIcon.file.url
          }
          alt={product.name}
        />
      </Styled.IconWrapper>
      <Styled.BasketItemBody>
        <Styled.TitleWrapper>
          <Heading level={5} weight={3} noMargin>
            {product.name}
          </Heading>

          <InteractiveIcon
            srText="button for deleting a product from cart"
            onClick={() => removeProductFromCart(product.id)}
            group="system"
            name="delete"
            size={3}
          />
        </Styled.TitleWrapper>
        <Styled.QuantityAndPriceWrapper>
          <Styled.PriceContainer>
            <div>
              <Styled.PaymentType>Upfront</Styled.PaymentType> <br />
              <Heading level={5} weight={3} noMargin>
                {formatPrices(
                  parseFloat(product?.priceUpfront ? product?.priceUpfront : "")
                )}
              </Heading>
              <Styled.PaymentType> (ex VAT)</Styled.PaymentType>
            </div>

            <div>
              <Styled.PaymentType>Montly</Styled.PaymentType> <br />
              <Heading level={5} weight={3} noMargin>
                {formatPrices(product.price)}
              </Heading>
              <Styled.PaymentType> (ex VAT)</Styled.PaymentType>
            </div>
          </Styled.PriceContainer>
          <CustomQuantitySelector
            {...{
              value: product.quantity || 0,
              onInvalid: (evt) => {
                if (evt === 0) {
                  removeProductFromCart(product.id);
                }
              },
              onChange: (quantity: number) =>
                addProductToCart({
                  ...product,
                  quantity
                })
            }}
          />
        </Styled.QuantityAndPriceWrapper>
      </Styled.BasketItemBody>
    </Styled.BasketItemContainer>
  ) : (
    <Styled.BasketItemContainerMobile>
      <Styled.IconWrapperMobile>
        <img
          src={
            name === "WS10"
              ? product.lightIcon.file.url
              : product.darkIcon.file.url
          }
          alt={product.name}
        />
      </Styled.IconWrapperMobile>
      <Styled.BasketItemBodyMobile>
        <Styled.TitleWrapper>
          <Heading level={5} weight={3} noMargin>
            {product.name}
          </Heading>

          <InteractiveIcon
            srText="button for deleting a product from cart"
            onClick={() => removeProductFromCart(product.id)}
            group="system"
            name="delete"
            size={3}
          />
        </Styled.TitleWrapper>
        <Styled.QuantityAndPriceWrapperMobile>
          <Styled.PriceContainerMobile>
            <div>
              <Styled.PaymentType>Upfront</Styled.PaymentType> <br />
              <Heading level={5} weight={3} noMargin>
                {formatPrices(
                  parseFloat(product?.priceUpfront ? product?.priceUpfront : "")
                )}
              </Heading>
            </div>

            <div style={{ marginLeft: "4em" }}>
              <Styled.PaymentType>Montly</Styled.PaymentType> <br />
              <Heading level={5} weight={3} noMargin>
                {formatPrices(product.price)}
              </Heading>
            </div>
          </Styled.PriceContainerMobile>
          <Styled.QuantityContainerMobile>
            <CustomQuantitySelector
              {...{
                value: product.quantity || 0,
                onInvalid: (evt) => {
                  if (evt === 0) {
                    removeProductFromCart(product.id);
                  }
                },
                onChange: (quantity: number) =>
                  addProductToCart({
                    ...product,
                    quantity
                  })
              }}
            />
          </Styled.QuantityContainerMobile>
        </Styled.QuantityAndPriceWrapperMobile>
      </Styled.BasketItemBodyMobile>
    </Styled.BasketItemContainerMobile>
  );
};
