import { paramCase } from "param-case";

export interface SecurityScoreCalcProps {
  /** Cyber Score */
  score?: number;
  /** Score Rating */
  rating?: string;
  /** message displayed in pill*/
  pillMessage?: string | null;
  /** Change the state to calculating, gray out the comp */
  isCalculating?: boolean;
  wasFetched?: boolean;
  isIdle?: boolean;
  hideScore?: boolean;
}

type GetPositioning = Pick<
  SecurityScoreCalcProps,
  "isIdle" | "isCalculating" | "score"
>;

export const scoreDisplayColors = {
  severe: "#e60801",
  poor: "#ec9800",
  fair: "#feca00",
  good: "#a9b403",
  excellent: "#009900",
  gray: "#7E7E7E"
};

export function getScorePalette({
  score,
  isCalculating,
  isIdle
}: GetPositioning): string[] {
  if (isCalculating || isIdle) {
    return Array(5).fill(scoreDisplayColors.gray);
  }

  if (!score) return [];

  switch (true) {
    case score >= 0 && score <= 59:
      return [
        scoreDisplayColors.severe,
        ...Array(4).fill(scoreDisplayColors.gray)
      ];

    case score >= 60 && score <= 69:
      return [
        scoreDisplayColors.severe,
        scoreDisplayColors.poor,
        ...Array(3).fill(scoreDisplayColors.gray)
      ];

    case score >= 70 && score <= 79:
      return [
        scoreDisplayColors.severe,
        scoreDisplayColors.poor,
        scoreDisplayColors.fair,
        ...Array(2).fill(scoreDisplayColors.gray)
      ];

    case score >= 80 && score <= 89:
      return [
        scoreDisplayColors.severe,
        scoreDisplayColors.poor,
        scoreDisplayColors.fair,
        scoreDisplayColors.good,
        ...Array(1).fill(scoreDisplayColors.gray)
      ];

    case score >= 90:
      return [
        scoreDisplayColors.severe,
        scoreDisplayColors.poor,
        scoreDisplayColors.fair,
        scoreDisplayColors.good,
        scoreDisplayColors.excellent
      ];

    default:
      return [];
  }
}

// export const defaultIndicatorPosition: Positioning = { right: 280, bottom: 87 };
// export function getIndicatorPosition({
//   score,
//   isCalculating = false,
//   isIdle
// }: GetPositioning): Positioning {
//   if (!score || isCalculating || isIdle) return { right: 282, bottom: 85 };

//   switch (true) {
//     case score >= 0 && score <= 59:
//       return {
//         right: 303,
//         bottom: 180
//       };
//     case score >= 60 && score <= 69:
//       return {
//         right: 239,
//         bottom: 275
//       };
//     case score >= 70 && score <= 79:
//       return {
//         right: 120,
//         bottom: 285
//       };
//     case score >= 80 && score <= 89:
//       return { right: 38, bottom: 200 };
//     case score >= 90:
//       return {
//         right: 52,
//         bottom: 85
//       };
//     default:
//       return defaultIndicatorPosition;
//   }
// }

export function getGradeBasedOnScore(score: number | null = null) {
  if (!score) return null;

  switch (true) {
    case score >= 90:
      return "A";
    case score >= 80 && score <= 89:
      return "B";
    case score >= 70 && score <= 79:
      return "C";
    case score >= 60 && score <= 69:
      return "D";
    case score <= 59:
      return "F";

    default:
      return null;
  }
}

export function getProgressBasedOnGrade(grade: string | null) {
  switch (true) {
    case grade === "A":
      return 100;
    case grade === "B":
      return 80;
    case grade === "C":
      return 60;
    case grade === "D":
      return 40;
    case grade === "F":
      return 20;
    default:
      return 0;
  }
}

export const getTileColorBasedOnScore = (score: number): string => {
  switch (true) {
    case score >= 90:
      return "#009900";
    case score >= 80 && score <= 89:
      return "#a9b403";
    case score >= 70 && score <= 79:
      return "#feca00";
    case score >= 60 && score <= 69:
      return "#ec9700";
    case score <= 59:
      return "#e60201";
    default:
      return "#e60201";
  }
};

export const getDatumIndexForChart = (score: number): number => {
  switch (true) {
    case score >= 90:
      return 4;
    case score >= 80 && score <= 89:
      return 3;
    case score >= 70 && score <= 79:
      return 2;
    case score >= 60 && score <= 69:
      return 1;
    case score <= 59:
      return 0;
    default:
      return 0;
  }
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeFactor(factorName: string) {
  return paramCase(factorName)
    .split("-")
    .map((item: string) => capitalizeFirstLetter(item))
    .join(" ");
}

// TO DO
// To be later moved to Contentful
// So that the Ledends Can Support Localisation

export function formatSecurityScoreCard(factorName: string) {
  const specialCases: { [key: string]: string } = {
    ipreputation: "IP Reputation",
    dnshealth: "DNS Health"
  };

  const lowercasedFactorName = factorName.toLowerCase();

  if (lowercasedFactorName in specialCases) {
    return specialCases[lowercasedFactorName];
  }

  return factorName
    .split(/(?=[A-Z])/)
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
