import dayjs, { UnitType } from "dayjs";
import { LocalFile, ParseResult, parse } from "papaparse";

import { InteractionEvent } from "@source-web/interaction";

import { useLocation } from "@reach/router";

import { GatsbyImageType } from "../../types";
import { IMiddlewareSubscription } from "../services/marketplaceService/subscriptions.types";

export const isServer = typeof window === "undefined";

export const isJest = typeof jest !== "undefined";

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  maximumFractionDigits: 2
});

export function getDiffBetweenDates(date: Date | dayjs.Dayjs, unit: UnitType) {
  return dayjs().diff(date, unit).toString().replace("-", "");
}

export const haveSScAccount = !isServer
  ? JSON.parse(localStorage.getItem("haveSScAccount") || "false")
  : false;

// Capitalize first letter
export const capitalizeFirstLetter = (text: string = "") => {
  if (typeof text !== "string") return "";
  return text.substring(0, 1).toUpperCase() + text.substring(1);
};

// return last path from the URL
export const useLastPathname = () => {
  const { pathname } = useLocation();
  const pathNames = pathname.split("/").filter((val) => !!val);
  return pathNames[pathNames.length - 1];
};

// return the URL value for specified index
export const usePathnameByIndex = (index: number) => {
  const { pathname } = useLocation();
  const pathNames = pathname.split("/").filter((val) => !!val);
  return pathNames[index];
};

// remove sufix from the locale
// e.g: en-GB -> en
export const formatLocale = (locale: string) => locale.split("-")[0];

//format images returned from graphQL
export const getStaticImageFromSrcSet = (srcSet: string, size?: string) => {
  const sizes: Record<string, string> = srcSet
    .split(",\n")
    .reduce((total, current) => {
      const [url, size]: any = current.split(" ");
      return {
        ...total,
        [size.slice(0, -1)]: url
      };
    }, {});
  return size ? sizes[size] : Object.values(sizes)[0];
};

//format images returned from graphQL
export const formatImage = (image: GatsbyImageType, size?: string) => {
  if (!image) return "";
  return getStaticImageFromSrcSet(
    image.gatsbyImageData.images.sources[0].srcSet,
    size
  );
};

// download PDF from a base64 string
export function downloadPDF({
  base64,
  title
}: {
  base64: string;
  title: string;
}) {
  const linkSource = `data:application/pdf;base64,${base64}`;
  const downloadLink = document.createElement("a");
  const fileName = `${title}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

// receive an email and return just the domain
// e.g john@vodafone.com | () => vodafone.com
export function getDomainFromEmail(companyEmail: string) {
  return companyEmail?.split("@")[1];
}

// receive an domain and return just the name
// e.g vodafone.com | () => vodafone
export function removeDotFromDomain(companyEmail: string) {
  return companyEmail.split(".")[0];
}

// return true if argument is an array
export const isArray = function (a: any) {
  return !!a && a.constructor === Array;
};

export function typeEqualsTo(prop: any, type: string): boolean {
  return typeof prop === type;
}

// return a string based on grade. e.g:
// falsy values: undefined | null | NaN | false => "Rating"
// "?" from SSC means that the Company score is being processed => "Calculating"
// if none of the above cases match, just return the grade => grade
export const calculateStateForGrade = (grade: string) => {
  if (!grade) return "Rating";
  if (grade === "?") return "Calculating";

  return grade;
};

// return the downtime page information stored on localStorage
export function getDowntimePageInformation() {
  const data = localStorage.getItem("downtimePageInformation");

  if (data !== null) {
    return JSON.parse(data);
  }
  return null;
}

// set the downtime page information and store it on localStorage
export function setDowntimePageInformation(error: string) {
  localStorage.setItem(
    "downtimePageInformation",
    JSON.stringify({ error, date: new Date() })
  );
}

// clean downtime page information from localStorage
export function cleanDowntimePageInformation() {
  localStorage.removeItem("downtimePageInformation");
}

export function isObjectEmpty(obj: Object) {
  return Object.keys(obj).length === 0;
}

export const parseCsv = (
  file: LocalFile,
  successCb: (args: ParseResult<unknown>) => void,
  errorCb?: (err: Error, file: LocalFile) => void
): void =>
  parse(file, {
    skipEmptyLines: true,
    fastMode: true,
    header: false,
    complete: (args: ParseResult<unknown>) => successCb(args),
    error: (err, file) => {
      errorCb
        ? errorCb(err, file)
        : console.error("Error when parsing file", err, file);
    }
  });

export function removeCurrencySign(str: string) {
  return str.replace(/[^0-9\.]/g, "");
}

export function getNumbersFromString(str: string) {
  return str.replace(/\D/g, "");
}

export function normalizeCamelCase(camelCaseString: string) {
  // insert a space before all caps
  return (
    camelCaseString
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

const increasePercentage = (numberOne: number, numberTwo: number) =>
  ((numberTwo - numberOne) / numberOne) * 100;

const duplicateArrayAndGetByPeriod = (
  array: any[],
  periodForFiltering: number,
  key: string
) =>
  array
    .slice()
    .slice(0, periodForFiltering)
    .reduce((acc, curr) => acc + curr[key], 0);

export function calculatePercentageVariation(
  data: any[],
  period: number,
  key: string
): number {
  const firstPeriodScore = duplicateArrayAndGetByPeriod(data, period, key);

  const secondPeriodScore = duplicateArrayAndGetByPeriod(data, period * 2, key);

  return increasePercentage(firstPeriodScore, secondPeriodScore);
}

export function createAndDownloadBlobFile(
  url: any,
  filename: string,
  type: string
) {
  const link = document.createElement("a");

  if (type == "download" && link.download !== undefined) {
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (type == "view") {
    link.setAttribute("href", url);
    link.setAttribute("target", "_blank");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export const trimLowerCase = (value: string) =>
  value.toLowerCase().replace(/\s+/g, "");

export const getProductUniqueInfo = (subscription: IMiddlewareSubscription) =>
  subscription.product?.find((product) => product.name === "product");

export function handleSubscriptionIds(
  subscriptions:
    | IMiddlewareSubscription
    | Array<IMiddlewareSubscription>
    | undefined,
  includeDemoProds: boolean = false
): string[] {
  //@Todo remove logic for rendering SSC and CybSafe
  // after products are added into the account
  // remove every declaration from productsToInclude
  const demoProds = ["ssc", "cyb"];
  const productsToInclude = includeDemoProds ? [...demoProds] : [];

  if (isArray(subscriptions)) {
    const manySubs = subscriptions as Array<IMiddlewareSubscription>;

    const mappedSubs = manySubs?.map((subscription) => {
      const productInfo = getProductUniqueInfo(subscription);

      if (!productInfo?.id) return null;

      return productInfo.id;
    }) as string[];

    const uniqueMappedSubsAndProductsToInclude = [
      ...new Set([...mappedSubs, ...productsToInclude])
    ];

    return uniqueMappedSubsAndProductsToInclude.filter(Boolean);
  }

  const singleSubs = subscriptions as IMiddlewareSubscription;

  return [singleSubs?.id, ...productsToInclude];
}

export function is60PercentOf(value?: number, reference?: number): boolean {
  if (!value || !reference) return false;
  const sixtyPercentOfReference = reference * 0.6;
  return value >= sixtyPercentOfReference;
}

export function isJourneyPopUp(
  e: InteractionEvent,
  isServer: boolean,
  url?: string
) {
  e.preventDefault();
  if (!isServer) {
    window.open(url, "_blank");
  }
}

export const journeyHandleClick = (
  e: InteractionEvent,
  formattedLocale: string,
  isChecked: boolean,
  handleOpenModal: () => void,
  handleCloseModal: () => void,
  formattedURL?: string
) => {
  e.preventDefault();

  const shouldOpenModal =
    JSON.parse(localStorage.getItem("productCheckBoxState") || "false") ===
    false;

  const openURL = () => window.open(formattedURL, "_blank");
  const delayforItaly = 3500;

  if (shouldOpenModal) {
    if (formattedLocale === "it") {
      handleOpenModal();
      setTimeout(() => {
        openURL();
        handleCloseModal();
      }, delayforItaly);
    } else {
      isChecked ? openURL() : handleOpenModal();
    }
  } else {
    openURL();
  }
};

export function generateSetFromArray<T>(array: T[] | undefined): T[] {
  if (!array?.length) return [];
  return Array.from(new Set(array));
}

export function cleanArray(actual: any[]) {
  if (!actual?.length) return [];

  return actual?.filter(Boolean);
}

type IconType = {
  file: {
    url: string;
  };
};
export const getIconForTheme = (
  isDark: boolean,
  darkIcon: IconType,
  lightIcon: IconType
) => {
  return isDark ? darkIcon.file.url : lightIcon.file.url;
};

// Define the predefined height values.
const predefinedHeightValue = (key: number, innerHeight: number) => {
  const predefinedHeights: { [key: number]: number } = {
    100: 0,
    90: innerHeight - 320,
    80: innerHeight - 240,
    70: innerHeight - 160,
    60: innerHeight - 80,
    50: innerHeight - 66.7,
    40: innerHeight - 53.4,
    30: innerHeight - 40.1,
    20: innerHeight - 26.8,
    10: innerHeight - 13.5,
    0: innerHeight
  };

  return predefinedHeights[key];
};

// Function to map y values to height.
export const mapYValueToHeight = (y: number, innerHeight: number): number => {
  if (y >= 0 && y <= 100 && y % 10 === 0) {
    return predefinedHeightValue(y, innerHeight);
  } else {
    // Linear interpolation to map values between defined points
    const lowerValue = Math.floor(y / 10) * 10; // Nearest lower value
    const upperValue = Math.ceil(y / 10) * 10; // Nearest upper value

    const lowerHeight = mapYValueToHeight(lowerValue, innerHeight); // Height corresponding to lower value
    const upperHeight = mapYValueToHeight(upperValue, innerHeight); // Height corresponding to upper value

    // Calculate intermediate height using linear interpolation
    const interpolatedHeight =
      lowerHeight +
      ((y - lowerValue) / (upperValue - lowerValue)) *
        (upperHeight - lowerHeight);

    return interpolatedHeight;
  }
};

export const getFormattedDateForHistoryGraph = (value: string) => {
  const date = new Date(value);
  const formattedDate = date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short"
    })
    .replace(/ /g, " ");

  return formattedDate;
};
