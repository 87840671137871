import React from "react";

import { UnitSelectorWithLabel } from "@source-web/unit-selector-with-label";

import { QuantitySelection } from "./styles/CustomQuantitySelector.styles";

export const CustomQuantitySelector = ({
  min = 0,
  max = 99999999,
  onInvalid = () => {},
  value = 0,
  isLarge = false,
  ...props
}: {
  value: number;
  max?: number;
  min?: number;
  isLarge?: boolean;
  onInvalid?: (evt: number) => void;
  onChange: (
    value: number,
    trigger: "textInput" | "incrementButton" | "decrementButton"
  ) => void;
}) => {
  return (
    <QuantitySelection isLarge={value >= 1000 || isLarge}>
      <UnitSelectorWithLabel
        {...props}
        fieldWrapper={{
          label: "",
          showLabel: false
        }}
        unitSelector={{
          onInvalidEntry: (evt) => {
            onInvalid(evt);
          },
          id: "unitSelector",
          min: min,
          allowNonIncrementValue: true,
          max: max,
          increment: 1,
          srLabel: {
            leftButton: "decrement button",
            rightButton: "increment button"
          },
          value: value || min,
          onChange: (evt, trigger) => {
            props.onChange(evt, trigger);
          }
        }}
      />
    </QuantitySelection>
  );
};
