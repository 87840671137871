import { useFormikContext } from "formik";
import React, { FC, useEffect } from "react";
import styled from "styled-components";

import { isObjectEmpty } from "../../../lib/";
import { PrimaryButton } from "../../PrimaryButton";

interface IFormObserverProps {
  shouldSubmit: boolean;
  shouldDebug: boolean;
  buttonText: string;
}

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const FormObserver: FC<IFormObserverProps> = ({
  shouldSubmit,
  shouldDebug = false,
  buttonText
}: IFormObserverProps) => {
  const { values, submitForm } = useFormikContext();

  useEffect(() => {
    if (isObjectEmpty(values as Object)) return;

    if (shouldDebug) console.debug("FormObserver::values", values);
  }, [values, shouldDebug]);

  return shouldSubmit ? (
    <ButtonWrapper>
      <PrimaryButton text={buttonText} onClick={() => submitForm()} />
    </ButtonWrapper>
  ) : null;
};

export default FormObserver;
