import React from "react";

import { Icon } from "@source-web/icon";
import { Span } from "@source-web/span";

import { PageContext } from "../../../../types";
import { PageHeaderNode } from "../PageWrapperHeader/PageWrapperHeader";
import {
  ButtonContainer,
  ButtonMobileContainer
} from "../PageWrapperOptions/styles/LogoutButton.styles";
import {
  LanguageSelector__DesktopComponent,
  LanguageSelector__MobileComponent
} from "./styles/LanguageSelector.styles";

// function sliceRoute(route: string) {
//   const splittedRoutes = route.split("/");

//   return splittedRoutes.slice(2).join("/");
// }

export function LanguageSelector({
  pageContext
}: // title
// path
{
  pageContext: PageContext;
  title: PageHeaderNode["languageTitle"];
  // path: string;
}) {
  // const route = sliceRoute(path);

  const activeLocaleData = pageContext?.localeData?.find(
    (data) => data.localeId === pageContext?.formattedLocale
  );

  {
    /* CYB-18394: Disable Langauge Selector for Soft Launch.  */
  }
  // function setLocale(selectedLocale: string) {
  //   navigate(`/${formatLocale(selectedLocale)}/${route}`);
  // }

  // const icons: Record<string, React.JSX.Element> = {
  //   it: <IconItCircle size={3} />,
  //   gb: <IconGbCircle size={3} />,
  //   pt: <IconPtCircle size={3} />
  // };

  return (
    <>
      {/* CYB-18394: Disable Langauge Selector for Soft Launch.  To enable just we can uncomment the below line*/}
      {/* <ButtonContainer onClick={() => setIsOpen(true)}>*/}
      <ButtonContainer>
        <LanguageSelector__DesktopComponent>
          <Icon name="international" group="system" />
          <Span>
            ({pageContext?.formattedLocale?.toUpperCase()}){" "}
            {activeLocaleData?.name}
          </Span>
        </LanguageSelector__DesktopComponent>
      </ButtonContainer>

      <ButtonMobileContainer>
        <LanguageSelector__MobileComponent>
          <Icon name="international" group="system" />
          <Span>
            ({pageContext?.formattedLocale?.toUpperCase()}){" "}
            {activeLocaleData?.name}
          </Span>
        </LanguageSelector__MobileComponent>
      </ButtonMobileContainer>

      {/* CYB-18394: Disable Langauge Selector for Soft Launch. */}
      {/* <Modal
        srName="language selector"
        onCloseCb={() => setIsOpen(false)}
        isOpen={isOpen}
        type="flyout"
      >
        <LanguageSelector__ModalComponent>
          <Heading level={4}>{title}</Heading>

          {pageContext?.usableLocales?.map((locale: string) => {
            const currentLocaleData = pageContext?.localeData?.find(
              (data) => data.localeId === formatLocale(locale)
            );

            return (
              <LanguageSelector__ListItemComponent
                onClick={() => setLocale(locale)}
                key={locale}
              >
                <img
                  src={currentLocaleData?.flag.file?.url}
                  width="30px"
                  height="30px"
                  alt="flags"
                />
                <Span>{`(${formatLocale(locale).toUpperCase()}) ${currentLocaleData?.name
                  }`}</Span>
              </LanguageSelector__ListItemComponent>
            );
          })}
        </LanguageSelector__ModalComponent>
      </Modal> */}
    </>
  );
}
