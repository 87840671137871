import * as React from "react";

const ScrollToId = ({ idOfElement }: { idOfElement: string }) => {
  React.useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.document.getElementById(idOfElement)
    ) {
      window.document.getElementById(idOfElement)?.scrollIntoView();
    }
  }, [idOfElement]);
  return null;
};

export default ScrollToId;
