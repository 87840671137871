import { createContext } from "react";

import { IAuthProvider } from "./auth.types";

/* Creates React context for authentication */

const AuthContext = createContext<IAuthProvider>({
  isNotificationHidden: () => false,
  authState: {
    user: {
      user: "",
      userId: ""
    }
  },
  onSignIn: async () => {},
  logOut: () => {},
  removeNotificationFromState: async () => {},
  updateAuthState: async () => {},
  checkTimeExpirationAuthState: () => {},
  isReady: false
});

export { AuthContext };
