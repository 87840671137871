import React, { ReactElement } from "react";
import { useTheme } from "styled-components";

import { NumberBadge, NumberBadgeTheme } from "@source-web/number-badge";

import * as Styled from "./styles/ItemWithBadge.styles";

export interface ItemWithBadgeProps {
  children?: ReactElement;
  number?: number;
}

export const ItemWithBadge = ({ children, number = 0 }: ItemWithBadgeProps) => {
  const { color } = useTheme();
  const ItemWithBadgeLocalTheme: NumberBadgeTheme = {
    //@ts-ignore ignoring type error since we are not using secondary
    appearance: {
      primary: {
        background: color.primary1.default,
        color: color.primary2.default
      }
    }
  };

  if (!children)
    return (
      <NumberBadge
        localTheme={ItemWithBadgeLocalTheme}
        appearance="primary"
        number={number}
      />
    );

  return (
    <>
      {number ? (
        <Styled.ItemWithBadgeContainer>
          {children}

          <NumberBadge
            localTheme={ItemWithBadgeLocalTheme}
            appearance="primary"
            number={number}
          />
        </Styled.ItemWithBadgeContainer>
      ) : (
        children
      )}
    </>
  );
};
