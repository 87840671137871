// Main Dependencies
import { useField } from "formik";
import React, { useContext } from "react";
import { ReactReduxContext } from "react-redux";

// VFUK Components
import { TextInputWithLabel } from "@source-web/text-input-with-label";

// Types
import { TextInputProps } from "../../WrapperComponents.types";
// Local Imports
import * as Styles from "../../styles/Wrappers.style";

const WrapperTextInput = ({ fieldWrapper, textInput }: TextInputProps) => {
  const [field, meta] = useField<string | undefined>(textInput.id);
  const { store } = useContext(ReactReduxContext);

  if (
    store.getState().servicesPage.cleanValues &&
    store.getState().servicesPage.cleanValues.includes(textInput.id)
  ) {
    field.value = undefined;
  }

  return (
    <Styles.InputWrapper>
      <TextInputWithLabel
        fieldWrapper={{
          ...fieldWrapper,
          helpText: meta.error && meta.touched ? meta.error : undefined
        }}
        textInput={{ ...field, ...textInput }}
        state={meta.error && meta.touched ? "error" : undefined}
      />
    </Styles.InputWrapper>
  );
};

export default WrapperTextInput;
