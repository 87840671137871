import styled from "styled-components";

export const ButtonContainer = styled.div`
  ${(props) => `

  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: 5px;
  cursor: pointer;
  transition: 400ms ease-in-out;
  @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
    margin: 0px;
  }
  `}
`;

export const ButtonMobileContainer = styled.div`
  ${(props) => `
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin: 5px;
  cursor: pointer;
  transition: 400ms ease-in-out;
  @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
    margin: 0px;
  }
  `}
`;

export const DesktopLogoutContainer = styled.div`
  ${(props) => `
@media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
  display: none;  
}
display : block;
`}
`;

export const MobileLogoutContainer = styled.div`
  ${(props) => `
    @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-style: normal;
      list-style-type: none;
      font-size: 20px;
      font-weight: 200;
    }
    & > span + a{
      margin-left: 4px;
    }
    display : none;
  `}
`;
