import { navigate } from "gatsby";
import React from "react";

import { InteractiveIcon } from "@source-web/interactive-icon";

import { NavigationalTabs, Tab } from "@vfuk/core-navigational-tabs";

import { trackMenuClick } from "../../../../lib";
import { PageContext } from "../../../../types";
import { Cart } from "../../../Cart";
import UpdatesWidget from "../../../UpdatesWidget";
import * as Styled from "../../styles/PageWrapper.styles";
import { MobileNavigation } from "../MobileNavigation/MobileNavigation";
import { PageHeaderNode } from "../PageWrapperHeader/PageWrapperHeader";
import { NavIconWrapper } from "../PageWrapperHeader/styles/PageWrapperHeader.styles";
import { ThemeToggle } from "../ThemeToggle";
import useNavigationalTabsQuery, {
  ContentfulTab,
  ContentfulToggle,
  UseQueries
} from "./queries";

const ProfilePageLink = ({
  pageContext,
  isActive
}: {
  pageContext: PageContext;
  isActive: boolean;
}) => {
  return (
    <NavIconWrapper isActive={isActive} id="profile">
      <InteractiveIcon
        srText="navigate to profile page"
        onClick={() => {
          navigate(`/${pageContext?.formattedLocale}/profile`);
        }}
        group="system"
        name="admin"
        size={4}
      />
    </NavIconWrapper>
  );
};

interface PageWrapperNavigationProps {
  pageContext: PageContext;
  path: string;
  toggleTheme: () => void;
  pageHeader: PageHeaderNode;
}

export function PageWrapperNavigation({
  pageContext,
  path,
  toggleTheme,
  pageHeader
}: PageWrapperNavigationProps) {
  const queries: UseQueries = useNavigationalTabsQuery();

  const {
    allContentfulTabs,
    allContentfulToggle
  }: {
    allContentfulTabs: ContentfulTab;
    allContentfulToggle: ContentfulToggle;
  } = queries;

  const { locale } = pageContext;

  const routes = allContentfulTabs.nodes
    .filter((route) => route.node_locale === locale)
    .sort((a, b) => a.order - b.order);

  const themesLabels = allContentfulToggle.edges.filter(
    (theme) => theme.node.node_locale === locale
  );

  return (
    <Styled.NavContainer>
      {path.includes("dashboard") ? (
        <Styled.TitleContainer>
          <Styled.VFDashboardBusinessLogo />
        </Styled.TitleContainer>
      ) : null}

      <Styled.NavItems>
        {path.includes("dashboard") ? <Styled.VFDashboardToggleLogo /> : null}

        <Styled.NavTabContainer role="tablist">
          {path.includes("dashboard") ? null : <Styled.VFLogoWithNoBrandName />}
          <NavigationalTabs
            onTabClick={(id) => {
              const pathnameArr = location.pathname.split("/");
              if (pathnameArr[pathnameArr.length - 1] !== id) {
                navigate(`/${pageContext?.formattedLocale}/${id}`);
                trackMenuClick({
                  page_locale: pageContext?.locale,
                  journey_type: id
                });
              }
            }}
            externallySetTabId={
              path.split("/").filter((pathname: any) => !!pathname)[1]
            }
          >
            {routes.map((tab) => (
              <Tab
                text={tab.label}
                key={tab.label}
                id={tab.contentfulid.replace(" ", "").toLowerCase()}
              />
            ))}
          </NavigationalTabs>
        </Styled.NavTabContainer>

        <Styled.WidgetOverlay>
          <Styled.DesktopContainer>
            <ThemeToggle
              toggleTheme={toggleTheme}
              themesLabels={themesLabels}
            />
          </Styled.DesktopContainer>

          <Cart formattedLocale={pageContext?.formattedLocale} />
          <ProfilePageLink
            isActive={path.includes("profile")}
            pageContext={pageContext}
          />
          <UpdatesWidget
            locale={pageContext.locale}
            formattedLocale={pageContext?.formattedLocale}
            // changelog={pageContext.hubUpdates}
            // CYB-18395 : Hub Updates to Be Hidden for Soft Launch - disabling hubUpdates Props
          />
          <MobileNavigation
            pageContext={pageContext}
            toggleTheme={toggleTheme}
            pageHeader={pageHeader}
          />
        </Styled.WidgetOverlay>
      </Styled.NavItems>
    </Styled.NavContainer>
  );
}
