//@ts-expect-error
import { MockEvent, EventSource as MockEventSource } from "mocksse";
import { useEffect, useRef, useState } from "react";

import { useAuth } from "../../../context/auth/useAuth";
import { generateSetFromArray } from "../../utilities";
import { defaultDetailedStatus, defaultOptions } from "./defaults";
import { onError, onMessage } from "./handlers";
import { mockedData } from "./mockSource";
import {
  EventSourceDetailedStatus,
  OptionsType,
  UseEventSourceReturnType
} from "./types";

/**
 * Custom React Hook for handling EventSource connections.
 *
 * @template T - The type of data expected from the EventSource.
 * @param {string} url - The URL to establish the EventSource connection.
 * @param {OptionsType} [options=defaultOptions] - Additional options for the EventSource connection.
 * @returns {UseEventSourceReturnType<T>} An object containing data, loading status, and a refetch function.
 */

export function useEventSource<T = unknown>(
  url: string,
  options: OptionsType = defaultOptions
): UseEventSourceReturnType<T> {
  // Merge default options with user-provided options
  const mergedOptions = {
    ...defaultOptions,
    ...options
  };
  const finalUrl = `${process.env.GATSBY_CYBERHUB_MIDDLEWARE_BASE_URL}/v1/${url}`;
  // State to keep track of the EventSource ready states
  // commenting for now, waiting for some of the API's to stop failing,
  // in order to evaluate the errors
  const [lastReadyState, setLastReadyState] = useState<(number | undefined)[]>(
    []
  );

  // State to store the data received from the EventSource
  const [data, setData] = useState<T[] | undefined>();

  // State to track the detailed status (isLoading and isClosed)
  const [detailedStatus, setDetailedStatus] =
    useState<EventSourceDetailedStatus>(defaultDetailedStatus);

  // State to trigger a refetch of data
  const [retry, setRetry] = useState<boolean>(false);

  // A reference to the EventSource instance
  const source = useRef<EventSource | null>(null);

  const { checkTimeExpirationAuthState } = useAuth();

  const dateNow = Date.now();

  const mockCondition =
    JSON.parse(process.env.GATSBY_USE_MOCKS ?? "false") &&
    Object.prototype.hasOwnProperty.call(mockedData, url);

  const instance: typeof EventSource = mockCondition
    ? MockEventSource
    : EventSource;

  // Effect to update the ready state history when it changes
  // in order to evaluate the errors
  useEffect(() => {
    if (source?.current) {
      setLastReadyState((prevState) => {
        const storedState = [...prevState, source?.current?.readyState].slice(
          -3
        );
        return storedState;
      });
    }
  }, [source?.current?.readyState]);

  // Main effect for handling the EventSource connection
  useEffect(() => {
    setData([]);

    if (!url || !mergedOptions?.enabled) {
      // If URL is missing or the request is disabled, close the connection.
      setDetailedStatus({
        isLoading: false,
        isClosed: true
      });
      return undefined;
    }

    if (data && !retry) {
      // If data is already available and retry is not requested,
      // return the cached data immediately.
      setDetailedStatus({
        isLoading: false,
        isClosed: false
      });
      return undefined;
    }

    // Check if the session is expired
    checkTimeExpirationAuthState(dateNow);

    setDetailedStatus({
      isLoading: true,
      isClosed: false
    });
    // Create a new EventSource instance
    const es = new instance(finalUrl, { withCredentials: true });

    source.current = es;

    // Array to store incoming messages
    let messages: T[] = [];

    if (mockCondition) {
      new MockEvent({
        url: finalUrl,
        setInterval: [600],
        responses: mockedData[url]
      });

      es.addEventListener("message", (event) => {
        onMessage({
          event,
          newData: messages,
          storedData: data,
          setDataCallback: setData,
          options: mergedOptions,
          setStatusCallback: setDetailedStatus
        });

        setLastReadyState([0, 1, 2]);

        if (mergedOptions.isSingleEntity) es.close();
      });
    }
    // Event handler for incoming messages
    es.onmessage = (event: MessageEvent) => {
      onMessage({
        event,
        newData: messages,
        storedData: data,
        setDataCallback: setData,
        options: mergedOptions,
        setStatusCallback: setDetailedStatus
      });
    };

    // Event handler for errors
    es.onerror = (event) => {
      onError({
        event,
        eventSource: es,
        options: mergedOptions,
        setStatusCallback: setDetailedStatus
      });
    };

    // Cleanup function to close the EventSource connection
    return () => {
      source.current = null;
      messages = [];
    };
  }, [url, retry, mergedOptions?.enabled]);

  // Function to trigger a data refetch
  const refetch = () => {
    setRetry(true);
  };

  // Return data, loading status, and refetch function
  return {
    data: options?.isSingleEntity ? data?.[0] : generateSetFromArray(data),
    isLoading: detailedStatus.isLoading,
    isClosed: lastReadyState.length === 3,
    isError: lastReadyState.length === 2,
    refetch
  };
}
