import React from "react";

import renderer from "../../../components/DynamicJourney/renderer";
import { FullscreenModal } from "../../../components/FullScreenModal";
import { typeEqualsTo } from "../../../lib";
import { ContentfulJsonForm } from "../form.types";

export function formRenderer(form: string | Object) {
  const normalizedForm = typeEqualsTo(form, "string")
    ? JSON.parse(form as string)
    : form;
  return renderer(normalizedForm);
}

export function DynamicJourneyModal({
  heading,
  show = false,
  form,
  onClose
}: {
  heading: string | null;
  show?: boolean;
  form?: ContentfulJsonForm | null;
  onClose: any;
}) {
  function unloadNavigation() {
    if (window.confirm("Are you sure you want to exit?")) {
      onClose();
    }
  }

  return (
    <FullscreenModal
      title={heading ? heading : ""}
      show={show}
      onClose={() => unloadNavigation()}
    >
      {form?.internal?.content ? formRenderer(form?.internal?.content) : null}
    </FullscreenModal>
  );
}
