import styled from "styled-components";

export const ItemWithBadgeContainer = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  & > span:last-of-type {
    ${() => `
      display: inline-flex;
      position: absolute;
      right: -8px;
      top: -7px;
    `}
  }
`;
