import * as React from "react";
import { ErrorInfo, ReactNode } from "react";

import { StateNotification } from "@source-web/state-notification";

import { Grid } from "@vfuk/core-grid";

import * as Styled from "./styles/ErrorBoundary.styles";

export interface ErrorBoundaryPropsType {
  children?: ReactNode;
}

export interface ErrorBoundaryStateType {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryPropsType,
  ErrorBoundaryStateType
> {
  constructor(props: ErrorBoundaryPropsType) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Styled.ErrorMessageContainer>
          <Grid maxWidth="spring">
            <StateNotification
              state="error"
              heading={{
                text: "Something went wrong. Please refresh"
              }}
            >
              <details style={{ whiteSpace: "pre-wrap" }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo.componentStack}
              </details>
            </StateNotification>
          </Grid>
        </Styled.ErrorMessageContainer>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
