import React, { useEffect, useRef, useState } from "react";

import { PageContext } from "../../../../types";
import { ThemedSection } from "../../../ThemedSection";
import { PageWrapperNavigation } from "../PageWrapperNavigation";
import { PageWrapperOptions } from "../PageWrapperOptions/";
import usePageWrapperHeaderQuery from "./queries";
import * as Styled from "./styles/PageWrapperHeader.styles";

interface PageWrapperHeaderProps {
  pageContext: PageContext;
  theme?: any;
  path: string;
  toggleTheme: () => void;
}

export interface PageHeaderNode {
  logoutTitle: string;
  languageTitle: string;
  node_locale: string;
}

function PageWrapperHeader({
  pageContext,
  path,
  toggleTheme
}: PageWrapperHeaderProps) {
  const barWrapperOptions = useRef<HTMLDivElement>(null);
  const barNavigation = useRef<HTMLDivElement>(null);

  const { allContentfulPageHeader } = usePageWrapperHeaderQuery();

  const filterPageHeader = allContentfulPageHeader.nodes.filter(
    (node: PageHeaderNode) => node.node_locale === pageContext.locale
  );

  const [positionNavigation, setPositionNavigation] = useState<
    number | undefined
  >();
  const [fixed, setFixed] = useState(false);

  const checkPosition = () => {
    if (barWrapperOptions.current) {
      const positionBarNavigation =
        barNavigation?.current?.getBoundingClientRect().height;
      if (window.scrollY >= 50) {
        setFixed(true);
        positionBarNavigation && setPositionNavigation(positionBarNavigation);
      } else {
        setFixed(false);
      }
    }
  };

  useEffect(() => {
    checkPosition();
    window.addEventListener("scroll", checkPosition);
    return () => {
      window.removeEventListener("scroll", checkPosition);
    };
  }, [fixed]);

  return (
    <Styled.Header
      className={fixed ? "navigationFixed" : ""}
      marginBottom={positionNavigation}
      data-testid="page-wrapper-header"
    >
      <ThemedSection appearance="secondary">
        <div ref={barWrapperOptions} data-testid="barWrapperOptions">
          {!path.includes("signIn") &&
          !path.includes("downtime") &&
          path !== "/" ? (
            <Styled.OptionsContainer>
              <PageWrapperOptions
                pageContext={pageContext}
                pageHeader={filterPageHeader[0]}
              />
            </Styled.OptionsContainer>
          ) : null}

          {path.includes("signIn") ? (
            <Styled.TitleContainer>
              <Styled.VFBusinessLogo data-testid="VFBusinessLogo" />
            </Styled.TitleContainer>
          ) : null}
        </div>

        <Styled.FixedContainer
          className={`fixedContainer ${fixed ? "navigationFixed" : ""}`}
          ref={barNavigation}
          data-testid="barNavigation"
        >
          {!path.includes("signIn") &&
          path !== "/" &&
          !path.includes("downtime") ? (
            <PageWrapperNavigation
              toggleTheme={toggleTheme}
              path={path}
              pageContext={pageContext}
              pageHeader={filterPageHeader[0]}
            />
          ) : null}
        </Styled.FixedContainer>
      </ThemedSection>
    </Styled.Header>
  );
}

export default PageWrapperHeader;
