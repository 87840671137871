//@ts-nocheck

/*
#####################################
  TS check is disabled.
  MatrixTable throw TS errors 
  due to lack of TableRows/TableCells 
  not allowing Conditional Rendering
#####################################
*/
import * as React from "react";

import { MatrixTable } from "@source-web/matrix-table";
import { TableCell, TableRow } from "@source-web/table";

import { WrapperSelectInput } from "../DynamicJourney/WrapperComponents/Inputs";
import * as Styled from "./style/MatrixTable.style";

const WrappedMatrixTable = ({
  leftMargin,
  horizontalTitle,
  horizontalHeadings,
  verticalHeadings,
  rows
}: any) => {
  return (
    <Styled.TableWrapper>
      <div style={{ marginBottom: "-35px" }}>
        <span style={{ marginLeft: "12px" }}>{horizontalTitle}</span>
      </div>
      <MatrixTable
        invertCollapse={true}
        horizontalHeadings={horizontalHeadings}
        verticalHeadings={verticalHeadings}
      >
        {rows.length ? (
          rows?.map((row) => {
            return (
              <TableRow key={row?.rowNumber}>
                <TableCell>
                  <span style={{ marginLeft: leftMargin }}></span>
                </TableCell>
                {row.inputs?.map((field) => {
                  return (
                    <TableCell key={field.id}>
                      <Styled.SelectInputMFW>
                        <WrapperSelectInput
                          selectInput={{
                            options: field.options,
                            placeholder: field.placeholder,
                            name: field.name,
                            value: field.value,
                            id: field.id
                          }}
                        />
                      </Styled.SelectInputMFW>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        ) : (
          <TableCell />
        )}
      </MatrixTable>
    </Styled.TableWrapper>
  );
};

export default WrappedMatrixTable;
