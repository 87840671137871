import dayjs from "dayjs";
import "dayjs/locale/it";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Heading } from "@source-web/heading";
import { LoadingSpinner } from "@source-web/loading-spinner";
import { Paragraph } from "@source-web/paragraph";

import { useAuth } from "../../../../context/auth/useAuth";
import { updateNotification } from "../../../../lib";
import ConfirmationModal from "../../../ConfirmationModal";
import { FormattedNotification } from "../../../NotificationBuilder/NotificationBuilder.types";
import { ContentfulUpdatesWidget, getDates } from "../../UpdatesWidget";
import * as CommonStyles from "../../styles/UpdatesWidget.style";
import NotificationTile from "./components/NotificationTile";
import * as Styled from "./styles/NotificationsTab.styles";

dayjs.extend(calendar);
dayjs.extend(updateLocale);

dayjs.updateLocale("it", {
  calendar: {
    sameDay: "[Oggi alle] H:mm",
    nextDay: "[Domani alle] H:mm",
    nextWeek: "dddd [prossimo]",
    lastDay: "[Ieri alle] H:mm",
    lastWeek: "dddd [ultimo]",
    sameElse: "YYYY-MM-DD"
  }
});

type setModalStateType = React.Dispatch<
  React.SetStateAction<{
    isOpen: boolean;
    notification: string;
  }>
>;

const defaultModalState = {
  isOpen: false,
  notification: ""
};

export interface NotificationsTabProps {
  navigateToNotification: (entry: FormattedNotification) => void;
  notificationsQuery: {
    isLoading: boolean;
    data: FormattedNotification[];
  };
  notificationDates: string[];
  closeWidget(): void;
  updateWidgetData: ContentfulUpdatesWidget;
  formattedLocale: string;
}

function renderConfirmationModal({
  modalState,
  setModalState,
  handleNotificationRemoval,
  updateWidgetData
}: {
  modalState: {
    isOpen: boolean;
    notification: string;
  };
  setModalState: setModalStateType;
  handleNotificationRemoval: () => void;
  updateWidgetData: ContentfulUpdatesWidget;
}) {
  return (
    <ConfirmationModal
      isOpen={modalState.isOpen}
      isCloseable={true}
      onCloseCb={() => setModalState(defaultModalState)}
      heading={{
        justify: "center",
        text: updateWidgetData.confirmationModalTitle,
        size: 3
      }}
      cancelBtnProps={{
        text: updateWidgetData.confirmationModalCancelButton,
        appearance: "secondary",
        onClick: () => {
          setModalState(defaultModalState);
        }
      }}
      confirmBtnProps={{
        text: updateWidgetData.confirmationModalConfirmButton,
        appearance: "primary",
        onClick: () => {
          handleNotificationRemoval();
        }
      }}
    >
      <Paragraph>{updateWidgetData.confirmationModalText}</Paragraph>
    </ConfirmationModal>
  );
}

function renderNotificationList({
  notificationDates,
  notifications,
  navigateToNotification,
  setModalState,
  closeWidget,
  formattedLocale
}: {
  notificationDates: NotificationsTabProps["notificationDates"];
  notifications: FormattedNotification[];
  navigateToNotification: (entry: FormattedNotification) => void;
  setModalState: setModalStateType;
  closeWidget: () => void;
  formattedLocale: string;
}) {
  const handleButtonClick = (entry: FormattedNotification) => {
    navigateToNotification(entry);
    closeWidget();
  };

  const handleClose = (entry: FormattedNotification) => {
    setModalState({
      isOpen: true,
      notification: entry.notificationUniqueKey
    });
  };

  return notificationDates.flatMap((date) => {
    const { isTodayDate, isYesterdayDate, differenceFromNow, day, month } =
      getDates(date, formattedLocale);

    const notificationsForDate = notifications?.filter(
      ({ dateReceived, caption, read }: FormattedNotification) =>
        date === dateReceived && !read && caption
    );

    return notificationsForDate?.map((entry: FormattedNotification) => (
      <React.Fragment key={`${date}-${entry.notificationUniqueKey}`}>
        <Styled.DateDividerContainer>
          <Heading level={5} weight={3} text={`${day} ${month}`} />
          <Paragraph weight={1} size={2}>
            {isTodayDate ||
              (isYesterdayDate && dayjs(date).calendar().split(" ")[0])}
            {!isTodayDate && !isYesterdayDate && differenceFromNow}
          </Paragraph>
        </Styled.DateDividerContainer>

        <React.Fragment key={entry.serviceName}>
          <NotificationTile
            serviceData={entry}
            onButtonClick={() => handleButtonClick(entry)}
            onClose={() => handleClose(entry)}
          />
        </React.Fragment>
      </React.Fragment>
    ));
  });
}

const NotificationsTab = ({
  navigateToNotification,
  notificationsQuery,
  notificationDates,
  closeWidget,
  updateWidgetData,
  formattedLocale
}: NotificationsTabProps) => {
  const { removeNotificationFromState } = useAuth();
  const { isLoading, data } = notificationsQuery;

  const [notifications, setNotifications] = useState<
    Array<FormattedNotification>
  >(data || []);

  const queryClient = useQueryClient();

  const [modalState, setModalState] = useState(defaultModalState);

  const { checkTimeExpirationAuthState } = useAuth();

  const dateNow = Date.now();

  const { mutate, isLoading: isUpdatingNotification } = useMutation(
    () => updateNotification(modalState.notification, { read: true }),
    {
      onSuccess: () => {
        setNotifications((prev) =>
          prev.map((notification) => {
            if (
              notification.notificationUniqueKey === modalState.notification
            ) {
              return {
                ...notification,
                read: true
              };
            }
            return notification;
          })
        );
        queryClient.invalidateQueries("notifications");
      },
      onSettled: () => {
        checkTimeExpirationAuthState(dateNow);
      }
    }
  );

  const handleUpdateNotification = () => {
    mutate();
  };

  function handleNotificationRemoval() {
    try {
      removeNotificationFromState(modalState.notification);
      handleUpdateNotification();
    } catch (err) {
      console.error(err);
    } finally {
      setModalState(defaultModalState);
    }
  }

  useEffect(() => {
    setNotifications(data);
  }, [data]);

  return (
    <>
      {isLoading || isUpdatingNotification ? (
        <CommonStyles.LoaderWrapper>
          <Styled.LoadingContainer>
            <LoadingSpinner appearance="secondary" size={5} />
          </Styled.LoadingContainer>
        </CommonStyles.LoaderWrapper>
      ) : (
        <Styled.NotificationContainer>
          {renderNotificationList({
            notificationDates: notificationDates,
            notifications: notifications,
            navigateToNotification,
            setModalState,
            closeWidget,
            formattedLocale
          })}
        </Styled.NotificationContainer>
      )}

      {renderConfirmationModal({
        modalState,
        setModalState,
        handleNotificationRemoval,
        updateWidgetData
      })}
    </>
  );
};

export default NotificationsTab;
