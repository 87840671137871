import React, { Children, ReactElement, isValidElement } from "react";

import { Container } from "@source-web/container";
import { Heading } from "@source-web/heading";

import * as Styled from "./styles/ContainerWithTitle.styles";

export type IContainerWithTitleThemes = "primary" | "secondary";

export const ContainerWithTitle = ({
  children,
  bodyTheme = "primary",
  headerTheme = "primary",
  title
}: {
  title: string;
  children: ReactElement | ReactElement[];
  bodyTheme?: IContainerWithTitleThemes;
  headerTheme?: IContainerWithTitleThemes;
}) => {
  return (
    <Container>
      <Styled.ContainerWithLabelHeader headerTheme={headerTheme}>
        <Heading level={5} weight={3}>
          {title}
        </Heading>
      </Styled.ContainerWithLabelHeader>
      <Styled.ContainerWithLabelBody bodyTheme={bodyTheme}>
        {Children.map(children, (children) =>
          isValidElement(children) ? children : null
        )}
      </Styled.ContainerWithLabelBody>
    </Container>
  );
};
