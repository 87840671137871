import { FullScreenModalThemeType } from "./FullScreenModal.theme.types";

const fullScreenModalLocal = (theme: any): FullScreenModalThemeType => {
  return {
    background: theme.color.monochrome2.default,
    footerBackground: theme.color.monochrome1.default,
    footerBorder: theme.color.monochrome4.default
  };
};

export default fullScreenModalLocal;
