export * from "./errorMessages";
export * from "./regex";

export const notificationActions = {
  download: "download",
  buy: "buy",
  onboarding: "onboarding",
  campaign: "campaign",
  normal: "normal",
  redirect: "redirect"
};

export const formFlowEnums = {
  campaign: "campaign",
  walkthrough: "walkthrough",
  onboarding: "onboarding",
  vciso: "vciso",
  serviceRquest: "serviceRequest"
};

export const downtimePageStatus = {
  planned: "planned",
  unplanned: "unplanned"
};

export const sscInvitationMessageFromCyberHub =
  "This invite was requested via CyberHub";
