import { QueryCache, QueryClient } from "react-query";

const tenMinutesInMilisseconds = 600000;

export const staleCacheConfiguration = {
  staleTime: tenMinutesInMilisseconds,
  cacheTime: tenMinutesInMilisseconds
};

export const cyberhubQueryCache = new QueryCache();

export const defaultQueryOptions = {
  queryCache: cyberhubQueryCache,
  defaultOptions: {
    queries: {
      ...staleCacheConfiguration,
      retry: false,
      refetchOnWindowFocus: false
    }
  }
};
export const cyberhubQueryClient = new QueryClient(defaultQueryOptions);
