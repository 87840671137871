import { useFormikContext } from "formik";
import * as React from "react";
import { useDispatch } from "react-redux";

import { globalHistory } from "@reach/router";

import { addFields } from "../../store/slices/servicesPage";

const UnloadPrompt = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();
  React.useEffect(() => {
    return globalHistory.listen(({ action, location }) => {
      if (
        (action === "PUSH" && !location.pathname.includes("campaign")) ||
        action === "POP"
      ) {
        dispatch(addFields({ values: values }));
      }
    });
  });

  return null;
};

export default UnloadPrompt;
