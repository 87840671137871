// Main Dependencies
import { useField } from "formik";
import React from "react";

import { Pill } from "@source-web/pill";
// VFUK Components
import { TextInputWithLabel } from "@source-web/text-input-with-label";

// Types
import { TextInputProps } from "../../WrapperComponents.types";
// Local Imports
import * as Styles from "../../styles/Wrappers.style";

function isValidEmail(email: string) {
  return /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email);
}

function emailMapper(emailList: string) {
  return emailList.split(",").map((mail) => {
    if (!isValidEmail(mail)) return;

    return <Pill key={mail} text={mail} />;
  });
}

const WrapperMultiEmailField = ({
  fieldWrapper,
  textInput,
  showEmails = true
}: TextInputProps & { showEmails?: boolean }) => {
  const [field, meta] = useField<string>(textInput.id);
  return (
    <>
      <Styles.InputWrapper>
        <TextInputWithLabel
          fieldWrapper={{
            ...fieldWrapper,
            helpText: meta.touched && meta.error ? meta.error : undefined
          }}
          textInput={{ ...field, ...textInput }}
          state={meta.touched && meta.error ? "error" : undefined}
        />
      </Styles.InputWrapper>

      {showEmails && (
        <Styles.PillWrapper>
          {field?.value && emailMapper(field.value)}
        </Styles.PillWrapper>
      )}
    </>
  );
};

export default WrapperMultiEmailField;
