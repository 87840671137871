// @ts-nocheck
// Main Dependencies
import dayjs, { extend } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Form, Formik, useField, useFormikContext } from "formik";
import _ from "lodash";
import React, {
  Children,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import { ThemeProvider, useTheme } from "styled-components";

// VFUK Components
import { Button, ButtonProps } from "@source-web/button";
import {
  CalendarPickerWithLabel,
  CalendarPickerWithLabelProps
} from "@source-web/calendar-picker-with-label";
import { CheckboxList, CheckboxListProps } from "@source-web/checkbox-list";
import { Container } from "@source-web/container";
import { Divider } from "@source-web/divider";
import { FieldWrapperProps } from "@source-web/field-wrapper";
import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";
import { Link } from "@source-web/link";
import { MatchMedia } from "@source-web/match-media";
import { Paragraph } from "@source-web/paragraph";
import { ProgressBar } from "@source-web/progress-bar";
import { RadioButtonList } from "@source-web/radio-button-list";
import { SmallButton } from "@source-web/small-button";
import { Spacing } from "@source-web/spacing";
import { Span } from "@source-web/span";
import { StateNotification } from "@source-web/state-notification";
import { Table, TableCell, TableRow } from "@source-web/table";
import { TextInput } from "@source-web/text-input";
import { Toggle } from "@source-web/toggle";
import { ToggleList } from "@source-web/toggle-list";

import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";
import { Stepper } from "@vfuk/core-stepper";
import { StepperProps } from "@vfuk/core-stepper/dist/Stepper.types";
import WS10Theme from "@vfuk/core-theme-ws10";

import { PrimaryButton } from "../../../components/PrimaryButton";
import { useCampaignFormContext } from "../../../context/forms/useForms";
import { ActionButtonWrapperHrefNoPadding } from "../../../pageTemplates/servicesPage/styles/ServicesPage.styles";
import {
  addFields,
  changeStep,
  changeSubStep,
  resetState
} from "../../../store/slices/servicesPage";
import { FullScreenModalFooter } from "../../FullScreenModal";
import WrappedMatrixTable from "../../MatrixTable/MatrixTable";
import WrappedPointTable from "../../PointTable/PointTable";
import ScrollToId from "../../ScrollToId";
import UnloadPrompt from "../../UnloadPrompt";
import FormObserver from "../Plugins/formObservable";
import {
  WrappedTextAreaWithLabel,
  WrapperMultiEmailField,
  WrapperSelectInput,
  WrapperTextInput
} from "./Inputs";
import { WrapperCheckboxList } from "./Inputs/WrapperCheckboxList";
// Types
import {
  FullScreenModalFooterProps,
  IToggleStateObject,
  SideBySideTableProps,
  SubStepperProps,
  TextInputProps,
  TimeSlotErrorProps,
  TimeSlotProps,
  WrappedButtonListProps,
  WrappedConditionalToggleProps,
  WrappedHeaderProps,
  WrappedParagraphProps,
  WrappedTableProps,
  WrappedTimeSlotProps,
  WrappedTimelinesProps,
  WrappedToggleListProps
} from "./WrapperComponents.types";
// Local Imports
import * as Styles from "./styles/Wrappers.style";

// Dayjs extend functionality
extend(advancedFormat);

export const WrappedParagraph = ({
  spacing,
  paragraph,
  width
}: WrappedParagraphProps) => {
  return (
    <Styles.ParagraphWrapper width={width}>
      <Spacing {...spacing}>
        <Paragraph {...paragraph}>{paragraph.children}</Paragraph>
      </Spacing>
    </Styles.ParagraphWrapper>
  );
};

interface ToggleProps {
  toggle: string;
  value: string;
  isActive: boolean;
}

export const WrappedConditionalToggle = ({
  name,
  firstToggleProps,
  secondToggleProps
}: WrappedConditionalToggleProps) => {
  const [activeFirstToggle, setActiveFirstToggle] = useState(false);
  const [activeSecondToggle, setActiveSecondToggle] = useState(false);

  const [field, meta, { setValue }] = useField<ToggleProps[]>(name);

  const toggle1Props = { ...field, ...firstToggleProps };
  const toggle2Props = { ...field, ...secondToggleProps };

  useEffect(() => {
    if (!field?.value?.length) {
      const param: ToggleProps[] = [
        {
          toggle: firstToggleProps.name,
          value: firstToggleProps.value,
          isActive: false
        },
        {
          toggle: secondToggleProps?.name,
          value: secondToggleProps?.value,
          isActive: false
        }
      ];
      setValue(param);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateToggleStates = (value: string, toggles: ToggleProps[]) => {
    const itemIndex = toggles.findIndex((toggleItem: ToggleProps) => {
      return toggleItem?.value === value;
    });
    const currentValues = toggles[itemIndex];
    const newToggleStates = [...toggles];
    newToggleStates.splice(itemIndex, 1, {
      ...currentValues,
      isActive: !currentValues?.isActive
    });
    setValue(newToggleStates);
  };

  const handleHelperText = () =>
    activeSecondToggle
      ? secondToggleProps?.toggleHelperText?.isActive
      : secondToggleProps?.toggleHelperText?.isNotActive;

  const isConditionalToggle = () => {
    if (!secondToggleProps || !activeFirstToggle) return;
    return (
      <>
        <Toggle
          {...toggle2Props}
          state={meta.touched && meta.error ? "error" : undefined}
          isActive={activeSecondToggle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateToggleStates(e.target.value, field.value);
            setActiveSecondToggle(!activeSecondToggle);
          }}
        >
          {toggle2Props.label}
        </Toggle>
        <WrappedParagraph
          {...toggle2Props.paragraphProps}
          paragraph={{ children: handleHelperText() }}
        />
      </>
    );
  };

  return (
    <div>
      <Toggle
        {...toggle1Props}
        state={meta.touched && meta.error ? "error" : undefined}
        isActive={activeFirstToggle}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          updateToggleStates(e.target.value, field.value),
            setActiveFirstToggle(!activeFirstToggle);
        }}
      >
        {toggle1Props.label}
      </Toggle>
      <div />
      {isConditionalToggle()}
    </div>
  );
};

export const WrappedToggleList = ({ items, name }: WrappedToggleListProps) => {
  const [field, meta, { setValue }] = useField<IToggleStateObject[]>(name);

  const mountField = useCallback(() => {
    if (!field?.value?.length) {
      setValue(items);
    }
  }, [field?.value, items, setValue]);

  useEffect(() => {
    mountField();
  }, [mountField]);

  const updateToggleStates = (value: string, toggles: IToggleStateObject[]) => {
    const itemIndex = toggles.findIndex((toggleItem: IToggleStateObject) => {
      return toggleItem?.value === value;
    });

    const currentValues = toggles[itemIndex];
    const newToggleStates = [...toggles];
    newToggleStates.splice(itemIndex, 1, {
      ...currentValues,
      isActive: !currentValues?.isActive,
      checked: !currentValues?.checked
    });

    setValue(newToggleStates);
  };

  return (
    <Styles.InputWrapper>
      <Styles.ToggleListWrapper>
        {field?.value ? (
          <ToggleList
            state={meta.touched && meta.error ? "error" : undefined}
            fieldWrapper={{
              label: "toggles",
              showLabel: false
            }}
            toggles={{
              align: "left",
              id: name,
              groupName: `ToggleList-${name}`,
              onChange: (event) => {
                updateToggleStates(event.target.value, field.value);
              },
              items: field?.value
            }}
          />
        ) : null}
      </Styles.ToggleListWrapper>
    </Styles.InputWrapper>
  );
};

export const WrappedButtonHref = (props: ButtonProps) => {
  return (
    <ActionButtonWrapperHrefNoPadding>
      <PrimaryButton {...props} />
    </ActionButtonWrapperHrefNoPadding>
  );
};

export const WrappedCalendarPickerWithLabel = ({
  fieldWrapper,
  calendarPicker,
  includedDates,
  onChangeAction
}: CalendarPickerWithLabelProps & {
  includedDates?: any;
  onChangeAction?: (value?: Record<string, number | undefined>) => void;
}) => {
  const [field, meta, { setValue }] = useField(calendarPicker.id);

  const handleChange = (value: Date | null) => {
    setValue(value?.getTime());
    onChangeAction && onChangeAction({ date: value?.getTime() });
  };

  return (
    <Styles.CustomCalendarPickerWrapper>
      <CalendarPickerWithLabel
        fieldWrapper={{
          ...fieldWrapper,
          helpText: meta.touched && meta.error ? meta.error : undefined
        }}
        calendarPicker={{
          ...field,
          ...calendarPicker,
          onChange: handleChange,
          includeDates: includedDates
            ? includedDates
            : calendarPicker.includeDates,
          openToDate: new Date(),
          value: field.value ? new Date(field.value) : new Date()
        }}
        state={meta.touched && meta.error ? "error" : undefined}
      />
    </Styles.CustomCalendarPickerWrapper>
  );
};

export const WrappedTimelines = ({
  startDate,
  endDate
}: WrappedTimelinesProps) => {
  return (
    <Styles.InputWrapper>
      <Styles.CalendarWrapper>
        <WrappedCalendarPickerWithLabel {...startDate} />
        <WrappedCalendarPickerWithLabel {...endDate} />
      </Styles.CalendarWrapper>
    </Styles.InputWrapper>
  );
};

export const WrappedHeader = ({
  spacing,
  subHeading,
  heading
}: WrappedHeaderProps) => {
  return (
    <Spacing {...spacing}>
      <Styles.FormHeadingWrapper>
        <Heading {...heading} />
      </Styles.FormHeadingWrapper>
      <Paragraph {...subHeading} />
    </Spacing>
  );
};

export const WrapperFooter = ({
  submitButtonProps,
  cancelButtonProps,
  asyncStep,
  loading
}: Required<FullScreenModalFooterProps>) => {
  const { name } = useTheme();
  const { finalize } = useCampaignFormContext();
  const dispatch = useDispatch();
  const { isValid, values, submitForm, isSubmitting, setSubmitting } =
    useFormikContext();
  const currentStep = useSelector(
    (state) => state.servicesPage.form.currentStep
  );

  const { store } = useContext(ReactReduxContext);

  const onModalClose = () => {
    if (
      store.getState().servicesPage.form.formType === "ServiceRequest" &&
      currentStep > 1
    ) {
      const payload = {
        step: currentStep - 1,
        values: store.getState().servicesPage.form.values[1],
        asyncStep: true,
        backStep: true
      };

      dispatch(addFields(payload));

      return dispatch(changeStep(payload));
    } else {
      dispatch(addFields({ values }));
      if (currentStep <= 1) {
        return finalize();
      }

      return dispatch(changeStep({ step: currentStep - 1 }));
    }
  };

  const handleSubmitButtonClick = async () => {
    setSubmitting(true);
    if (submitButtonProps?.htmlAttributes?.type === "submit") {
      dispatch(
        addFields({
          values
        })
      );

      await submitForm();
      return;
    }

    dispatch(
      changeStep({
        step: currentStep + 1,
        values: values,
        asyncStep: JSON.parse(asyncStep)
      })
    );
  };

  return (
    <ThemeProvider theme={WS10Theme}>
      <Styles.StyledFooterContainer name={name} isValid={isValid}>
        <FullScreenModalFooter
          submitButtonProps={{
            state: !isValid ? "disabled" : undefined,
            onClick: () => handleSubmitButtonClick(),
            loading: isSubmitting || loading,
            ...submitButtonProps
          }}
          cancelButtonProps={{
            ...cancelButtonProps,
            appearance: "alt1",
            onClick: () => onModalClose(),
            inverse: name !== "WS10"
          }}
        />
      </Styles.StyledFooterContainer>
    </ThemeProvider>
  );
};

export const WrapperRadioButtonList = ({
  orientation,
  items,
  name
}: WrappedButtonListProps) => {
  const [field, meta, { setValue }] = useField<any>(name);

  const currentStep = useSelector(
    (state) => state.servicesPage.form.currentStep
  );
  const stepValues = useSelector(
    (state) => state.servicesPage.form.values[currentStep - 1]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!field?.value?.length) {
      setValue(items[0].value);
    }
  }, [field?.value, field?.value?.length, items, setValue]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (stepValues) {
      const values: any = _.cloneDeep(stepValues);
      values[name] = event.target.value;
      dispatch(addFields({ values: values }));
    }
    setValue(event.target.value);
  };

  return (
    <RadioButtonList
      orientation={orientation}
      fieldWrapper={{
        label: "buttonList",
        showLabel: false,
        helpText: meta.touched && meta.error ? meta.error : undefined
      }}
      radioButtons={{
        groupName: `Buttonlist-${name}`,
        id: name,
        onChange: (event) => {
          handleOnChange(event);
        },
        checked: field.value,
        items: items
      }}
      state={meta.touched && meta.error ? "error" : undefined}
    />
  );
};

export const WrapperCheckboxListOld = ({
  items,
  name,
  fieldWrapper
}: {
  items: CheckboxListProps["checkboxes"]["items"][];
  name: string;
  fieldWrapper: FieldWrapperProps;
}) => {
  const [field, meta, { setValue }] =
    useField<CheckboxListProps["checkboxes"]["items"][]>(name);

  useEffect(() => {}, [field?.value, field?.value?.length, items, setValue]);

  const updateCheckboxStates = (
    event: React.ChangeEvent<HTMLInputElement>,
    checkboxes
  ) => {
    const itemIndex = checkboxes.findIndex((checkboxItem) => {
      return checkboxItem.value === event.target.value;
    });
    const currentValues = checkboxes[itemIndex];
    const newCheckboxesState = [...checkboxes];
    newCheckboxesState.splice(itemIndex, 1, {
      ...currentValues,
      checked: !currentValues?.checked
    });
    setValue(newCheckboxesState);
  };

  return (
    <Styles.CheckboxListContainer>
      <CheckboxList
        state={meta.touched && meta.error ? "error" : undefined}
        fieldWrapper={{ ...fieldWrapper }}
        checkboxes={{
          id: name,
          groupName: `CheckboxList-${name}`,
          onChange: (event) => {
            updateCheckboxStates(event, field.value);
          },

          items: field.value || items
        }}
      />
    </Styles.CheckboxListContainer>
  );
};

export const WrapperFooterSubStep = ({
  submitButtonProps,
  cancelButtonProps,
  asyncStep,
  loading,
  totalSubSteps
}: Required<FullScreenModalFooterProps>) => {
  const dispatch = useDispatch();
  const { isValid, values, submitForm } = useFormikContext();
  const subStep = useSelector((state) => state.servicesPage.form.subStep);
  const currentStep = useSelector(
    (state) => state.servicesPage.form.currentStep
  );

  const handleSubmit = () => {
    if (subStep === totalSubSteps - 1) {
      dispatch(addFields({ values: values }));
      submitForm();
    } else {
      dispatch(
        changeSubStep({
          step: asyncStep ? subStep : subStep + 1,
          values: values
        })
      );
    }
  };

  const onModalClose = () => {
    return subStep === 0
      ? dispatch(changeStep({ step: currentStep - 1, values: values }))
      : dispatch(changeSubStep({ step: subStep - 1, values: values }));
  };

  return (
    <Styles.StyledFooterContainer isValid={isValid}>
      <FullScreenModalFooter
        submitButtonProps={{
          ...submitButtonProps,
          state: !isValid ? "disabled" : undefined,
          onClick: () => handleSubmit(),
          loading: loading
        }}
        cancelButtonProps={{
          ...cancelButtonProps,
          onClick: () => onModalClose()
        }}
      />
    </Styles.StyledFooterContainer>
  );
};

export const WrappedTable = ({
  headings,
  table,
  stackOnSm,
  title
}: WrappedTableProps) => {
  return (
    <Styles.TableContainer>
      <span>{title}</span>
      <Table headings={headings} stackOnSm={stackOnSm}>
        {table.map((row, index) => (
          <TableRow key={index}>
            {row.data.map((cel, index) => {
              return <TableCell key={index}>{cel}</TableCell>;
            })}
          </TableRow>
        ))}
      </Table>
    </Styles.TableContainer>
  );
};

export const WrappedSideBySideTable = ({ children }: SideBySideTableProps) => {
  return (
    <>
      <Styles.TableContainerSideBySide>
        {children}
      </Styles.TableContainerSideBySide>
    </>
  );
};

export const WrapperSubStepper = ({
  totalSubSteps,
  name,
  spacing
}: SubStepperProps) => {
  const subStep = useSelector((state) => state.servicesPage.form.subStep);

  const handlePercentage = () => (100 / totalSubSteps) * (subStep + 1);

  return (
    <Styles.SubStepperContainer>
      <Spacing {...spacing}>
        <ProgressBar inverse value={handlePercentage} srLabel="Progress bar" />
        <Styles.SpanContainer>
          Step {subStep + 1} of {totalSubSteps}: {name}
        </Styles.SpanContainer>
      </Spacing>
    </Styles.SubStepperContainer>
  );
};

export const WrapperFooterNoStep = ({
  submitButtonProps,
  cancelButtonProps,
  onSubmit
}: Required<FullScreenModalFooterProps> & {
  onSubmit: () => void;
}) => {
  const { name } = useTheme();
  const { finalize } = useCampaignFormContext();

  function handleOnClose() {
    if (window.confirm("Are you sure you want to exit?")) {
      finalize();
    }
  }
  return (
    <ThemeProvider theme={WS10Theme}>
      <Styles.StyledFooterContainer name={name}>
        <FullScreenModalFooter
          submitButtonProps={{
            ...submitButtonProps,
            onClick: () => onSubmit()
          }}
          cancelButtonProps={{
            ...cancelButtonProps,
            onClick: () => handleOnClose(),
            appearance: "alt1",
            inverse: name !== "WS10"
          }}
        />
      </Styles.StyledFooterContainer>
    </ThemeProvider>
  );
};

export const FormStepper = ({
  children,
  currentStep,
  steps,
  showSteps = true,
  ...props
}: Required<StepperProps> & {
  children: ReactNode;
  showSteps?: boolean;
}) => {
  const content = Children.toArray(children)[currentStep - 1];

  return (
    <>
      {showSteps && currentStep > 0 && (
        <Styles.StepperContainer>
          <Stepper steps={steps} currentStep={currentStep || 1} {...props} />
        </Styles.StepperContainer>
      )}
      {content}
    </>
  );
};

export const WrapperFooterNoForm = ({
  submitButtonProps
}: Required<FullScreenModalFooterProps>) => {
  const { finalize } = useCampaignFormContext();
  const dispatch = useDispatch();

  const handleCancelClick = () => {
    if (window.confirm("Are you sure you want to exit?")) {
      dispatch(resetState());
      finalize();
    }
  };

  return (
    <FullScreenModalFooter
      submitButtonProps={{
        ...submitButtonProps,
        appearance: "primary",
        onClick: () => handleCancelClick()
      }}
    />
  );
};

export const OnboardingCard = ({
  children
}: {
  children: React.ReactElement;
}) => <Styles.OnboardingCardWrapper>{children}</Styles.OnboardingCardWrapper>;

export const WrappedFileInput = ({
  fieldWrapper,
  textInput,
  maxFileSize = 20
}: TextInputProps & { maxFileSize: number }) => {
  const [field, meta, { setValue, setError, setTouched }] = useField<string>(
    textInput.id
  );
  const [fileName, setFileInformation] = useState("");

  const toBase64 = async (file: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    const target: File | undefined = file.target.files
      ? file.target?.files[0]
      : undefined;

    const reader = new FileReader();

    if (FileReader && target) {
      const fileSize = target.size / 1024 / 1024;
      if (fileSize >= maxFileSize) {
        setError("File cannot be empty or exceed 20 MiB");
        return;
      }

      setFileInformation(target.name);
      reader.readAsDataURL(target);
      reader.onload = () => {
        const fileReaderResult: string | undefined = reader.result?.toString();
        if (fileReaderResult) {
          setValue(fileReaderResult);
        }
      };
      reader.onerror = () => {
        setError("Please enter a valid input.");
      };
    }
  };
  const error = meta.touched && meta.error;

  return (
    <Styles.FileInputContainer>
      <label htmlFor="upload">
        <Span>{fieldWrapper.label}</Span>
        <small>{fieldWrapper.subText}</small>
        <Styles.FileInputField error={!!error}>
          {!fileName ? textInput.placeholder : fileName}

          {error ? (
            <Icon name="error" group="state" />
          ) : (
            <Icon name="cloud-upload" group="system" />
          )}
        </Styles.FileInputField>

        <TextInput
          // Formik input which receives the B64 encoded string.
          {...{ ...field, ...textInput }}
        />

        <Span>
          <small>{error}</small>
        </Span>

        <input
          //  this input is hidden, gets triggered by the htmlFor on label.
          //  the base64 string will be stored in the Formik input above.
          accept="image/x-png,image/gif,image/jpeg"
          onChange={(event) => {
            toBase64(event);
          }}
          id="upload"
          type="file"
          name="upload"
        />
      </label>
    </Styles.FileInputContainer>
  );
};

export const TimeSlot = ({
  grid,
  gridRow,
  paragraph,
  link,
  dateProperty,
  hourProperty,
  onLinkClick
}: TimeSlotProps) => {
  const dispatch = useDispatch();
  const currentStep = useSelector(
    (state) => state.servicesPage.form.currentStep
  );

  const [fieldDate] = useField<string>(dateProperty);
  const [fieldHour] = useField<string>(hourProperty);
  const date = dayjs(Number(fieldDate.value)).format("Do MMM YYYY");
  const time = dayjs(String(fieldHour.value)).format("HH:mm");

  const timePlusOne = dayjs(String(fieldHour.value))
    .add(1, "hour")
    .format("HH:mm");

  const handleOnClick = () => {
    onLinkClick
      ? onLinkClick()
      : dispatch(changeStep({ step: currentStep - 1 }));
  };

  return (
    <Styles.TimeSlotWrapper>
      <Grid maxWidth={grid}>
        <GridRow>
          <GridColumn col={12}>
            <Paragraph {...paragraph}>
              <GridRow justify={gridRow.justify}>
                <Styles.TimeSlotsDateContainer>
                  {time}&nbsp;-&nbsp;{timePlusOne},&nbsp;{date}
                  {link.text && (
                    <Link onClick={() => handleOnClick()} {...link}>
                      {link.text}
                    </Link>
                  )}
                </Styles.TimeSlotsDateContainer>
              </GridRow>
            </Paragraph>
          </GridColumn>
        </GridRow>
      </Grid>
    </Styles.TimeSlotWrapper>
  );
};

const TimeSlotError = ({
  paragraph,
  link,
  button,
  dateProperty,
  hourProperty,
  onLinkClick
}: TimeSlotErrorProps) => {
  const dispatch = useDispatch();
  const currentStep = useSelector(
    (state) => state.servicesPage.form.currentStep
  );

  const [fieldDate] = useField<string>(dateProperty);
  const [fieldHour] = useField<string>(hourProperty);

  const date = dayjs(Number(fieldDate.value)).format("Do MMM YYYY");
  const time = dayjs(String(fieldHour.value)).format("HH:mm");

  const timePlusOne = dayjs(String(fieldHour.value))
    .add(1, "hour")
    .format("HH:mm");

  const theme = useTheme();

  const handleOnClick = () => {
    onLinkClick
      ? onLinkClick()
      : dispatch(changeStep({ step: currentStep - 1 }));
  };
  return (
    <Styles.ErrorWrapper>
      <StateNotification state="error">
        {time} - {timePlusOne}, {date}
        {link.text && (
          <Link
            onClick={() => {
              handleOnClick();
            }}
            {...link}
          >
            {link.text}
          </Link>
        )}
      </StateNotification>

      <Paragraph {...paragraph} />
      <PrimaryButton
        {...button}
        onClick={() => {
          handleOnClick();
        }}
        appearance={theme.name === "WS10" ? "primary" : "alt1"}
        inverse={theme.name === "WS10" ? false : true}
      />
    </Styles.ErrorWrapper>
  );
};

export const WrappedTimeSlots = ({
  isBooked,
  timeSlotProps,
  timeSlotErrorProps
}: WrappedTimeSlotProps) => {
  return isBooked ? (
    <TimeSlotError {...timeSlotErrorProps} />
  ) : (
    <TimeSlot {...timeSlotProps} />
  );
};

export const registry = {
  Container,
  Form,
  MatchMedia,
  Heading,
  Fragment,
  Link,
  Button,
  Divider,
  SmallButton,
  GridColumn,
  GridRow,
  Spacing,
  PrimaryButton,
  WrappedFileInput,
  OnboardingCard,
  FormStepper,
  Formik,
  WrappedButtonHref,
  WrapperFooter,
  WrapperFooterNoForm,
  FullScreenModalFooter,
  WrappedHeader,
  WrappedParagraph,
  WrapperSelectInput,
  WrappedTextAreaWithLabel,
  WrappedCalendarPickerWithLabel,
  WrapperTextInput,
  WrappedTimelines,
  WrappedPointTable,
  WrappedMatrixTable,
  WrappedToggleList,
  WrapperFooterNoStep,
  UnloadPrompt,
  ScrollToId,
  WrappedTimeSlots,
  WrappedConditionalToggle,
  WrappedTable,
  WrapperFooterSubStep,
  WrapperRadioButtonList,
  WrappedSideBySideTable,
  WrapperSubStepper,
  WrapperCheckboxList,
  WrapperMultiEmailField,
  FormObserver
};
