import styled, { createGlobalStyle, css } from "styled-components";

import { Theme } from "@source-web/themes";

// @ts-ignore
import desktopLogoInverse from "../../../assets/darkTheme/brand/desktop/VFBusinessDesktopDark.svg";
// @ts-ignore
import tabletLogoInverse from "../../../assets/darkTheme/brand/tablet/VFBusinessTabletDark.svg";
// @ts-ignore
import desktopSpeechInverse from "../../../assets/darkTheme/speech/desktop/VFSpeechDesktopDark.svg";
// @ts-ignore
import mobileSpeechInverse from "../../../assets/darkTheme/speech/mobile/VFSpeechMobileDark.svg";
// @ts-ignore
import desktopLogoLight from "../../../assets/lightTheme/brand/desktop/VFBusinessDesktopLight.svg";
// @ts-ignore
import tabletLogoLight from "../../../assets/lightTheme/brand/tablet/VFBusinessTabletLight.svg";
// @ts-ignore
import desktopSpeechLight from "../../../assets/lightTheme/speech/desktop/VFSpeechDesktopLight.svg";
// @ts-ignore
import mobileSpeechLight from "../../../assets/lightTheme/speech/mobile/VFSpeechMobileLight.svg";

// Corrects the font colour within the modals and the footer
export const GlobalOverrides = createGlobalStyle`
${(props: {
  theme: Theme;
  isFormOpen: boolean;
  isDashboard: boolean;
  hasScore: boolean;
}) => `
  html {
    [role="tablist"] {
      padding-left: 12px;
      & span,
      div {
        color: ${props.theme.name === "WS10" ? "black" : "white"};
      }
    }
  }

  #MDigitalInvitationWrapper {
    position: absolute;
    display: ${props.hasScore ? `block` : `none`};;
  }
  
  #kampyleButtonContainer {
    z-index: 3;
    position: absolute;
  }

  body {
    overflow-x: auto;
    overflow-y: ${props?.isFormOpen ? `hidden` : `scroll`};
    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      background-color: ${
        props.theme.name === "WS10"
          ? props.theme.color.monochrome1.default
          : props.theme.inverseColor.monochrome1.default
      };
    }
  }

  footer * {
    color: ${
      props.theme.name === "WS10"
        ? props.theme.color.monochrome4.default
        : props.theme.baseFont.color
    };
  }

  input:-webkit-autofill {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .__floater__body {
    // tooltip body
    & div > div > div {
      // close icon
      & div:nth-child(1) {
        padding-bottom: 0px;
        padding-top: 0px;
      }

      // title and content
      & div:nth-child(2),
      div:nth-child(3) {
        padding-left: ${props.theme.spacing.fixed[2]}px;

        & > p {
          color: ${props.theme.name === "WS10" ? "black" : "white"};
        }
      }

      // footer
      & div:nth-child(4) {
        //next button
        button:last-of-type {
          margin-left: ${props.theme.spacing.fixed[3]}px;
          background: ${props.theme.color.error.default};
        }
        //previous button
        button:first-of-type {
          margin-right: ${props.theme.spacing.fixed[3]}px;
        }
      }
      // content paragraph
      p {
        white-space: break-spaces;
      }
    }
  }
`}`;

export const Body = styled.div`
  overflow: hidden;
  position: relative;
`;

export const VFDashboardBusinessLogo = styled.div`
  ${(props) => css`
    background-repeat: no-repeat;
    @media (width >= ${props.theme.breakpoints.lg}px) {
      height: 100px;
      width: 373px;
      background-image: ${props.theme.name === "WS10"
        ? `url(${desktopLogoLight})`
        : `url(${desktopLogoInverse})`};
    }
    @media (width < ${props.theme.breakpoints.lg}px) {
      display: none;
    }
  `}
`;

export const VFLogoWithNoBrandName = styled.div(
  (props) => `
    background-repeat: no-repeat;
    background-image: ${
      props.theme.name === "WS10"
        ? `url(${desktopSpeechLight})`
        : `url(${desktopSpeechInverse})`
    };
    height: 60px;
    width: 60px;

    @media screen and (min-width: 320px) and (max-width: ${
      props.theme.breakpoints.md
    }px) {
      height: 30px;
      width: 199px;
      background-image: ${
        props.theme.name === "WS10"
          ? `url(${mobileSpeechLight})`
          : `url(${mobileSpeechInverse})`
      };
    }
  `
);

export const VFDashboardToggleLogo = styled.div(
  (props) => `
    background-repeat: no-repeat;
    @media (min-width: ${props.theme.breakpoints.lg}px) {
      display: none;
    }
    @media (min-width: ${props.theme.breakpoints.md}px) and (max-width ${
    props.theme.breakpoints.lg
  }px) {
      height: 76px;
      width: 342px;
      background-image: ${
        props.theme.name === "WS10"
          ? `url(${tabletLogoLight})`
          : `url(${tabletLogoInverse})`
      };
    }

    @media (max-width: ${props.theme.breakpoints.lg}px) {
      height: 24px;
      width: 24px;
      margin-inline: 16px;
      background-image: ${
        props.theme.name === "WS10"
          ? `url(${mobileSpeechLight})`
          : `url(${mobileSpeechInverse})`
      };
    }
    
  `
);

export const NavTabContainer = styled.div`
  ${(props) => css`
    [role="tablist"] {
      padding-left: 0;
    }
    @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
      & > div > div {
        display: none;
      }
    }
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    white-space: nowrap;
    --webkit-overflow-scrolling: touch;
    --ms-overflow-style: -ms-autohiding-scrollbar;
    padding-top: ${props.theme.spacing.fixed[2]}px;
    &::-webkit-scrollbar {
      display: none;
    }
    & > div > div {
      justify-content: flex-start;
      & > div > button > div {
        padding: 16px 24px;
      }
    }
    button[aria-selected="true"] > div {
      border-bottom: ${props.theme.name === "WS10"
        ? `4px solid  ${props.theme.color.primary1.default}`
        : ` 4px solid ${props.theme.color.monochrome6.default}`};
    }

    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      & > div > div > div > button > div {
        padding: 12px 18px;
      }
      margin-right: ${props.theme.spacing.fixed[1]}px;
    }
    @media only screen and (min-width: ${props.theme.breakpoints
        .md}px) and (max-width: ${props.theme.breakpoints.lg}px) {
      & > div > div > div > button > div {
        padding: 12px 18px;
      }
      margin-right: ${props.theme.spacing.fixed[1]}px;
    }
  `}
`;

export const CaptionText = styled.p`
  font-size: 15px;
  margin-top: 20px;
  line-height: 29px;
  display: inline-flex;
`;

export const Container = styled.div(
  (props: any) => `
  background-color: ${
    props.theme.name === "WS10"
      ? props.theme.color.monochrome6.default
      : props.theme.color.monochrome2.default
  };

  border-bottom: 1px solid #999999;
  
  & svg {
    fill: ${props.theme.color.primary1.default};
  }
  & > div {
    display:flex;
    justify-content:flex-end; 
    margin:auto;
    max-width:${props.theme.gridConfig.springWidth}px;
    & > div:first-of-type {
      border:none; 
    }
    & > div:last-of-type {
      margin-right:${props.theme.spacing.fixed[3]}px;
    }
  }
  & > div >  div > ul  {
    margin-left: ${props.theme.spacing.fixed[3]}px;
    & li > a {
      text-decoration:none;
    }
  }  
  
   @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) { 
    display: none;
  }
`
);

export const OptionsContainer = styled.div(
  (props: any) => `
  background-color: ${props.theme.color.monochrome2.default};

  @media only screen and (max-width: ${props.theme.breakpoints.md}px) { 
    display: none;
  }
  & > div {
    gap:${props.theme.spacing.fixed[3]}px;
    display:flex;
    justify-content:flex-end; 
    margin:auto;
    max-width:${props.theme.gridConfig.springWidth}px;
    & > div:first-of-type {
      border:none; 
    }
    & > div:last-of-type {
      margin-right:${props.theme.spacing.fixed[3]}px;
    }
  }
`
);

export const LoginHeader = styled.div(
  (props: any) => `
  background-color: ${props.theme.color.monochrome2.default};
  & > div {
    display: flex;
        visibility: hidden;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
`
);

export const TitleContainer = styled.div`
  ${(props) => css`
    max-width: ${props.theme.gridConfig.springWidth}px;
    padding: 0 ${props.theme.spacing.fixed[3]}px;
    display: flex;
    justify-content: flex-start;
    @media screen and (min-width: 320px) and (max-width: 1024px) {
    }
  `}
`;

export const VFMobileLogo = styled.div`
  ${(props) => css`
    @media (min-width: ${props.theme.breakpoints.md}px) {
      display: none;
    }
    @media (max-width: ${props.theme.breakpoints.md}px) {
      height: 24px;
      width: 24px;
      margin-left: 12px;
    }
    background-repeat: no-repeat;
  `}
`;

export const WidgetOverlay = styled.div`
  ${(props) => css`
    @media (max-width: ${props.theme.breakpoints.md}px) {
      z-index: 1;
      display: flex;
      justify-content: flex-start;
      background-color: ${props.theme.name === "WS10"
        ? props.theme.color.monochrome1.default
        : props.theme.color.monochrome2.default};
    }
    height: 70px;
    margin-left: auto;
    min-height: max-content;
    display: flex;
    align-items: center;
    & > * {
      padding: 0 20px;
    }
  `}
`;

export const NavContainer = styled.div`
  ${(props) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) { 
      max-width: ${props.theme.gridConfig.springWidth}px;
      margin: auto; 
    } 
  `}
`;

export const NavItems = styled.div`
  ${(props) => css`
    display: flex;
    background-color: ${props.theme.name === "WS10"
      ? props.theme.color.monochrome1.default
      : ""};
    @media (min-width: 320px) and (max-width: 1024px) {
      height: 70px;
      display: flex;
      align-items: center;
      background-color: ${props.theme.name === "WS10"
        ? props.theme.color.monochrome1.default
        : props.theme.color.monochrome2.default};
    }
  `}
`;

export const NavigationContainer = styled.div`
  ${(props) => `
  @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
    background-color: ${
      props.theme.name === "WS10"
        ? props.theme.color.monochrome1.default
        : props.theme.color.monochrome2.default
    }; 
    max-height:60px;
    width: 100%;
    display: flex;
    justify-content:space-between;
  }

    @media only screen and (min-width: ${props.theme.breakpoints.md}px) { 
      
      max-width: ${props.theme.gridConfig.springWidth}px;
      display: flex;
      justify-content: space-between;
      margin: auto; 
      min-height: 70px;
    } 
    align-items: center;
    display:flex;  
  `}
`;

export const MobileContainer = styled.div`
  &&& {
    ${(props) => `
    @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
      display:none;
    }
    
    @media only screen and (min-width: ${
      props.theme.breakpoints.sm
    }px) and (max-width:${props.theme.breakpoints.lg}px){ 
      display:block;
    }
    display:none;
  `}
  }
`;

export const DesktopContainer = styled.div`
  ${(props) => `
  @media (width > ${props.theme.breakpoints.lg}px) {
    display:flex;
    gap:${props.theme.spacing.fixed[3]}px;
  }
    display:none;
  `}
`;

export const ToggleContainerDesktop = styled.div`
  ${(props) => css`
    align-self: center;
    display: flex;
    gap: 2.5rem;
    @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
      display: none;
    }
  `}
`;

export const ToggleContainerMobile = styled.div`
  ${(props) => css`
    display: none;
    @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
      & {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
      }
      & > label {
        font-size: 20px;
        font-weight: 200;
        margin: 0 10px;
      }
    }
  `}
`;

export const BreadcrumbContainer = styled.div(
  (props: any) => `
  @media(min-width:${props.theme.breakpoints.sm}px) {
    display:none;
  }
  background-color: ${
    props.theme.name === "WS10"
      ? props.theme.color.monochrome1.default
      : props.theme.color.monochrome2.default
  };
  border-bottom: 1px solid #999999;
  & svg {
    fill: ${props.theme.color.primary1.default};
  }
  & > div {
    display:flex;
    justify-content:flex-end; 
    margin:auto;
    max-width:${props.theme.gridConfig.springWidth}px;
    & > div:first-of-type {
      border:none; 
    }
    & > div:last-of-type {
      margin-right:${props.theme.spacing.fixed[3]}px;
    }
  }
  & > div >  div > ul  {
    margin-left: ${props.theme.spacing.fixed[3]}px;
    & li > a {
      text-decoration:none;
    }
  }  
`
);
