export const eventActions = {
  onClick: "onClick",
  onSubmit: "onSubmit"
};

export const eventCategories = {
  button: "Button",
  notification: "Notification Click",
  link: "Link"
};

export const eventLabels = {
  login: "Login",
  services: "Services Page",
  navigation: "Page Navigation",
  campaign: "Campaigns",
  ssc: "Security Score Card",
  vca: "VCA - Virtual Cyber Advisor",
  viewOnline: "ThreatBulletin - View online",
  download: "ThreatBulletin - Download",
  supportFaqLink: "Support - Please see the FAQs",
  supportRaiseIncidentButton: "Support - Submit Raise Incident",
  messagePopupButton: "Message Popup Buttons"
};

export const journeyTypes = {
  ssc: "Security Score Card",
  login: "Login",
  services: "Services Page",
  campaign: "Campaigns",
  tour: "Tour",
  vca: "VCA - Virtual Cyber Advisor",
  viewOnline: "ThreatBulletin - View online",
  download: "ThreatBulletin - Download",
  supportFaqLink: "Support - Please see the FAQs",
  supportRaiseIncidentButton: "Support - Submit Raise Incident"
};
