import dayjs from "dayjs";

import { AgentSlots } from "../../../lib";
import { snowApi } from "../../../store/apis/snow";
import { store } from "../../../store/store";

// import { handlePostSubmit } from "../../../utilities/utilities";

export const formatToDate = (date: number | string, format = "DD/MM/YYYY") =>
  dayjs(date).format(format);

export const formatTime = (dateString: string) =>
  dayjs(dateString).format("HH:mm a");

export const SubmitForm = () => null;
// val: any
// {
// store.dispatch(snowApi.endpoints.createCampaign.initiate(val));
// handlePostSubmit({ formFlow: val.formFlow }, val);
// };

export const getDates = (val: any) =>
  store.dispatch(snowApi.endpoints.getAvailableAgentSlots.initiate(val));

// Today
export const getMinDates = () => dayjs(Date.now()).toDate();

// a Month in the future
export const getMaxDates = () => dayjs(Date.now()).add(31, "day").toDate();

export const getAgentSlotDates = (slots: AgentSlots): Date[] =>
  Object.values(slots.availability).map(
    (value) => dayjs(value.date).toDate() ?? value.timeSlots.length
  );

export const getOptionsFromSlot = (selectedDate: number, slots: AgentSlots) =>
  Object.values(slots.availability)
    .map(
      (value) =>
        value.timeSlots.length &&
        formatToDate(selectedDate) === formatToDate(value.date) &&
        value.timeSlots.map((entrie) => ({
          text: entrie.time,
          value: entrie.dateTime,
          helpText: value.date,
          disabled: false
        }))
    )
    .filter(Boolean)[0];

export const getLoadingStateFromStatus = (status: string) =>
  status === "pending";

export const not = (val: any) => !val;

export const isEqual = (val: any, equalTo: string) => {
  if (typeof val === "string") {
    return val === equalTo;
  }
  return JSON.stringify(val) === equalTo;
};

export const isEqualOr = (val: any[], equalTo: string) => {
  return val.includes(equalTo);
};

export const isNotEqual = (val: any, equalTo: string) =>
  JSON.stringify(val) !== equalTo;

export const isSuccessful = (submitted: boolean, submissionStatus: string) =>
  submitted && submissionStatus === "fulfilled";

export const isSubmitted = (submitted: boolean) => submitted;

export const isErrored = (submitted: boolean, submissionStatus: string) =>
  submitted && submissionStatus === "fulfilled";

export const initInitialValues = (initialValues: any) =>
  initialValues === undefined ? new Object() : initialValues;
