import dayjs from "dayjs";

import { AgentSlots, productType, subscriptionListingList } from "../services";

export const subscriptionListings: () => subscriptionListingList = () => {
  const todaysDate = dayjs();
  const fourYearsAhead = todaysDate.add(4, "year").unix();

  return [
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "34534",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "CANCELLED",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: todaysDate.add(10, "days").unix(),
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "123",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "SUSPENDED",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "456",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "ACTIVE",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "789",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "ACTIVE",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "101112",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "CANCELLED",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "131415",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "ACTIVE",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "161718",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "ACTIVE",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "192021",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "ACTIVE",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "222324",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "ACTIVE",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "1234",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "ACTIVE",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    },
    {
      assignmentCount: 1,
      company: {
        href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
        id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
      },
      creationDate: 1481002406580,
      edition: {
        href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
        id: "2"
      },
      endDate: fourYearsAhead,
      externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
      id: "187",
      links: [],
      maxUsers: 1,
      order: {
        href: "http://appdirect/api/billing/v1/orders/1",
        id: "1"
      },
      parentSubscriptionId: null,
      product: {
        href: "http://appdirect/api/marketplace/v1/products/2",
        id: "2"
      },
      status: "ACTIVE",
      suspensionReason: "API",
      user: {
        href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
        id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
      }
    }
  ];
};

export const DemoSubscriptionListing: subscriptionListingList = [
  {
    assignmentCount: 1,
    company: {
      href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
      id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
    },
    creationDate: 1481002406580,
    edition: {
      href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
      id: "2"
    },
    endDate: null,
    externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
    id: "161718",
    links: [],
    maxUsers: 1,
    order: {
      href: "http://appdirect/api/billing/v1/orders/1",
      id: "1"
    },
    parentSubscriptionId: null,
    product: {
      href: "http://appdirect/api/marketplace/v1/products/2",
      id: "2"
    },
    status: "ACTIVE",
    suspensionReason: "API",
    user: {
      href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
      id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
    }
  },
  {
    assignmentCount: 1,
    company: {
      href: "http://appdirect/api/account/v1/companies/e83cd3d6-7865-4fc2-9d7c-603ad17dd639",
      id: "e83cd3d6-7865-4fc2-9d7c-603ad17dd639"
    },
    creationDate: 1481002406580,
    edition: {
      href: "http://appdirect/api/marketplace/v1/products/2/editions/2",
      id: "2"
    },
    endDate: null,
    externalAccountId: "d2f4acf1-fb5a-4c4a-921f-75c4b6bfff08",
    id: "789",
    links: [],
    maxUsers: 1,
    order: {
      href: "http://appdirect/api/billing/v1/orders/1",
      id: "1"
    },
    parentSubscriptionId: null,
    product: {
      href: "http://appdirect/api/marketplace/v1/products/2",
      id: "2"
    },
    status: "ACTIVE",
    suspensionReason: "API",
    user: {
      href: "http://appdirect/api/account/v1/users/c47d0fd7-b67a-4e07-84f8-f93dfafbeecc",
      id: "c47d0fd7-b67a-4e07-84f8-f93dfafbeecc"
    }
  }
];

export const product: productType = {
  addonOfferings: [],
  autoUpgradeToPaid: false,
  bundledPlanIds: null,
  buyable: false,
  changeSubscriptionAllowed: true,
  collectLeads: false,
  collectLeadsWithPurchaseEnabled: false,
  customIntegration: null,
  displayQuestions: true,
  displayReviews: true,
  downloadFileSize: null,
  featuredCustomers: [],
  featuredMedia: [],
  features: [],
  free: false,
  freeTrialOrEditionPresent: true,
  hidePricings: false,
  highestFixedDiscount: {},
  highestPercentageDiscount: null,
  hostedLocation: null,
  href: "http://localhost/api/marketplace/v1/products/348",
  id: 348,
  lastModified: 1461792768086,
  linkedImportableApplicationUuid: null,
  listing: {
    blurb: "App Blurb",
    imageUrl: null,
    myAppLogoIconUrl: null,
    overview: null,
    profileImageUrl: null,
    rating: null,
    reviewCount: 0
  },
  liveChatAvailable: false,
  liveChatEnabled: false,
  name: "MockApp-4367741647933991053",
  newSubscriptionEnabled: true,
  numRatings: null,
  oneClickPurchasable: false,
  overview: {
    benefit: [],
    demoUrl: null,
    documentationUrl: null,
    downloadDocumentationUrl: null,
    downloadFileSize: null,
    imageUrl: null,
    splashDescription: "App Description",
    splashTitle: null,
    systemRequirements: null,
    versions: {}
  },
  pricing: {
    bullet: [],
    edition: [
      {
        bullet: [],
        bundleOnly: false,
        code: "OS5WH",
        customerContractRestricted: false,
        customization: [],
        defaultFreeTrial: false,
        expiredTrialGracePeriod: null,
        freeTrialDuration: 1,
        freeTrialType: "MONTH",
        invisible: false,
        item: [],
        lastModified: 1461792767969,
        leadGen: false,
        name: "jEGpImFXPG",
        plan: [
          {
            allowCustomUsage: false,
            contract: {
              autoExtensionPricingId: 688,
              blockContractDowngrades: false,
              blockContractUpgrades: false,
              blockSwitchToShorterContract: false,
              cancellationPeriodLimit: null,
              endOfContractGracePeriod: null,
              gracePeriod: null,
              minimumServiceLength: 3,
              terminationFee: null
            },
            cost: [
              {
                amount: {
                  USD: 10
                },
                blockContractDecrease: false,
                blockContractIncrease: false,
                blockOriginalContractDecrease: false,
                increment: 1,
                maxUnits: null,
                meteredUsage: false,
                minUnits: 0,
                pricePerIncrement: false,
                unit: "USER",
                unitDependency: null
              }
            ],
            discount: null,
            frequency: "MONTHLY",
            href: "http://localhost/api/marketplace/v1/products/348/editions/348/paymentPlans/688",
            id: 688,
            isPrimaryPrice: false,
            keepBillDateOnUsageChange: false,
            separatePrepaid: false,
            uuid: null
          },
          {
            allowCustomUsage: false,
            contract: {
              autoExtensionPricingId: 689,
              blockContractDowngrades: false,
              blockContractUpgrades: false,
              blockSwitchToShorterContract: false,
              cancellationPeriodLimit: null,
              endOfContractGracePeriod: null,
              gracePeriod: null,
              minimumServiceLength: 6,
              terminationFee: null
            },
            cost: [
              {
                amount: {
                  USD: 10
                },
                blockContractDecrease: false,
                blockContractIncrease: false,
                blockOriginalContractDecrease: false,
                increment: 1,
                maxUnits: null,
                meteredUsage: false,
                minUnits: 0,
                pricePerIncrement: false,
                unit: "USER",
                unitDependency: null
              }
            ],
            discount: null,
            frequency: "MONTHLY",
            href: "http://localhost/api/marketplace/v1/products/348/editions/348/paymentPlans/689",
            id: 689,
            isPrimaryPrice: false,
            keepBillDateOnUsageChange: false,
            separatePrepaid: false,
            uuid: null
          }
        ],
        primary: false,
        rank: 1,
        restricted: false,
        revenueType: "RECURRING",
        targetAudience: null,
        trial: {
          length: 1,
          unit: "MONTH"
        },
        uuid: "e01e484b-4894-4136-8afc-8d1d50d8c04d"
      }
    ],
    footnote: [],
    pricingSummary: null
  },
  privacyUrl: null,
  provider: {
    name: "Appdirect",
    url: "appdirect.com",
    uuid: "4a74d22c-8391-4c8c-8fc5-807a193a613e"
  },
  publishedApp: null,
  publishedOn: 1461792767968,
  rating: null,
  referUrl: null,
  referable: false,
  resources: [],
  screenshots: [],
  startingPrice: {
    amount: {
      USD: 0
    },
    billingFrequency: "MONTHLY",
    discount: null,
    unit: "USER"
  },
  support: {
    description: null,
    email: null,
    knowledgebaseUrl: null,
    phone: null
  },
  supportedLanguages: ["en"],
  tags: [],
  termsUrl: null,
  type: "WEB_APP",
  usageType: "MULTI_USER",
  uuid: "c3775161-9ca4-4b5d-bf9f-3f8785063962",
  workingApp: {
    href: "http://localhost/api/marketplace/v1/products/347",
    id: "347"
  }
};

export const companyInformation = {
  name: "Vodafone",
  email: "test.cyberhub@vodafone.com",
  companyName: "Vodafone",
  location: "United Kingdom",
  industry: "IT Services",
  companySize: "1001-5000 employees"
};

export const productList = [
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "Our Cyber Exposure Diagnostic service is part of our simple, pre-defined packages designed specifically for your needs, leveraging our partner Accenture's industry-leading capabilities in cyber security.",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "Our Cyber Exposure Diagnostic service is part of our simple, pre-defined packages designed specifically for your needs, leveraging our partner Accenture's industry-leading capabilities in cyber security.",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/456",
    iconUrl: null,
    id: 456,
    lastModified: 1461792766422,
    name: "Cyber Exposure Diagnostic",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$10.00/User/Mo",
    tag: [],
    url: "http://localhost/apps/456",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  },
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "With our Penetration Testing service, get access to highly skilled resources to understand how vulnerabilities in the ecosystem can be exploited by a cyber attack, providing the warning needed to take action.",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "With our Penetration Testing service, get access to highly skilled resources to understand how vulnerabilities in the ecosystem can be exploited by a cyber attack, providing the warning needed to take action.",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/161718",
    iconUrl: null,
    id: 161718,
    lastModified: 1461792766422,
    name: "Penetration Testing",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$15.00/User/Mo",
    tag: [],
    url: "http://localhost/apps/161718",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  },
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "Our vulnerability assessment service leverages Accenture's industry-leading threat intelligence capabilities to help you gain a better understanding of any weaknesses in your current business ecosystem.",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "Our vulnerability assessment service leverages Accenture's industry-leading threat intelligence capabilities to help you gain a better understanding of any weaknesses in your current business ecosystem.",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/192021",
    iconUrl: null,
    id: 192021,
    lastModified: 1461792766422,
    name: "Vulnerability Assessment",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$6.00/User/Mo",
    tag: [],
    url: "http://localhost/apps/192021",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  },
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "The Phishing Awareness service combines Accenture's experience in safeguarding their own organisations with easy-to-deploy state-of-the-art campaigns and detailed reporting with a best-in-class solution.",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "The Phishing Awareness service combines Accenture's experience in safeguarding their own organisations with easy-to-deploy state-of-the-art campaigns and detailed reporting with a best-in-class solution.",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/789",
    iconUrl: null,
    id: 789,
    lastModified: 1461792766422,
    name: "Phishing Awareness",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$5.00/User/Mo",
    tag: [],
    url: "http://localhost/apps/789",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  },
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "Combining Vodafone's experience and global footprint and Accenture's industry-leading technologies and cyber security experts, this service gives the next generation capabilities far beyond those of conventional firewalls",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "Combining Vodafone's experience and global footprint and Accenture's industry-leading technologies and cyber security experts, this service gives the next generation capabilities far beyond those of conventional firewalls",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/131415",
    iconUrl: null,
    id: 131415,
    lastModified: 1461792766422,
    name: "Firewall Management",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$10.00/User/Mo",
    tag: [],
    url: "http://localhost/apps/131415",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  },
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "Our MDR service uses a combination of skilled analysts and proprietary technology in conjunction with Accenture's global threat intelligence capability to deliver a SOC solution with better detection",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "Our MDR service uses a combination of skilled analysts and proprietary technology in conjunction with Accenture's global threat intelligence capability to deliver a SOC solution with better detection",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/123",
    iconUrl: null,
    id: 123,
    lastModified: 1461792766422,
    name: "Managed Detection & Response",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$20.00/User/Mo",
    tag: [],
    url: "http://localhost/apps/123",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  },
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "Together with Accenture, we help organisations around the globe manage, respond to, and recover from the most elusive and complex attacks, as well as countering persistent cyber security threats.",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "Together with Accenture, we help organisations around the globe manage, respond to, and recover from the most elusive and complex attacks, as well as countering persistent cyber security threats.",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/101112",
    iconUrl: null,
    id: 101112,
    lastModified: 1461792766422,
    name: "Breach Response & Forensics",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$30.00/User/Mo",
    tag: [],
    url: "http://localhost/apps/101112",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  },
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "Trend Micro™ Worry-Free Business Security Services™ for small offices protects multiple Windows computers, Macs, and Android devices located in or out of the office from viruses and other threats from the web",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "Trend Micro™ Worry-Free Business Security Services™ for small offices protects multiple Windows computers, Macs, and Android devices located in or out of the office from viruses and other threats from the web",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/222324",
    iconUrl: null,
    id: 222324,
    lastModified: 1461792766422,
    name: "Trend Micro™ Worry-Free™",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$50.00/User/Mo",
    tag: [],
    url: "http://localhost/apps/222324",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  },
  {
    addon: false,
    billingFrequency: "MONTHLY",
    blurb:
      "Leveraging Vodafone's global scale and specialist expertise in mobility services and Lookout's unrivalled security services to provide a best-in-class solution to secure mobiles from any kind of cyber threat",
    bundleUrl: [],
    buyable: false,
    collectLeads: false,
    description:
      "Leveraging Vodafone's global scale and specialist expertise in mobility services and Lookout's unrivalled security services to provide a best-in-class solution to secure mobiles from any kind of cyber threat",
    developerName: "Appdirect",
    discountDetails: null,
    downloadFileSize: null,
    featured: false,
    featuredSliderPosition: null,
    free: false,
    freeTrialOrEditionPresent: true,
    hasLyncToPhone: false,
    hidePricings: false,
    href: "http://localhost/api/marketplace/v1/products/34534",
    iconUrl: null,
    id: 34534,
    lastModified: 1461792766422,
    name: "Lookout Mobile Security",
    numRatings: null,
    overview: null,
    overviewImageUrl: null,
    popular: false,
    popularity: null,
    productType: "WEB_APP",
    profileLogoUrl: null,
    publishedOn: 1461792766320,
    rating: null,
    referable: false,
    sortRank: 5,
    staffPick: false,
    staffPickSliderPosition: null,
    startingPrice: "$9.99/User/Mo",
    tag: [],
    url: "http://localhost/apps/34534",
    uuid: "9b96574b-1686-44c4-a84d-f79f28d2b79f",
    vendorName: "Appdirect"
  }
];

export const agentSlots: () => AgentSlots = () => {
  const todaysDate = dayjs().set("minute", 0);
  const tomorrowsDate = todaysDate.add(1, "day");
  const overMorrowsDate = todaysDate.add(2, "day");

  return {
    availability: {
      [todaysDate.format("YYYY-MM-DD")]: {
        allNoTimesReasons: "No Available Times",
        timeSlots: [],
        date: todaysDate.format("YYYY-MM-DD")
      },
      [tomorrowsDate.format("YYYY-MM-DD")]: {
        allNoTimesReasons: "",
        timeSlots: [
          {
            time: tomorrowsDate.set("hour", 13).format("hh:mma"),
            dateTime: tomorrowsDate.set("hour", 13).format("YYYY-MM-DDTHH:mm")
          },
          {
            time: tomorrowsDate.set("hour", 14).format("hh:mma"),
            dateTime: tomorrowsDate.set("hour", 14).format("YYYY-MM-DDTHH:mm")
          },
          {
            time: tomorrowsDate.set("hour", 15).format("hh:mma"),
            dateTime: tomorrowsDate.set("hour", 15).format("YYYY-MM-DDTHH:mm")
          }
        ],
        date: tomorrowsDate.format("YYYY-MM-DD")
      },
      [overMorrowsDate.format("YYYY-MM-DD")]: {
        allNoTimesReasons: "",
        timeSlots: [
          {
            time: overMorrowsDate.set("hour", 10).format("hh:mma"),
            dateTime: overMorrowsDate.set("hour", 10).format("YYYY-MM-DDTHH:mm")
          },
          {
            time: overMorrowsDate.set("hour", 11).format("hh:mma"),
            dateTime: overMorrowsDate.set("hour", 11).format("YYYY-MM-DDTHH:mm")
          },
          {
            time: overMorrowsDate.set("hour", 15).format("hh:mma"),
            dateTime: overMorrowsDate.set("hour", 15).format("YYYY-MM-DDTHH:mm")
          }
        ],
        date: overMorrowsDate.format("YYYY-MM-DD")
      }
    }
  };
};

export const serviceStatusesByUserId: Record<any, any> = {
  // 2: [],
  // 3: [],
  // 10: [
  //   {
  //     id: "1001",
  //     uuid: "1",
  //     status: "Order In Progress",
  //     dateReceived: "2022-02-12T16:31:23Z",
  //     serviceId: 1, // CED
  //     hasRead: false
  //   },
  //   {
  //     id: "2001",
  //     uuid: "1",
  //     status: "Complete Onboarding",
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     serviceId: 7, // MDR
  //     hasRead: false
  //   },
  //   {
  //     id: "3001",
  //     uuid: "1",
  //     status: "Complete Onboarding",
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     serviceId: 2, // MFW
  //     hasRead: false
  //   },
  //   {
  //     id: "4001",
  //     uuid: "1",
  //     status: "Onboarding In Progress", // Check this with thiago/andre
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     onboardingCall: "2022-02-11T16:31:23Z",
  //     serviceId: 5, // PA
  //     hasRead: false
  //   },
  //   {
  //     id: "5001",
  //     uuid: "1",
  //     status: "Report Complete",
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     serviceId: 4, // VA
  //     hasRead: false
  //   }
  // ],
  // 11: [
  //   {
  //     id: "2001",
  //     uuid: "1",
  //     status: "Complete Onboarding",
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     serviceId: 7, // MDR
  //     hasRead: false
  //   },
  //   {
  //     id: "3001",
  //     uuid: "1",
  //     status: "Complete Onboarding",
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     serviceId: 2, // MFW
  //     hasRead: false
  //   }
  // ],
  // 12: [
  //   {
  //     id: "4001",
  //     uuid: "1",
  //     status: "Onboarding In Progress", // Check this with thiago/andre
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     onboardingCall: "2022-02-11T16:31:23Z",
  //     serviceId: 5, // PA
  //     hasRead: false
  //   },
  //   {
  //     id: "5001",
  //     uuid: "1",
  //     status: "Report Complete",
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     serviceId: 4, // VA
  //     hasRead: false
  //   },
  //   {
  //     id: "5001",
  //     uuid: "7",
  //     status: "Report Complete",
  //     dateReceived: "2022-02-11T16:31:23Z",
  //     serviceId: 7, // VA
  //     hasRead: false
  //   }
  // ],
  14: [
    {
      id: "4001",
      uuid: "1",
      status: "Onboarding In Progress", // Check this with thiago/andre
      dateReceived: "2022-02-11T16:31:23Z",
      onboardingCall: "2022-02-11T16:31:23Z",
      serviceId: 5, // PA
      hasRead: false
    },
    {
      id: "5001",
      uuid: "1",
      status: "Report Complete",
      dateReceived: "2022-02-11T16:31:23Z",
      serviceId: 4, // VA
      hasRead: false
    },
    {
      id: "6001",
      uuid: "7",
      status: "Report Complete",
      dateReceived: "2022-02-11T16:31:23Z",
      serviceId: 7, // VA
      hasRead: false
    }
  ]
};

export const getBookedAppointments: Record<any, any> = {
  14: {
    appointments: [
      {
        uuid: "123",
        appointment: {
          timeSlot: "2022-06-12T16:31:23Z",
          serviceId: 2,
          description: "Complete Onboarding",
          endDate: "2022-06-12T16:31:23Z",
          totalHours: 20,
          status: "completed"
        }
      },
      {
        uuid: "456",
        appointment: {
          timeSlot: "2022-06-13T16:31:23Z",
          serviceId: 3,
          description: "Full Report Walkthrough",
          endDate: "2022-06-12T16:31:23Z",
          totalHours: 25,
          status: "available"
        }
      }
    ]
  }
};

export const getBookedAppointmentsOld = (): any => {
  return {
    appointments: [
      {
        uuid: "123",
        appointment: {
          timeSlot: "2021-11-20T16:31:23Z",
          serviceId: 2,
          description: "Complete Onboarding",
          endDate: "2022-02-20T16:31:23Z",
          totalHours: 20,
          status: "completed"
        }
      },
      {
        uuid: "456",
        appointment: {
          timeSlot: "2022-02-12T16:31:23Z",
          serviceId: 3,
          description: "Full Report Walkthrough",
          endDate: "2022-02-08T16:31:23Z",
          totalHours: 25,
          status: "available"
        }
      },
      {
        uuid: "1415",
        appointment: {
          timeSlot: "2022-02-12T16:31:23Z",
          serviceId: 3,
          description: "Full Report Walkthrough",
          endDate: "2022-02-18T16:31:23Z",
          totalHours: 20,
          status: "available"
        }
      },
      {
        uuid: "789",
        appointment: {
          timeSlot: "2022-02-11T16:31:23Z",
          serviceId: 1,
          description: "Full Report Walkthrough",
          endDate: "2022-02-13T16:31:23Z",
          totalHours: 20,
          status: "available"
        }
      },
      {
        uuid: "1011",
        appointment: {
          timeSlot: "2022-02-11T16:31:23Z",
          serviceId: 1,
          description: "Full Report Walkthrough",
          endDate: "2022-02-25T16:31:23Z",
          totalHours: 20,
          status: "completed"
        }
      },
      {
        uuid: "1213",
        appointment: {
          timeSlot: "2022-02-11T16:31:23Z",
          serviceId: 1,
          description: "Complete Onboarding",
          endDate: "2022-02-13T16:31:23Z",
          totalHours: 20,
          status: "completed"
        }
      }
    ]
  };
};

export const subscriptionListById: Record<string, subscriptionListingList> = {
  "1-FF19LKV": subscriptionListings(),
  "14": subscriptionListings(),
  "19": subscriptionListings(),
  "15": [],
  "16": subscriptionListings().filter((product) => product.id === "192021"),
  "17": subscriptionListings().filter((product) => product.id === "192021"),
  "18": subscriptionListings().filter((product) => product.id === "789"),
  "33": subscriptionListings(),
  "12": subscriptionListings(),
  "32": subscriptionListings(),
  "34": subscriptionListings(),
  "36": subscriptionListings()
};

export const sscScore = {
  score: 100,
  grade: "A"
};

export const requestStatusData = [
  {
    id: "102020",
    date: "2021/02/28",
    status: "Password Change",
    pillText: "Request Completed"
  },
  {
    id: "102019",
    date: "2021/01/21",
    status: "Add new application in existing rules",
    pillText: "Request in Progress"
  }
];
export const assessmentCompany = {
  industry: "telecommunications",
  partyRole: { name: "Vodafone", role: "company" },
  riskAssessmentResult: {
    overallScore: 53,
    score: [
      { riskName: "ApplicationSecurity", score: "58.0" },
      { riskName: "ThreatScore", score: "90.0" },
      { riskName: "DnsHealth", score: "65.0" },
      { riskName: "EndPointSecurity", score: "43.0" },
      { riskName: "HackerChatter", score: "100.0" },
      { riskName: "IpReputation", score: "50.0" },
      { riskName: "LeakedInformation", score: "100.0" },
      { riskName: "NetworkSecurity", score: "51.0" },
      { riskName: "PatchingCadence", score: "31.0" },
      { riskName: "SocialEngineering", score: "100.0" }
    ]
  }
};

export const assessmentIndustry = {
  industry: "",
  partyRole: { name: "telecommunications", role: "industry" },
  riskAssessmentResult: {
    overallScore: 75,
    score: [
      { riskName: "ApplicationSecurity", score: "66.0" },
      { riskName: "ThreatScore", score: "97.0" },
      { riskName: "DnsHealth", score: "82.0" },
      { riskName: "EndPointSecurity", score: "80.0" },
      { riskName: "HackerChatter", score: "99.0" },
      { riskName: "IpReputation", score: "85.0" },
      { riskName: "LeakedInformation", score: "100.0" },
      { riskName: "NetworkSecurity", score: "71.0" },
      { riskName: "PatchingCadence", score: "70.0" },
      { riskName: "SocialEngineering", score: "100.0" }
    ]
  }
};

export const mockedLookoutNotification = {
  id: "6001",
  uuid: "1",
  status: "Lookout Notification Status",
  dateReceived: "2022-02-11T16:31:23Z",
  serviceId: 8,
  hasRead: false
};

export const lookoutResponse = {
  bucket: [
    {
      bucketCounter: [
        {
          valueName: "licensesPurchased",
          value: {
            amount: 98
          }
        },
        {
          valueName: "licensesUsed",
          value: {
            amount: 55
          }
        },
        {
          valueName: "adminLogins",
          value: {
            amount: 44
          }
        },
        {
          valueName: "lastAdminLogin",
          consumptionPeriod: {
            startDateTime: "2022-05-25T12:16:30.910Z"
          }
        },
        {
          counterType: "high",
          valueName: "openIssues",
          value: {
            amount: 233
          }
        },
        {
          counterType: "medium",
          valueName: "openIssues",
          value: {
            amount: 33
          }
        },
        {
          counterType: "low",
          valueName: "openIssues",
          value: {
            amount: 44
          }
        },
        {
          counterType: "high",
          valueName: "resolvedIssues",
          value: {
            amount: 2222
          }
        },
        {
          counterType: "medium",
          valueName: "resolvedIssues",
          value: {
            amount: 42
          }
        },
        {
          counterType: "low",
          valueName: "resolvedIssues",
          value: {
            amount: 42232
          }
        },
        {
          valueName: "maliciousSitesBlocked",
          value: {
            amount: 56
          }
        },
        {
          valueName: "phishingSitesBlocked",
          value: {
            amount: 57
          }
        },
        {
          valueName: "enrollmentCode",
          User: {
            href: "https://app.lookout.com/code/CTKBJYA"
          }
        }
      ],
      relatedParty: {
        id: "1-232adfadfasd",
        role: "external_partner_id"
      }
    }
  ]
};

export const cybsafeMock = {
  phishing: {
    emailsSent: 70,
    lowRisk: 55,
    mediumRisk: 36,
    highRisk: 25,
    noActions: 10,
    reported: 70
  },
  awarenessOverview: {
    awarenessOverview: {
      totalLicences: 10,
      usersAccredited: 0,
      usersCompleted: 0,
      usersTotal: 5
    },
    companyConfidence: null,
    initialAssessment: {
      averageScore: null,
      delta: null,
      percentages: [],
      usersTotal: 0
    }
  },
  awareness: {
    refresherRates: { passed: 10, failed: 0, ignored: 13, total: 20 },
    confidence: {
      initialAssessment: { value: null },
      startedCourse: { value: null },
      completedCourse: { value: null },
      latestRefresher: { value: null }
    }
  },
  culture: {
    trust: {
      dimensionName: "Trust",
      dimensionSlug: "trust",
      responses: { favorable: "2", neutral: "3", unfavorable: "5", total: "10" }
    },
    "just-and-fair": {
      dimensionName: "Just & Fair",
      dimensionSlug: "just-and-fair",
      responses: {
        favorable: "10",
        neutral: "5",
        unfavorable: "3",
        total: "18"
      }
    },
    responsibility: {
      dimensionName: "Responsibility",
      dimensionSlug: "responsibility",
      responses: { favorable: "2", neutral: "3", unfavorable: "5", total: "10" }
    },
    "resources-and-communication": {
      dimensionName: "Resources & Communication",
      dimensionSlug: "resources-and-communication",
      responses: { favorable: "8", neutral: "3", unfavorable: "3", total: "10" }
    },
    "productive-security": {
      dimensionName: "Productive Security",
      dimensionSlug: "productive-security",
      responses: { favorable: "6", neutral: "7", unfavorable: "6", total: "10" }
    },
    "management-and-leadership": {
      dimensionName: "Management & Leadership",
      dimensionSlug: "management-and-leadership",
      responses: { favorable: "4", neutral: "3", unfavorable: "7", total: "10" }
    },
    "ease-and-choice": {
      dimensionName: "Ease & Choice",
      dimensionSlug: "ease-and-choice",
      responses: { favorable: "4", neutral: "4", unfavorable: "5", total: "10" }
    },
    community: {
      dimensionName: "Community",
      dimensionSlug: "community",
      responses: { favorable: "5", neutral: "5", unfavorable: "5", total: "10" }
    }
  },
  behaviorScore: { name: "Overall", score: 86, coverage: null }
};

export const mockedCart = {
  id: "1234"
};

export const mockedTrendMicro = () => {
  const todaysDate = dayjs().set("minute", 0);

  const newDate = (daysToPass: number) => todaysDate.add(daysToPass, "day");
  const randomValue = () => Math.floor(Math.random() * 11);
  const daysToTest = [...Array(2).keys()];

  return {
    securityRisksOverTime: daysToTest.map((day) => ({
      date: newDate(day),
      knownThreats: randomValue(),
      unknownThreats: randomValue(),
      policyViolations: randomValue()
    })),
    vulnerabilities: daysToTest.map((day) => ({
      date: newDate(day),
      virus: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      spyware: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      webReputation: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      networkVirus: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      behaviourMonitoring: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      predictiveMachineLearning: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      urlFiltering: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      deviceControl: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      dataLossPrevention: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      },
      applicationControl: {
        detections: randomValue(),
        affectedEndpoints: randomValue()
      }
    })),
    detectionsByInfectionChannel: daysToTest.map((day) => ({
      date: newDate(day),
      web: randomValue(),
      cloudSynchronization: randomValue(),
      email: randomValue(),
      removableStorage: randomValue(),
      localOrNetworkDrive: randomValue()
    })),
    securityAgentStatus: daysToTest.map((day) => ({
      date: newDate(day),
      desktop: { outdated: randomValue(), offline: randomValue() },
      mobileDevices: { outdated: randomValue(), warning: randomValue() }
    })),
    numberOfDevices: 1,
    maximumDevices: 7
  };
};

export const subscriptionCybSafe = {
  id: "a2df407c-8f75-440e-90d2-9da272221d16",
  startDate: "2023-12-12T11:22:26Z",
  billingAccount: {
    id: "a2df407c-8f75-440e-90d2-9da272221d16"
  },
  product: [
    {
      id: "115620",
      href: "https://marketplace.vodafone.co.uk/api/marketplace/v1/products/115620",
      name: "product"
    },
    {
      id: "310458",
      href: "https://marketplace.vodafone.co.uk/api/marketplace/v1/products/115620/editions/310458",
      name: "edition"
    }
  ],
  productCharacteristic: [
    {
      name: "maxUsers",
      value: "1"
    }
  ],
  productTerm: [
    {
      name: "activationPeriod",
      validFor: {
        endDateTime: "2024-02-01T00:00:00Z",
        startDateTime: "2024-01-12T00:00:00Z"
      }
    },
    {
      name: "contractDate",
      validFor: {
        endDateTime: "2024-02-12T00:00:00Z"
      }
    },
    {
      name: "minimumServiceLength",
      duration: {
        amount: 1.0
      }
    }
  ],
  relatedParty: [
    {
      href: "https://marketplace.vodafone.co.uk/api/account/v1/users/null",
      role: "user"
    },
    {
      id: "ADP100000423274",
      href: "https://marketplace.vodafone.co.uk/api/account/v1/companies/ADP100000423274",
      role: "company"
    }
  ],
  status: "ACTIVE",
  relatedProductOrder: [
    {
      id: "7156827",
      category: "END_OF_CONTRACT_MIGRATION",
      orderTotalPrice: [
        {
          name: "totalPrice",
          price: {
            taxIncludedAmount: {
              unit: "GBP",
              value: 2.1
            }
          }
        }
      ],
      payment: [
        {
          id: "380883",
          name: "paymentPlanId"
        }
      ],
      productOrderItem: [
        {
          id: "16191049",
          quantity: 1,
          itemPrice: [
            {
              price: {
                taxIncludedAmount: {
                  value: 2.1
                }
              }
            }
          ],
          itemTotalPrice: [
            {
              price: {
                taxIncludedAmount: {
                  value: 2.1
                }
              }
            }
          ],
          product: {
            description:
              "Vodafone CybSafe - Vodafone CybSafe (Monthly Rolling) - Per User Fee",
            productCharacteristic: [
              {
                name: "orderLineUnit",
                value: "USER"
              },
              {
                name: "productOrderLineItemType",
                value: "ITEM"
              },
              {
                name: "frequency",
                value: "MONTHLY"
              }
            ]
          }
        }
      ],
      state: "ACTIVE"
    }
  ]
};

export const subscriptionLookout = {
  id: "9695b83b-655e-4236-a8fa-2b496ee53bc5",
  status: "ACTIVE",
  orderDate: null,
  startDate: "2023-08-08T12:44:31Z",
  terminationDate: "2023-09-08T00:14:50Z",
  product: [
    {
      id: "269101",
      href: "https://marketplace.vodafone.co.uk/api/marketplace/v1/products/55052",
      name: "product",
      description: null,
      status: null,
      isBundle: null,
      orderDate: null,
      startDate: null,
      geTerminationDate: null,
      productOrderItem: null,
      productPrice: null
    },
    {
      id: "130337",
      href: "https://marketplace.vodafone.co.uk/api/marketplace/v1/products/55052/editions/130337",
      name: "edition",
      description: null,
      status: null,
      isBundle: null,
      orderDate: null,
      startDate: null,
      geTerminationDate: null,
      productOrderItem: null,
      productPrice: null
    }
  ],
  relatedProductOrder: [
    {
      id: "6875370",
      href: null,
      externalId: null,
      cancellationDate: null,
      cancellationReason: null,
      completionDate: null,
      description: null,
      orderDate: null,
      orderTotalPrice: [
        {
          description: null,
          name: "totalPrice",
          recurringChargePeriod: null,
          unitOfMeasure: null,
          price: {
            percentage: null,
            taxRate: null,
            dutyFreeAmount: null,
            taxIncludedAmount: { unit: "GBP", value: "2.5" }
          }
        }
      ],
      productOrderItem: [
        {
          id: "15586750",
          quantity: 1,
          action: null,
          itemPrice: [
            {
              description: null,
              name: null,
              recurringChargePeriod: null,
              unitOfMeasure: null,
              price: {
                percentage: null,
                taxRate: null,
                dutyFreeAmount: null,
                taxIncludedAmount: { unit: null, value: "2.5" }
              }
            }
          ],
          itemTotalPrice: [
            {
              description: null,
              name: null,
              recurringChargePeriod: null,
              unitOfMeasure: null,
              price: {
                percentage: null,
                taxRate: null,
                dutyFreeAmount: null,
                taxIncludedAmount: { unit: null, value: "2.5" }
              }
            }
          ],
          product: {
            id: null,
            href: null,
            name: null,
            description:
              "Lookout Mobile Security - Lookout for Small Business - Per Device - Per Month - Device License fee",
            status: null,
            isBundle: null,
            orderDate: null,
            startDate: null,
            geTerminationDate: null,
            productOrderItem: null,
            productPrice: null
          },
          state: null
        }
      ]
    }
  ]
};

export const subscriptionTrendMicro = {
  id: "9ba2f4ce-a094-4c3d-97f0-59208341bc67",
  status: "ACTIVE",
  orderDate: null,
  startDate: "2023-10-17T12:34:50Z",
  terminationDate: "2023-09-15T14:58:10Z",
  productTerm: [
    {
      name: "activationPeriod",
      validFor: {
        endDateTime: "2024-02-01T00:00:00Z",
        startDateTime: "2024-01-12T00:00:00Z"
      }
    },
    {
      name: "contractDate",
      validFor: {
        endDateTime: "2025-02-12T00:00:00Z"
      }
    },
    {
      name: "minimumServiceLength",
      duration: {
        amount: 1.0
      }
    }
  ],
  product: [
    {
      id: "276157",
      href: "https://testvfuk.appdirect.com/api/marketplace/v1/products/276157",
      name: "product",
      description: null,
      status: null,
      isBundle: null,
      orderDate: null,
      startDate: null,
      geTerminationDate: null,
      productOrderItem: null,
      productPrice: null
    },
    {
      id: "671427",
      href: "https://testvfuk.appdirect.com/api/marketplace/v1/products/276157/editions/671427",
      name: "edition",
      description: null,
      status: null,
      isBundle: null,
      orderDate: null,
      startDate: null,
      geTerminationDate: null,
      productOrderItem: null,
      productPrice: null
    }
  ],
  relatedProductOrder: [
    {
      id: "7329056",
      href: null,
      externalId: null,
      cancellationDate: null,
      cancellationReason: null,
      completionDate: null,
      description: null,
      orderDate: null,
      orderTotalPrice: [
        {
          description: null,
          name: "totalPrice",
          recurringChargePeriod: null,
          unitOfMeasure: null,
          price: {
            percentage: null,
            taxRate: null,
            dutyFreeAmount: null,
            taxIncludedAmount: { unit: "GBP", value: "0.010725" }
          }
        },
        {
          description: "Early Termination Fee",
          name: "terminationFee",
          recurringChargePeriod: null,
          unitOfMeasure: null,
          price: {
            percentage: "100.0",
            taxRate: null,
            dutyFreeAmount: null,
            taxIncludedAmount: null
          }
        }
      ],
      productOrderItem: [
        {
          id: "13640815",
          quantity: 1,
          action: null,
          itemPrice: [
            {
              description: null,
              name: null,
              recurringChargePeriod: null,
              unitOfMeasure: null,
              price: {
                percentage: null,
                taxRate: null,
                dutyFreeAmount: null,
                taxIncludedAmount: { unit: null, value: "0.01" }
              }
            }
          ],
          itemTotalPrice: [
            {
              description: null,
              name: null,
              recurringChargePeriod: null,
              unitOfMeasure: null,
              price: {
                percentage: null,
                taxRate: null,
                dutyFreeAmount: null,
                taxIncludedAmount: { unit: null, value: "0.01" }
              }
            }
          ],
          product: {
            id: null,
            href: null,
            name: null,
            description:
              "Worry-Free Business Security Suites - Worry Free 1 Day Auto Renew - Per User Fee",
            status: null,
            isBundle: null,
            orderDate: null,
            startDate: null,
            geTerminationDate: null,
            productOrderItem: null,
            productPrice: null
          },
          state: null
        },
        {
          id: "13640816",
          quantity: 1,
          action: null,
          itemPrice: [
            {
              description: null,
              name: null,
              recurringChargePeriod: null,
              unitOfMeasure: null,
              price: {
                percentage: "7.25",
                taxRate: null,
                dutyFreeAmount: null,
                taxIncludedAmount: { unit: null, value: null }
              }
            }
          ],
          itemTotalPrice: [
            {
              description: null,
              name: null,
              recurringChargePeriod: null,
              unitOfMeasure: null,
              price: {
                percentage: null,
                taxRate: null,
                dutyFreeAmount: null,
                taxIncludedAmount: { unit: null, value: "7.25E-4" }
              }
            }
          ],
          product: {
            id: null,
            href: null,
            name: null,
            description: "Sales Tax",
            status: null,
            isBundle: null,
            orderDate: null,
            startDate: null,
            geTerminationDate: null,
            productOrderItem: null,
            productPrice: null
          },
          productTerm: [
            {
              name: "activationPeriod",
              validFor: {
                endDateTime: "2024-02-01T00:00:00Z",
                startDateTime: "2023-08-31T00:00:00Z"
              }
            },
            {
              name: "contractDate",
              validFor: {
                endDateTime: "2026-08-31T00:00:00Z"
              }
            },
            {
              name: "minimumServiceLength",
              duration: {
                amount: 12.0
              }
            }
          ],
          relatedProductOrder: [
            {
              id: "6906679",
              category: "NEW",
              orderTotalPrice: [
                {
                  name: "totalPrice",
                  price: {
                    taxIncludedAmount: {
                      unit: "GBP",
                      value: 15.0
                    }
                  }
                },
                {
                  description: "Early Termination Fee",
                  name: "terminationFee",
                  priceType: "PERCENTAGE",
                  price: {
                    percentage: 100.0
                  }
                }
              ],
              payment: [
                {
                  id: "171676",
                  name: "paymentPlanId"
                }
              ],
              productOrderItem: [
                {
                  id: "15665431",
                  quantity: 1,
                  itemPrice: [
                    {
                      price: {
                        taxIncludedAmount: {
                          value: 15.0
                        }
                      }
                    }
                  ],
                  itemTotalPrice: [
                    {
                      price: {
                        taxIncludedAmount: {
                          value: 15.0
                        }
                      }
                    }
                  ],
                  product: {
                    description:
                      "Wix Website Builder - eCommerce - Monthly Fee",
                    productCharacteristic: [
                      {
                        name: "orderLineUnit",
                        value: "NOT_APPLICABLE"
                      },
                      {
                        name: "productOrderLineItemType",
                        value: "ITEM"
                      },
                      {
                        name: "frequency",
                        value: "MONTHLY"
                      }
                    ]
                  }
                }
              ],
              state: "ACTIVE"
            }
          ],
          state: null
        }
      ]
    }
  ]
};

export const subscriptionResponse = [
  subscriptionLookout,
  subscriptionTrendMicro,
  subscriptionCybSafe
];

export const notificationsMock = [
  {
    status: "CYB-015",
    textMessage: "",
    global: false,
    serviceId: "",
    dateReceived: "2023-12-01T11:45:09.917-0300",
    onboardingCall: 0.0,
    read: false,
    notificationUniqueKey: "250bfded-19cc-4208-868d-9bd58572b1fe#CYB-015"
  },
  {
    status: "CYB-013",
    textMessage: "",
    global: false,
    serviceId: "",
    dateReceived: "2023-12-01T11:45:09.917-0300",
    onboardingCall: 0.0,
    read: false,
    notificationUniqueKey: "55067f7c-76eb-43f1-bd02-2277a6aa7fe8#CYB-013"
  },
  {
    status: "CYB-014",
    textMessage: "",
    global: false,
    serviceId: "",
    dateReceived: "2023-12-01T11:45:09.917-0300",
    onboardingCall: 0.0,
    read: false,
    notificationUniqueKey: "62bec8db-8b95-466e-988d-66169e0e77e3#CYB-014"
  }
];

// VSDM
export const inventory = [
  {
    id: "123456789",
    name: "Apple 15 MacBook Air",
    relatedParty: [
      {
        id: "1234677",
        role: "OrganizationGroupId",
        "@baseType": "RelatedParty",
        "@referredType": "Organization"
      },
      {
        id: "2234677",
        role: "OrganizationGroupUuId",
        "@baseType": "RelatedParty",
        "@referredType": "Organization"
      }
    ],
    resourceCharacteristic: [
      {
        name: "ConnectionType",
        value: "LAN"
      },
      {
        name: "NetworkName",
        value: "Vodafone"
      },
      {
        name: "IPAddress",
        value: "100:10:7:1"
      },
      {
        name: "MACAddress",
        value: "00:1b:63:84:45:e6"
      },
      {
        name: "LastSeen",
        value: "2023-07-25T11:47:41.236Z"
      },
      {
        name: "Udid",
        value: "1234567890"
      }
    ],
    resourceSpecification: {
      vendor: "Apple",
      model: "MacBook Air Pro"
    },
    "@baseType": "Resource",
    "@type": "DeviceResource",
    serialNumber: "125326879095500"
  },
  {
    id: "223456789",
    name: "Apple 15 MacBook",
    relatedParty: [
      {
        id: "2234677",
        role: "OrganizationGroupId",
        "@baseType": "RelatedParty",
        "@referredType": "Organization"
      },
      {
        id: "3234677",
        role: "OrganizationGroupUuId",
        "@baseType": "RelatedParty",
        "@referredType": "Organization"
      }
    ],
    resourceCharacteristic: [
      {
        name: "ConnectionType",
        value: "LAN"
      },
      {
        name: "NetworkName",
        value: "Vodafone"
      },
      {
        name: "IPAddress",
        value: "100:10:7:2"
      },
      {
        name: "MACAddress",
        value: "01:1b:63:84:45:e6"
      },
      {
        name: "LastSeen",
        value: "2023-07-25T11:47:41.236Z"
      },
      {
        name: "Udid",
        value: "2234567890"
      }
    ],
    resourceSpecification: {
      vendor: "Apple",
      model: "MacBook"
    },
    "@baseType": "Resource",
    "@type": "DeviceResource",
    serialNumber: "225326879095500"
  }
];

export const subscriptions = [
  {
    name: "SecurityAudit",
    "@type": "Product",
    productCharacteristic: [
      {
        name: "TotalDevices",
        value: 73
      },
      {
        "@type": "objectArrayCharacteristic",
        name: "Security",
        valueType: "object",
        value: [
          {
            "@type": "Characteristic",
            name: "Compromised",
            value: "1"
          },
          {
            "@type": "Characteristic",
            name: "NoPasscode",
            value: "10"
          },
          {
            "@type": "Characteristic",
            name: "NotEncrypted",
            value: "20"
          }
        ]
      },
      {
        "@type": "objectArrayCharacteristic",
        name: "Ownership",
        valueType: "object",
        value: [
          {
            "@type": "Characteristic",
            name: "CorporateDedicated",
            value: "37"
          },
          {
            "@type": "Characteristic",
            name: "CorporateShared",
            value: "24"
          },
          {
            "@type": "Characteristic",
            name: "EmployeeOwned",
            value: "12"
          }
        ]
      },
      {
        "@type": "objectArrayCharacteristic",
        name: "Platforms",
        valueType: "object",
        value: [
          {
            "@type": "Characteristic",
            name: "WindowsMobile",
            value: "15"
          },
          {
            "@type": "Characteristic",
            name: "Apple",
            value: "12"
          },
          {
            "@type": "Characteristic",
            name: "Android",
            value: "41"
          }
        ]
      },
      {
        "@type": "objectArrayCharacteristic",
        name: "EnrollmentStatus",
        valueType: "object",
        value: [
          {
            "@type": "Characteristic",
            name: "Enrolled",
            value: "41"
          },
          {
            "@type": "Characteristic",
            name: "Unenrolled",
            value: "23"
          },
          {
            "@type": "Characteristic",
            name: "Registered",
            value: "32"
          }
        ]
      }
    ],
    relatedParty: [
      {
        id: "OG7811330287",
        role: "OrganizationGroup",
        "@baseType": "relatedParty",
        "@referredType": "Organization"
      }
    ]
  }
];
