import React, { useEffect } from "react";

import { Container } from "@source-web/container";
import { Icon } from "@source-web/icon";
import { Paragraph } from "@source-web/paragraph";

import { useVSDMData } from "../../../../../../pageTemplates/servicesPage/hooks/useVSDMData";
import { FormattedNotification } from "../../../../../NotificationBuilder/NotificationBuilder.types";
import { PrimaryButton } from "../../../../../PrimaryButton";
import * as Styled from "../../styles/NotificationsTab.styles";

export interface NotificationTileProps {
  serviceData: FormattedNotification;

  onButtonClick: () => void;
  onClose: (id: FormattedNotification["notificationUniqueKey"]) => void;
}

const NotificationTile: React.FunctionComponent<NotificationTileProps> = ({
  serviceData,
  onButtonClick,
  onClose
}: NotificationTileProps) => {
  const [caption, setCaption] = React.useState<string>(serviceData.caption);

  const {
    data: VSDMData,
    isLoading: VSDMLoading,
    isError: VSDMError
  } = useVSDMData(serviceData.status === "CYB-016");

  useEffect(() => {
    if (VSDMData?.devices && !VSDMLoading && !VSDMError) {
      const enrolled = VSDMData.devices.enrollment.enrol;
      const totalDevices = VSDMData.devices?.total;

      setCaption(
        serviceData.caption
          .replace("{value1}", enrolled)
          .replace("{value2}", totalDevices)
      );
    }
  }, [VSDMData, VSDMLoading, VSDMError, serviceData.caption]);

  return (
    <Styled.Notification
      button={!!serviceData.buttonText}
      key={serviceData.serviceName}
    >
      {serviceData && serviceData.caption && serviceData.heading && (
        <Container>
          <Styled.NotificationHeader>
            <Paragraph weight={3} size={2}>
              {serviceData.heading}
            </Paragraph>
            <Styled.CloseButtonContainer
              onClick={() => onClose(serviceData.notificationUniqueKey)}
            >
              <Icon name="close" group="system" />
            </Styled.CloseButtonContainer>
          </Styled.NotificationHeader>
          <Styled.NotificationBody>
            <Paragraph weight={3} size={2}>
              {serviceData.subHeading}
            </Paragraph>
            <Paragraph size={2}>{caption}</Paragraph>
            {serviceData.buttonText && (
              <Styled.ButtonContainer>
                <PrimaryButton
                  text={serviceData.buttonText}
                  appearance="primary"
                  onClick={() => onButtonClick()}
                />
              </Styled.ButtonContainer>
            )}
          </Styled.NotificationBody>
        </Container>
      )}
    </Styled.Notification>
  );
};

export default NotificationTile;
