import Ajv from "ajv";
import ajvErrors from "ajv-errors";
import addFormats from "ajv-formats";
import { Formik } from "formik";
import React, { ComponentType, useContext } from "react";
import { ReactReduxContext } from "react-redux";

import { elementPluginType } from "@vf-djr/renderer";

import { useCampaignFormContext } from "../../../context/forms/useForms";
import { usePathnameByIndex } from "../../../lib";

const ajv = new Ajv({
  $data: true,
  allErrors: true
});
addFormats(ajv);
ajvErrors(ajv);

const djrApiMethodsEnums = {
  getAvailableDates: "getAvailableDates",
  onSubmitForm: "onSubmitForm"
};

const FormPlugin: elementPluginType = (
  { __props: { onSubmit, schema, formFlow } = {} },
  Element,
  setupProperties
) => {
  const { submitAndFinalize } = useCampaignFormContext();

  const { store } = useContext(ReactReduxContext);

  const pathname = usePathnameByIndex(2);

  function getStore() {
    const { servicesPage } = store.getState();
    return servicesPage;
  }

  if (
    typeof onSubmit === "string" &&
    (!setupProperties || !setupProperties[onSubmit])
  ) {
    console.error(`Form plugin missing ${onSubmit} function`);
    return Element;
  }

  const handleSubmission = async () => {
    const { form } = getStore();

    if (onSubmit.includes(djrApiMethodsEnums.getAvailableDates)) {
      setupProperties?.[onSubmit]({ formType: pathname });
      return;
    }

    if (onSubmit.includes(djrApiMethodsEnums.onSubmitForm)) {
      await submitAndFinalize(form.values);

      return;
    }
  };

  let validate = ajv.compile(schema);

  const WrappedFormik = (props: any) => {
    const handleValidation = (values: any) => {
      if (formFlow === "serviceRequest") {
        schema.required = store.getState().servicesPage.form.required;
        validate = ajv.compile(schema);
      }
      const valid = validate(values);
      if (!valid && validate.errors) {
        return validate.errors.reduce((total, current) => {
          const name = current.instancePath.substring(1)
            ? current.instancePath.substring(1)
            : current.params.missingProperty;

          const message = schema.errorMessage.properties[name]
            ? schema.errorMessage.properties[name]
            : current.message;

          return {
            ...total,

            [name]: message
          };
        }, {});
      }
    };

    return (
      // @ts-ignore
      <Formik
        {...props}
        onSubmit={
          typeof onSubmit === "string" ? handleSubmission : props.onSubmit
        }
        validate={handleValidation}
      />
    );
  };

  return WrappedFormik as ComponentType;
};

export default FormPlugin;
