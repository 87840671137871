import { getScoreFactory } from "../../../context/auth/helpers";
import { Roles } from "../../utilities";
import { fetchCyberhubMiddleware } from "../serviceUtils";

export async function getSecurityScoreAssessment(
  role: Roles = Roles.company,
  industry: string | null = null,
  factory: (args: any) => any = getScoreFactory
) {
  const companyIndustry = industry ? `&industry=${industry}` : "";

  return await fetchCyberhubMiddleware(
    `scores/assessment?role=${role}${companyIndustry}`
  )
    .then(async (res) => {
      const parsedData = factory(res);
      return parsedData;
    })
    .catch((e) => {
      throw e;
    });
}

export async function fetchPortfolio() {
  try {
    return await fetchCyberhubMiddleware("scores/portfolio", {
      method: "POST"
    });
  } catch (error) {
    console.error("Error:", error);
    // Handle any errors that occur during the API call
    throw error;
  }
}

export async function fetchIndividual() {
  try {
    return await fetchCyberhubMiddleware("scores/individual", {
      method: "POST"
    });
  } catch (error) {
    console.error("Error:", error);
    // Handle any errors that occur during the API call
    throw error;
  }
}
