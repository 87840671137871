import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

export const MainContainer = styled.main`
  height: 100%;
`;

export const PartnerModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PartnerHeader = styled.div(
  (props) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      &:last-child {
        margin-inline: 6px;
      }
    }
    svg {
      margin-right: 20px;
      width: 42px;
      height: 42px;
    }
    margin-bottom: 48px;
    @media only screen and (min-width: ${props.theme.breakpoints
        .sm}px) and (max-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      flex-wrap: wrap;
      svg {
        margin-right: 8px;
      }
      h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }

    @media only screen and (min-width: ${props.theme.breakpoints
        .md}px) and (max-width: ${props.theme.breakpoints.lg}px) {
      display: flex;
      flex-wrap: wrap;
      h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        &:last-child {
          margin-inline: 6px;
        }
      }
    }
  `
);

export const PartnerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PartnerBody = styled.div`
  margin: 10px 0;
  [data-component-name="Checkbox"] {
    margin-top: 36px;
  }
`;

export const PartnerFooter = styled.div`
  margin: 40px 0 0;
  display: grid;
  place-items: center;
  [data-component-name="Button"] {
    width: 300px;
  }
`;

export const StyledServiceDialog = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 2.5em;
    gap: 1em;
  `
);
export const StyledServiceDialogBodyWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    & > p {
      max-width: 600px;
    }
  `
);

export const StyledStepGridItemWrapper = styled.div(
  (props) => css`
    min-height: 0;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      margin: 0;
      min-height: 300px;
    }
  `
);
export const StyledStepGridWrapper = styled.div(
  (props) => css`
    display: flex;
    width: 100%;
    place-items: center;
    flex: 1 1 auto;
    gap: 2.5em;
    flex-direction: column;
    margin: 2.5em;
    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      margin: 0;
      gap: 0;
      flex-direction: row;
    }
  `
);

export const StyledServiceDialogImage = styled.img(
  () => css`
    width: 3.5rem;
    height: 3.5rem;
  `
);

export const StyledButtonWrapper = styled.div(
  (props: { theme: Theme; isSwap: boolean }) => css`
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      flex-direction: ${props?.isSwap ? "row-reverse" : "row"};
      gap: 1em;
      padding: 0;

      ${props?.isSwap && `justify-content: flex-end`};
    }
  `
);

export const LookoutDialogSubmitButton = styled.div(
  (props) => css`
    width: 100%;
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      gap: 1em;
      display: flex;
      justify-content: flex-end;
      flex: 1 1 100%;
      width: auto;
    }
  `
);

export const DesktopContainer = styled.div(
  () => css`
    display: block;
  `
);

export const MobileContainer = styled.div`
  &&& {
    ${(props) => css`
      @media only screen and (max-width: ${props.theme.breakpoints.md + 1}px) {
        padding: 1.25em 0.5em;
      }
      display: none;
    `}
  }
`;

export const InvitationModalWrapper = styled.div(
  () => css`
    display: flex;
    flex-flow: row wrap;
    gap: 1em;
  `
);
export const LookoutDialogFormWrapper = styled.div(
  () => css`
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 auto;
    gap: 1em;
  `
);

export const LookoutDialogFormMethod = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    gap: 1em;

    & textarea[id="emailBody"] {
      height: 150px;
    }
  `
);

export const InvitationModalDescription = styled.div(
  (props) => css`
    min-width: 0;
    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      max-width: 75%;
    }
  `
);

export const ActionButtonWrapper = styled.div(
  (props: { theme: Theme; havePackageWidget: boolean }) => css`
    padding-top: ${props?.havePackageWidget ? props.theme.spacing.fixed[8] : 0};
  `
);

export const ActionButtonWrapperHref = styled.div(
  (props: { theme: Theme; havePackageWidget: boolean }) => css`
    padding-top: ${props?.havePackageWidget ? props.theme.spacing.fixed[8] : 0};
    margin-top: ${props.theme.spacing.fixed[4]}px;

    @media only screen and (min-width: ${props.theme.breakpoints
        .md}px) and (max-width: ${props.theme.breakpoints.lg}px) {
      a[data-component-name="Button"] {
        padding: ${props.theme.spacing.fixed[1]}px;
      }
    }
  `
);

export const ActionButtonWrapperHrefNoPadding = styled.div`
  button,
  a {
    background-color: #e60000;
    color: #ffffff;
    width: 162px;
  }
`;

export const NavTabContainer = styled.div(
  (props) => css`
    white-space: nowrap;

    div[role="tablist"] {
      justify-content: flex-start;
    }

    & > div > div {
      justify-content: flex-start;

      & > div > button > div {
        padding: 16px 24px;
      }
    }

    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      & > div > div > div > button > div {
        padding: 12px 18px;
      }
    }

    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      max-width: ${props.theme.gridConfig.springWidth}px;
      margin: auto;
    }

    & > div > div:nth-child(1) {
      padding-left: ${props.theme.spacing.fixed[6]}px;
      justify-content: flex-start;
      border-bottom: 1px solid ${props.theme.color.monochrome4.default};
    }
  `
);

export const FileUploader = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`;

export const UnsetColorOnStepper = styled.div`
  & div > ul > li > div > div > span {
    color: unset;
  }
`;

export const LoadingSpinnerContainer = styled.div(
  () => css`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 40px;
  `
);

export const NavigationalSectionContainer = styled.div(
  (props) => css`
    white-space: nowrap;

    div[role="tablist"] {
      justify-content: flex-start;
    }

    & > div > div {
      justify-content: flex-start;
      & > div > button > div {
        padding: 16px 24px;
      }
    }

    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      & > div > div > div > button > div {
        padding: 12px 18px;
      }
    }

    & > div > div:nth-child(1) {
      padding-left: ${props.theme.spacing.fixed[6]}px;
      justify-content: flex-start;
      @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
        margin: auto;
        max-width: ${props.theme.gridConfig.springWidth}px;
      }
    }
  `
);

export const SectionHeadingContainer = styled.div(
  () => css`
    padding-top: ${(props) => props.theme.spacing.fixed[6]}px;
    padding-bottom: ${(props) => props.theme.spacing.fixed[6]}px;
  `
);

export const ServiceTabsContainer = styled.div(
  (props) => css`
    padding: 1.25em;
    min-height: 100vh;
    & > section:first-of-type {
      padding: 1.25em;
      border-radius: ${props.theme.border.radius[2]};
    }

    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      max-width: ${props.theme.gridConfig.springWidth}px;
      margin: auto;
    }
  `
);

export const TabsContainer = styled.div(
  (props) => css`
    & > div > div > div:first-of-type {
      justify-content: start;
    }
    div[role="tablist"] {
      max-width: 1440px;
      margin: auto;
      justify-content: flex-start;
      padding: 0 12px;
    }

    @media (max-width: ${props.theme.breakpoints.lg + 1}px) {
      div[role="tablist"] {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
        & > div {
          flex-grow: 1;
        }
      }
    }
    & > div > div:first-of-type {
      z-index: 3;
      ${(props) => `

    background: ${props.theme.color.monochrome1.default};
    border-bottom: 1px solid ${props.theme.color.monochrome4.default};

    `}
    }
  `
);

export const TabContent = styled.div`
  ${() => css`
    max-height: 100%;
    width: 100%;
  `}
`;

export const SupportBannerWrapper = styled.div(
  (props) =>
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 40px 20px;
      margin-bottom: 96px;
      background-color: ${props.theme.name === "WS10"
        ? props.theme.color.primary2.default
        : props.theme.color.monochrome1.default};
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;

      @media (min-width: ${props.theme.breakpoints.md + 1}px) {
        font-size: 28px;
        line-height: 32px;
      }

      > span {
        margin-inline: ${props.theme.spacing.fixed[1]}px;
      }
    `
);

export const IconAndPhoneNumberWrapper = styled.div(
  (props) =>
    css`
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-inline: ${props.theme.spacing.fixed[3]}px;

      > span:first-child {
        margin-inline-end: ${props.theme.spacing.fixed[2]}px;

        > span {
          > span {
            > svg {
              stroke: ${props.theme.name === "WS10"
                ? "#EA1A1A"
                : props.theme.color.primary2.default};

              > path {
                fill: ${props.theme.name === "WS10"
                  ? "#EA1A1A"
                  : props.theme.color.primary2.default};
              }
            }
          }
        }
      }

      > span:last-child {
        border-bottom: 3px solid;
        color: ${props.theme.name === "WS10"
          ? "#EA1A1A"
          : props.theme.color.primary2.default};
      }
    `
);
