import { isJest, isServer } from "../utilities/utilities";
import { TrackPageView } from "./types";

export function trackView(evt: TrackPageView): void {
  if (isServer || isJest) return;

  window?.utag?.view(evt);
}

export function trackEvent(evt: TrackPageView) {
  if (isServer || isJest) return;
  window?.utag?.link({
    ...evt,
    product_name: "Vodafone Business CyberHub",
    device: window?.navigator?.userAgent
  });
}

export function trackPageView(pageView: TrackPageView): void {
  if (isServer || isJest) return;
  const perfEntries = performance.getEntriesByType("navigation");

  trackView({
    ...pageView,
    product_name: "Vodafone Business CyberHub",
    device: window?.navigator?.userAgent,
    timestamp: perfEntries[0]?.duration || 0
  });
}
