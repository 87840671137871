/* Utility methods for persisting an cart to session storage */
import { isServer } from "../../lib/utilities/utilities";
import { ICartState } from "./types";

const cartKey = "cart";

/* Adds the provided cart to session storage */
export function storeCart(cart: ICartState): void {
  if (isServer) return;

  sessionStorage.setItem(cartKey, JSON.stringify(cart));
}

/* 
  Retrieves the cart from session storage if it exists.
  Otherwise, returns null.
*/
export function getStoredCart(): ICartState | null {
  if (!isServer) {
    const cartData = sessionStorage.getItem(cartKey);

    if (cartData) return JSON.parse(cartData);
  }

  return null;
}

/* Removes the cart from session storage */
export function clearStoredCart(): void {
  if (!isServer) {
    sessionStorage.removeItem(cartKey);
  }
}
