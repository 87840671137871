import styled, { FlattenSimpleInterpolation, css } from "styled-components";

import { Interaction } from "@source-web/interaction";
import { respondTo } from "@source-web/mixins";

import { StyledLogoProps } from "./Logo.style.types";

export const Logo = styled(Interaction)(
  (props: StyledLogoProps): FlattenSimpleInterpolation => css`
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${respondTo.lg(css`
      padding: ${props.theme.spacing.fixed[2]}px 0;
    `)}
    svg {
      width: ${props.theme.spacing.responsive[8].sm}px;
      height: ${props.theme.spacing.responsive[8].sm}px;

      ${respondTo.md(css`
        width: ${props.theme.spacing.responsive[8].md}px;
        height: ${props.theme.spacing.responsive[8].md}px;
      `)}

      ${respondTo.lg(css`
        width: ${props.theme.spacing.responsive[8].lg}px;
        height: ${props.theme.spacing.responsive[8].lg}px;
      `)}
    }
  `
);
