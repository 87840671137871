import { graphql, useStaticQuery } from "gatsby";

const useTermsAndConditionsPDFQuery = () => {
  return useStaticQuery(graphql`
    query GetTermsAndConditionsPDF {
      termsAndConditionsPdf: allContentfulAsset(
        filter: {
          title: {
            in: ["Terms and Conditions PDF", "CyberHub Condizioni Generali"]
          }
        }
      ) {
        nodes {
          file {
            url
          }
        }
      }
    }
  `);
};

export default useTermsAndConditionsPDFQuery;
