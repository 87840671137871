import styled, { css } from "styled-components";

import { Theme } from "@vfuk/core-styles/dist/themes/themes.types";

interface SetButtonColorProps {
  isDark?: boolean;
  theme: Theme;
}

export const SetButtonColor = styled.div<SetButtonColorProps>`
  ${({ isDark, theme }) => css`
    button:hover {
      background-color: ${isDark && theme.color.monochrome3.default};
      color: ${isDark && theme.color.monochrome2.default};
    }
  `}
`;
