import dayjs from "dayjs";

import {
  ICompanyFactor,
  ISecurityScorecardRootObject,
  RiskAssessmentResult
} from "../../lib/services/securityScoreCardService/types";
import { getScoreAssessment } from "../../pageTemplates/dashboard/components/SecurityScoreWidget/SecurityScoreWidget";

export function lookoutFactory(response: any) {
  let data;
  try {
    const {
      bucket: [{ bucketCounter }]
    } = response;

    data = bucketCounter.reduce((total: any, current: any) => {
      const formattedValue = current?.value?.amount.toString();
      const name = current.valueName;
      const counterType = current?.counterType;

      if (name === "enrollmentCode") {
        const enrollmentCodeField = current?.user?.href;
        const enrollmentCodeToUrlParam = new URLSearchParams(
          enrollmentCodeField
        );

        const code = enrollmentCodeToUrlParam.values();

        const iteratedCode = [...code];

        return { ...total, [name]: iteratedCode[0] };
      }
      if (name === "lastAdminLogin") {
        const date = current?.consumptionPeriod?.startDateTime
          ? new Date(current?.consumptionPeriod?.startDateTime)
          : null;
        const formattedDate = dayjs(date).format("HH:MM - DD MMMM YYYY");
        return {
          ...total,
          [name]: formattedDate
        };
      }

      if (counterType) {
        const iteratedObject = total?.[name] || {};
        return {
          ...total,
          [name]: {
            ...iteratedObject,
            [counterType]: formattedValue
          }
        };
      }

      return { ...total, [name]: formattedValue };
    }, {});
  } catch (e) {
    console.error(e);
  }

  return data;
}

function reArrangeFactors(
  factors: RiskAssessmentResult["score"]
): ICompanyFactor[] {
  const factored = factors.reduce<any>(
    (acc, factor) => [...acc, { score: factor.score, name: factor.riskName }],
    []
  );

  return factored;
}

export function getScoreFactory(
  response: getScoreAssessment
): ISecurityScorecardRootObject {
  return {
    industry: response?.industry,
    name: response?.partyRole?.name || "",
    score: response?.riskAssessmentResult?.overallScore,
    companyFactors: reArrangeFactors(
      response?.riskAssessmentResult?.score
    )?.sort(
      (factorA, factorB) =>
        parseFloat(factorA?.score) - parseFloat(factorB?.score)
    ) as ICompanyFactor[]
  };
}

/**
 * Extracts the error code from the provided error message (coming from the API).
 * The error code is defined as a sequence of digits at the start of the message, followed by a colon.
 *
 * @param errorMessage - The error message from which to extract the error code.
 * @returns The extracted error code as a string, or undefined if no code could be extracted.
 */
export function extractErrorCode(errorMessage: string): string | undefined {
  const detailedErrorCodePattern = /^\s*(\d+):/;
  const match = errorMessage.match(detailedErrorCodePattern);
  return match ? match[1] : undefined;
}
