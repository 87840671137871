import React from "react";

import { Icon } from "@source-web/icon";

import { useAuth } from "../../../../context/auth/useAuth";
import { useCart } from "../../../../context/cart/useCart";
import { PageHeaderNode } from "../PageWrapperHeader/PageWrapperHeader";
import * as PageWrapperOptionsStyles from "./styles/LogoutButton.styles";

interface SettingsTabProps {
  closeWidget: () => void;
  title: PageHeaderNode["logoutTitle"];
}

const LogoutButton: React.FC<SettingsTabProps> = ({ closeWidget, title }) => {
  const { logOut } = useAuth();
  const cart = useCart();

  const logOutClick = async () => {
    await logOut();
    cart?.cleanCart();
    closeWidget();
  };

  return (
    <PageWrapperOptionsStyles.ButtonContainer onClick={logOutClick}>
      <PageWrapperOptionsStyles.DesktopLogoutContainer>
        <Icon name="power" group="system" /> <a>{title}</a>
      </PageWrapperOptionsStyles.DesktopLogoutContainer>
      <PageWrapperOptionsStyles.MobileLogoutContainer onClick={logOutClick}>
        <Icon name="log-out" group="system" size={4} /> <a>{title}</a>
      </PageWrapperOptionsStyles.MobileLogoutContainer>
    </PageWrapperOptionsStyles.ButtonContainer>
  );
};

export default LogoutButton;
