import {
  cybsafeMock,
  inventory,
  lookoutResponse,
  notificationsMock,
  subscriptionCybSafe,
  subscriptionLookout,
  subscriptionTrendMicro,
  subscriptions
} from "../../mocks/mockResponses";

export const mockedData: Record<string, any> = {
  "lookout/insights/": [
    { type: "message", data: JSON.stringify(lookoutResponse) }
  ],
  "marketplace/subscriptions?status=ACTIVE": [
    { type: "message", data: JSON.stringify(subscriptionLookout) },
    { type: "message", data: JSON.stringify(subscriptionCybSafe) }
  ],
  "marketplace/subscriptions": [
    { type: "message", data: JSON.stringify(subscriptionLookout) },
    { type: "message", data: JSON.stringify(subscriptionCybSafe) },
    { type: "message", data: JSON.stringify(subscriptionTrendMicro) }
  ],
  "notifications?company=ADP100000018143&user=1-HCKQKVC": notificationsMock.map(
    (notification) => {
      return { type: "message", data: JSON.stringify(notification) };
    }
  ),
  "cybsafe/reports/behavior/scores": [
    { type: "message", data: JSON.stringify(cybsafeMock.behaviorScore) }
  ],
  "v1/vsdm/inventory/physical/lite": [
    { type: "message", data: JSON.stringify(inventory) }
  ],
  "v1/vsdm/subscriptions": [
    { type: "message", data: JSON.stringify(subscriptions) }
  ]
};
