import React from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { Modal } from "@source-web/modal";

import { CartContextType } from "../../../context/cart/cartContext";
import { CartItem } from "../../../context/cart/types";
import { AdviseNotification } from "../../AdviseNotification";
import { ContainerWithTitle } from "../../ContainerWithTitle/";
import { PrimaryButton } from "../../PrimaryButton";
import { BasketItem } from "../components";
import * as Styled from "../styles/Cart.styles";

export type MiniCartProps = {
  cart: CartContextType;
  formatter: Intl.NumberFormat;
  navigateToExplore: () => void;
  navigateToBasket: () => void;
};

export const MiniCartComponent = ({
  cart,
  formatter,
  navigateToExplore,
  navigateToBasket
}: MiniCartProps) => {
  const theme = useTheme();
  return (
    <>
      <Heading level={3}>Your Basket</Heading>
      {cart?.cartState?.items?.length ? (
        <>
          <Styled.CartContainer>
            <ContainerWithTitle title="Products">
              {cart.cartState.items
                .sort((previousProd, nextProd) =>
                  previousProd.name.localeCompare(nextProd.name)
                )
                .map((product: CartItem) => {
                  return (
                    <BasketItem
                      addProductToCart={cart.addProductToCart}
                      removeProductFromCart={cart.removeProductFromCart}
                      product={product}
                      key={product.id}
                    />
                  );
                })}
            </ContainerWithTitle>
            <ContainerWithTitle title="Total">
              <Styled.PriceContainer>
                <div>
                  Upfront <br />
                  <Heading level={4} weight={3}>
                    {cart.cartState.priceUpfront
                      ? formatter.format(cart.cartState.priceUpfront)
                      : "-,00"}
                  </Heading>
                  (ex VAT)
                </div>
                <div>
                  Montly <br />
                  <Heading level={4} weight={3}>
                    {formatter.format(cart.cartState.price)}
                  </Heading>
                  (ex VAT)
                </div>
              </Styled.PriceContainer>
            </ContainerWithTitle>
            <Styled.ButtonContainer>
              <PrimaryButton
                appearance="alt1"
                inverse={theme.name !== "WS10"}
                text="Continue shopping"
                onClick={() => navigateToExplore()}
              />
              <PrimaryButton
                text="Go to basket"
                onClick={() => navigateToBasket()}
              />
            </Styled.ButtonContainer>
          </Styled.CartContainer>
        </>
      ) : (
        <AdviseNotification
          state="info"
          title="Your basket is empty"
          text="Let's start by adding something!"
          button={{
            text: "Explore",
            onClick: () => navigateToExplore()
          }}
        />
      )}
    </>
  );
};

export const MiniCart = ({
  cart,
  formatter,
  navigateToExplore,
  navigateToBasket
}: MiniCartProps) => {
  return (
    <Modal
      id="updates-flyout"
      srName="updates-widget"
      isOpen={cart.isMiniCartOpen}
      size={4}
      overlayBlur
      type="flyout"
      appearance="primary"
      onCloseCb={() => {
        cart?.closeMiniCart();
      }}
    >
      <MiniCartComponent
        cart={cart}
        formatter={formatter}
        navigateToExplore={navigateToExplore}
        navigateToBasket={navigateToBasket}
      />
    </Modal>
  );
};
