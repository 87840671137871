import styled, { DefaultTheme, css } from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NavButtonContainer = styled.div`
  display: flex;
  height: 100%;

  ${(props: { theme: DefaultTheme; isTargetted?: boolean }) =>
    props.isTargetted
      ? `
  border-bottom:2px solid;
  `
      : ""}
`;

export const TabsContainer = styled.div`
  & > div > div > div:first-of-type {
    justify-content: start;
  }
  & > div > div:first-of-type {
    z-index: 101;
    ${(props) => `

    background: ${props.theme.color.monochrome1.default};
    border-bottom: 1px solid ${props.theme.color.monochrome4.default};

    `}
  }
`;

export const TabContent = styled.div`
  ${(props) => css`
    padding: ${props.theme.spacing.fixed[1]}px;
    overflow-y: scroll;
    max-height: 100%;
    width: 100%;
    padding-right: 30px;
    position: absolute;
    z-index: 1;
    ::-webkit-scrollbar {
      opacity: 0;
    }
  `}
`;

export const LoaderWrapper = styled.div`
  padding-bottom: 2.725em;
`;

export const IconWrapper = styled.div`
  display: flex;
`;
