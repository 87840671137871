import React from "react";
import { useTheme } from "styled-components";

import { Toggle } from "@source-web/toggle";

import {
  ToggleContainerDesktop,
  ToggleContainerMobile
} from "../../styles/PageWrapper.styles";

interface ThemeToggleEntry {
  toggleTheme: () => void;
  themesLabels: Array<{ node: { label: string } }>;
}

export function ThemeToggle({ toggleTheme, themesLabels }: ThemeToggleEntry) {
  const { name } = useTheme();

  const darkModeLabel = themesLabels && themesLabels[0]?.node?.label;
  const lightModeLabel = themesLabels && themesLabels[1]?.node?.label;

  const actualThemeName = name !== "WS10" ? darkModeLabel : lightModeLabel;

  return (
    <>
      <ToggleContainerDesktop id="theme-toggle">
        <Toggle
          id="theme-toggle-input"
          name="theme-toggle-input"
          data-testid="toggle"
          isActive={name !== "WS10"}
          showIcons={false}
          onChange={() => {
            toggleTheme();
          }}
          value={name}
        >
          <label htmlFor="theme-toggle-input">{actualThemeName}</label>
        </Toggle>
      </ToggleContainerDesktop>

      <ToggleContainerMobile id="theme-toggle">
        <label htmlFor="theme-toggle-input">{actualThemeName}</label>
        <Toggle
          id="theme-toggle-input"
          name="theme-toggle-input"
          data-testid="toggle"
          isActive={name !== "WS10"}
          showIcons={false}
          onChange={() => {
            toggleTheme();
          }}
          value={name}
        ></Toggle>
      </ToggleContainerMobile>
    </>
  );
}
