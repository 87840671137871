import styled from "styled-components";

export const LanguageSelector__ModalComponent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LanguageSelector__ListItemComponent = styled.div`
  padding: 1.25em;
  border-top: 1px solid;
  display: flex;
  align-items: center;
  gap: 1.5em;
  cursor: pointer;
  &:last-child {
    border-bottom: 1px solid;
  }
`;

export const LanguageSelector__DesktopComponent = styled.div`
  ${(props) => `
@media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
  display: none;
}
display : flex;
flex-direction: row;
align-items: center;
justify-content: center;
font-weight: 200;
& > span + span {
  margin-left: 4px;
}
`}
`;

export const LanguageSelector__MobileComponent = styled.div`
  ${(props) => `
  @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-size: 20px;
    font-weight: 200;
    
  }
  & > span + span {
    margin-left: 10px;
  }
display : none;
`}
`;
