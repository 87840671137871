import { graphql, useStaticQuery } from "gatsby";

export const useFormsQuery = () => {
  const forms = useStaticQuery(graphql`
    query ContentfulForms {
      allContentfulServiceRequest(filter: { node_locale: { eq: "en-GB" } }) {
        edges {
          node {
            childrenContentfulServiceRequestServiceRequestFormJsonNode {
              internal {
                content
              }
            }
          }
        }
      }
      allContentfulOnboardingJourney(filter: { node_locale: { eq: "en-GB" } }) {
        edges {
          node {
            heading
            childContentfulOnboardingJourneyCampaignFormJsonNode {
              internal {
                content
              }
            }
            childContentfulOnboardingJourneyWalkthroughFormJsonNode {
              internal {
                content
              }
            }
            childContentfulOnboardingJourneyOnboardingFormJsonNode {
              internal {
                content
              }
            }
          }
        }
      }
      allContentfulServiceTile(filter: { node_locale: { eq: "en-GB" } }) {
        edges {
          node {
            childContentfulServiceTileFormJsonNode {
              internal {
                content
              }
            }
          }
        }
      }
    }
  `);
  return forms;
};
