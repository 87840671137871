import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

import { Heading } from "@source-web/heading";

import { closeWidget } from "../../../../store/slices/pageWrapper";
import { PageContext } from "../../../../types";
import LogoutButton from "../../../PageWrapper/components/PageWrapperOptions/LogoutButton";
import { LanguageSelector } from "../LanguageSelector";
import { PageHeaderNode } from "../PageWrapperHeader/PageWrapperHeader";
import useNavigationalTabsQuery, {
  ContentfulTab,
  ContentfulToggle,
  UseQueries
} from "../PageWrapperNavigation/queries";
import { ThemeToggle } from "../ThemeToggle";
import * as Styled from "./styles/MobileNavigation.style";

interface MobileWrapperNavigation {
  pageContext: PageContext;
  toggleTheme: () => void;
  pageHeader: PageHeaderNode;
}

export function MobileNavigation({
  pageContext,
  toggleTheme,
  pageHeader
}: MobileWrapperNavigation) {
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const queries: UseQueries = useNavigationalTabsQuery();
  const { logoutTitle, languageTitle } = pageHeader;

  const {
    allContentfulTabs,
    allContentfulToggle
  }: {
    allContentfulTabs: ContentfulTab;
    allContentfulToggle: ContentfulToggle;
  } = queries;

  const { locale } = pageContext;

  const routes = allContentfulTabs.nodes
    .filter((route) => route.node_locale === locale)
    .sort((a, b) => a.order - b.order);

  const themesLabels = allContentfulToggle.edges.filter(
    (theme) => theme.node.node_locale === locale
  );

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflow = isOpen ? "hidden" : "auto";
    }

    return () => {
      if (body) {
        body.style.overflow = "auto";
      }
    };
  }, [isOpen]);

  return (
    <React.Fragment>
      <Styled.MobileToggler>
        <Styled.NavBarToggler onClick={() => setIsOpen(true)} />
      </Styled.MobileToggler>

      <React.Fragment>
        <Styled.Mod isOpen={isOpen}>
          <Styled.CloseButton onClick={() => setIsOpen(false)} />
          <Styled.MobileMenu>
            {routes.map(({ label, contentfulid }) => (
              <Styled.MobileMenuItems key={label}>
                <Link
                  key={label}
                  style={{ textDecoration: "none" }}
                  to={
                    contentfulid === "#"
                      ? "#"
                      : `/${pageContext?.formattedLocale}/${contentfulid}`
                  }
                  onClick={handleLinkClick}
                >
                  <Heading level={4}>{label}</Heading>
                </Link>
              </Styled.MobileMenuItems>
            ))}
            <Styled.Footer>
              <ThemeToggle
                toggleTheme={toggleTheme}
                themesLabels={themesLabels}
              />
              <LanguageSelector title={logoutTitle} pageContext={pageContext} />
              <Styled.MobileLogoutButton onClick={() => setIsOpen(false)}>
                <LogoutButton title={languageTitle} closeWidget={closeWidget} />
              </Styled.MobileLogoutButton>
            </Styled.Footer>
          </Styled.MobileMenu>
        </Styled.Mod>
        <Styled.Backdrop onClick={() => setIsOpen(false)} isOpen={isOpen} />
      </React.Fragment>
    </React.Fragment>
  );
}
