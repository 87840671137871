import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

import { TourProps as SourceWebTourProps } from "@source-web/tour";

import loadable from "@loadable/component";
import WS10Theme from "@vfuk/core-theme-ws10";

const LazyLoadedTour = loadable.lib(() => import("@source-web/tour"));

type TourProps = SourceWebTourProps & {
  spotlightClicks: boolean;
};

const Tour: FC<TourProps> = (props) => {
  return (
    <ThemeProvider theme={WS10Theme}>
      <LazyLoadedTour>
        {({ default: Tour }) => <Tour {...props} />}
      </LazyLoadedTour>
    </ThemeProvider>
  );
};

export default Tour;
