import { Theme } from "@source-web/themes";

import Dark from "./FullScreenModal.dark.theme";
import Default from "./FullScreenModal.default.theme";
import { FullScreenModalThemeType } from "./FullScreenModal.theme.types";

const getLocalTheme = (theme: Theme): FullScreenModalThemeType => {
  switch (theme.name) {
    // @ts-ignore
    case "WS10Dark":
      return Dark(theme);
    default:
      return Default(theme);
  }
};

export default getLocalTheme;
