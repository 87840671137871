import { FormikContextType, connect } from "formik";
import * as React from "react";

import { elementPluginType } from "@vf-djr/renderer";
import { createMapStateToProps } from "@vf-djr/utilities";

const mapFormikToPropsPlugin: elementPluginType = (
  { __mapFormikToProps },
  Element,
  setupProperties
) => {
  const ContextWrappedComponent = ({
    formik,
    ...props
  }: {
    formik: FormikContextType<any>;
    props: Record<string, unknown>;
  }) => {
    const formikProps = createMapStateToProps(setupProperties?.selectors)(
      __mapFormikToProps
    )(formik);
    const elementProps = { ...props, ...formikProps };

    return <Element {...elementProps} />;
  };

  return connect(ContextWrappedComponent);
};

export default mapFormikToPropsPlugin;
