import React from "react";
import { createPortal } from "react-dom";
import { useTheme } from "styled-components";

import { Divider } from "@source-web/divider";
import { Heading } from "@source-web/heading";
import { InteractiveIcon } from "@source-web/interactive-icon";

import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";
import { Stepper } from "@vfuk/core-stepper";

import { PrimaryButton } from "../PrimaryButton";
import {
  FullScreenModalFooterProps,
  FullScreenModalProps
} from "./FullScreenModal.types";
import { DarkLogo, Logo } from "./components/Logo";
import * as Styled from "./styles/FullScreenModal.style";

export const FullScreenModalFooter = ({
  submitButtonProps,
  cancelButtonProps
}: FullScreenModalFooterProps) => {
  return (
    <Styled.ModalFooter>
      <Grid maxWidth="spring">
        <GridRow direction="row">
          <Styled.ActionContainer>
            <Styled.ActionItem>
              {cancelButtonProps && <PrimaryButton {...cancelButtonProps} />}
            </Styled.ActionItem>
            <Styled.ActionItem>
              <Styled.ActionButtonWrapperNoPadding>
                {submitButtonProps && <PrimaryButton {...submitButtonProps} />}
              </Styled.ActionButtonWrapperNoPadding>
            </Styled.ActionItem>
          </Styled.ActionContainer>
        </GridRow>
      </Grid>
    </Styled.ModalFooter>
  );
};

export const FullscreenModal = ({
  show,
  title,
  children,
  stepperProps,
  onClose,
  logoHref,
  logoOnClick
}: FullScreenModalProps) => {
  const theme: any = useTheme();

  return show
    ? createPortal(
        <Styled.ModalRenderer>
          <Styled.ModalScrollableSection>
            <Styled.ModalHeader>
              <Grid maxWidth="spring">
                <GridRow>
                  <GridColumn
                    colMd={11}
                    col={10}
                    alignSelf="center"
                    marginTop={5}
                    marginBottom={5}
                  >
                    <Styled.HeaderLogoContainer>
                      {theme.name === "WS10" ? (
                        <Logo
                          srText={`Navigates to ${logoHref}`}
                          href={logoHref}
                          onClick={logoOnClick}
                        />
                      ) : (
                        <DarkLogo
                          srText={`Navigates to ${logoHref}`}
                          href={logoHref}
                          onClick={logoOnClick}
                        />
                      )}
                      <Heading text={title} size={2} />
                    </Styled.HeaderLogoContainer>
                  </GridColumn>
                  <GridColumn colMd={1} col={2} alignSelf="center">
                    <Styled.InteractiveIconWrapper>
                      <InteractiveIcon
                        srText="close overlay"
                        name="close"
                        appearance="primary"
                        inverse={theme.name !== "WS10"}
                        onClick={onClose}
                      />
                    </Styled.InteractiveIconWrapper>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Styled.ModalHeader>
            <Divider appearance="secondary" noMargin />
            <Grid maxWidth="spring">
              <GridRow
                justify="start"
                direction="row"
                marginTop={6}
                marginBottom={6}
              >
                <Styled.ContentContainer>
                  <>
                    {stepperProps?.currentStep &&
                      stepperProps.currentStep > 0 && (
                        <Stepper {...stepperProps} />
                      )}
                    {children}
                  </>
                </Styled.ContentContainer>
              </GridRow>
            </Grid>
          </Styled.ModalScrollableSection>
        </Styled.ModalRenderer>,
        document.body
      )
    : null;
};
