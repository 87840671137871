import styled, { css } from "styled-components";

import localTheme from "../themes/FullScreenModal.theme";
import { StyledFullScreenModalProps } from "./FullScreenModal.style.types";

export const ModalRenderer = styled.div<StyledFullScreenModalProps>((props) => {
  const appearanceFromProp = localTheme(props.theme);
  return css`
    z-index: 9999991 !important;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: ${appearanceFromProp.background};

    animation: fadein 0.5s;
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;
});

export const ModalHeader = styled.div<StyledFullScreenModalProps>((props) => {
  return css`
    padding: ${props.theme.spacing.fixed[3]}px 0;
  `;
});

export const ContentContainer = styled.div<StyledFullScreenModalProps>(() => {
  return css`
    margin-top: 1em;
    margin-right: 1em;
    margin-left: 1em;
    width: 100%;
  `;
});

export const ModalFooter = styled.div<StyledFullScreenModalProps>((props) => {
  const appearanceFromProp = localTheme(props.theme);
  return css`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-top: 1px solid ${appearanceFromProp.footerBorder};
    background: ${appearanceFromProp.footerBackground};
    padding-top: 1em;
    padding-bottom: 1em;
    z-index: 999;
  `;
});

export const ModalScrollableSection = styled.div<StyledFullScreenModalProps>(
  (props) => {
    return css`
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
      height: 100vh;
      padding-bottom: ${props.theme.spacing.fixed[2]}rem;
    `;
  }
);

export const HeaderLogoContainer = styled.div<StyledFullScreenModalProps>(
  () => {
    return css`
      display: flex;
      align-items: center;
      gap: 1em;
    `;
  }
);

export const InteractiveIconWrapper = styled.div<StyledFullScreenModalProps>(
  () => {
    return css`
      &:hover {
        opacity: 0.5;
      }
    `;
  }
);

export const ActionContainer = styled.div<StyledFullScreenModalProps>(
  (props) => {
    return css`
      display: flex;
      gap: 1em;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      @media (min-width: ${props.theme.breakpoints.md}px) {
        margin-right: 2em;
      }
    `;
  }
);

export const ActionItem = styled.div<StyledFullScreenModalProps>((props) => {
  return css`
    @media (max-width: ${props.theme.breakpoints.md}px) {
      flex: 1;
      margin-left: 1em;
      margin-right: 1em;
    }
  `;
});

export const ActionButtonWrapperNoPadding = styled.div`
  button {
    background-color: #e60000;
  }
`;
