import { fetchCyberhubMiddleware } from "../../lib/services/serviceUtils";
import { clearStoredCart, getStoredCart } from "./cartStorage";
import { ICartState } from "./types";

export async function createShoppingCart(): Promise<any> {
  return fetchCyberhubMiddleware("/shoppingCart");
}

/**
 * Retrieves cart from storage
 */
export function getCart(): ICartState | null {
  return getStoredCart();
}

/**
 * Deletes cart from storage
 */
export function deleteCart(): void {
  clearStoredCart();
}
