import { graphql, useStaticQuery } from "gatsby";

type Placement = "top" | "bottom" | "left" | "right";

type TourStep = {
  content: string;
  disableBeacon: boolean;
  offset: number;
  title: string;
  target: string;
  placement: Placement;
};

type TourNode = {
  tourSteps: TourStep[];
  name: string;
  node_locale: string;
};

type TourEdge = {
  node: TourNode;
};

type TourMode = {
  edges: TourEdge[];
};

type ContactInformation = {
  body: {
    raw: string;
  };
  heading: string;
  usesButton: boolean;
  actionText: string;
  link: string;
};

type TourQueryResult = {
  darkMode: TourMode;
  lightMode: TourMode;
  support: ContactInformation;
  manage: ContactInformation;
};

export const useTourQuery = (): TourQueryResult => {
  return useStaticQuery(graphql`
    query TourQuery($locale: String) {
      darkMode: allContentfulTour(
        filter: { name: { eq: "Dark Mode Notification" } }
      ) {
        edges {
          node {
            tourSteps {
              content
              disableBeacon
              offset
              title
              target
              placement
            }
            name
            node_locale
          }
        }
      }
      lightMode: allContentfulTour(
        filter: { name: { eq: "Light Mode Notification" } }
      ) {
        edges {
          node {
            tourSteps {
              content
              disableBeacon
              offset
              title
              target
              placement
            }
            name
            node_locale
          }
        }
      }
      support: contentfulContactInformation(
        pageName: { eq: "Need support" }
        node_locale: { eq: $locale }
      ) {
        body {
          raw
        }
        heading
        usesButton
        actionText
        link
      }
      manage: contentfulContactInformation(
        pageName: { eq: "manage" }
        node_locale: { eq: $locale }
      ) {
        body {
          raw
        }
        heading
        usesButton
        actionText
        link
      }
    }
  `);
};
