import dayjs, { extend } from "dayjs";
import "dayjs/locale/it";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "styled-components";

import { InteractiveIcon } from "@source-web/interactive-icon";
import { Modal } from "@source-web/modal";
import { Tab, Tabs } from "@source-web/tabs";

import { useAuth } from "../../context/auth/useAuth";
import { useCampaignFormContext } from "../../context/forms/useForms";
import {
  INotification,
  cleanArray,
  generateSetFromArray,
  useEventSource
} from "../../lib";
import { closeWidget, toggleWidget } from "../../store/slices/pageWrapper";
import { ItemWithBadge } from "../ItemWithBadge";
import { NotificationBuilder } from "../NotificationBuilder/NotificationBuilder";
import { FormattedNotification } from "../NotificationBuilder/NotificationBuilder.types";
import useServiceIdQuery from "../PageNotification/queries";
import NotificationsTab from "./components/NotificationsTab";
import * as Styled from "./styles/UpdatesWidget.style";

// CYB-18395 : Hub Updates to Be Hidden for Soft Launch
// import { HubUpdates as HubUpdatesType } from "../../types";

// TODO: hide until soft launch CYB-14142
// import AppointmentsTab from "./components/AppointmentsTab";
// import { useQuery } from "react-query";
// import { getActiveAppointments } from "../../lib/services/marketplaceService";
// import { IAppointment } from "../../lib/services/marketplaceService/types";

// import { HubUpdates } from "./components";

export interface UpdatesWidgetProps {
  locale: string;
  formattedLocale: string;
  //changelog: HubUpdatesType;
}

export interface ContentfulUpdatesWidget {
  confirmationModalCancelButton: string;
  confirmationModalConfirmButton: string;
  confirmationModalText: string;
  confirmationModalTitle: string;
  tabHubReleases: string;
  tabNotification: string;
  node_locale: string;
}

export interface UpdateWidgetData {
  allContentfulUpdatesWidget: {
    nodes: Array<ContentfulUpdatesWidget>;
  };
}

const useGetUpdatesWidgetData = () => {
  return useStaticQuery(graphql`
    query GetUpdatesWidgetData {
      allContentfulUpdatesWidget {
        nodes {
          tabNotification
          tabHubReleases
          confirmationModalTitle
          confirmationModalText
          confirmationModalCancelButton
          confirmationModalConfirmButton
          node_locale
        }
      }
    }
  `);
};

extend(relativeTime);
extend(isToday);
extend(utc);
extend(timezone);
extend(isYesterday);

const formatDate = (date: string, formattedLocale: string): string[] => {
  const day = new Date(date).toLocaleString(formattedLocale, {
    day: "2-digit"
  });
  const month = new Date(date).toLocaleString(formattedLocale, {
    month: "long"
  });
  return [day, month];
};

export function getDates(date: string, formattedLocale: string) {
  const formattedDate = formatDate(date, formattedLocale);

  const [day, month] = [formattedDate[0], formattedDate[1]];

  const isTodayDate = dayjs(date).isToday();
  const isYesterdayDate = dayjs(date).isYesterday();
  const differenceFromNow = dayjs(date).locale(formattedLocale).fromNow();

  return {
    isTodayDate,
    isYesterdayDate,
    differenceFromNow,
    day,
    month
  };
}

const UpdatesWidget = ({
  //changelog,
  locale,
  formattedLocale
}: UpdatesWidgetProps) => {
  const dispatch = useDispatch();

  const updatesWidgetData: UpdateWidgetData = useGetUpdatesWidgetData();

  const updatesData: ContentfulUpdatesWidget =
    updatesWidgetData.allContentfulUpdatesWidget.nodes.filter(
      (item) => item.node_locale === locale
    )[0];

  // TODO: hide until soft launch CYB-14142
  // const [appointmentsDate, setAppointmentsDates] = useState<string[]>([]);
  const { notificationsData, services } = useServiceIdQuery();

  const { getClickBasedOnAction } = useCampaignFormContext();

  const { authState } = useAuth();
  const theme = useTheme();

  const { company, userId } = authState?.user ?? {};

  const url = `notifications?company=${company}&user=${userId}`;
  const isOpened = useSelector((state) => state.pageWrapper.isWidgetOpen);

  const { data, isLoading, isClosed } = useEventSource<INotification>(url, {
    enabled: !!userId && !!company,
    isSingleEntity: false
  });

  const allNotificationsLength = (data as INotification[])?.length;

  const { notificationsList, notificationsDate } = useMemo(() => {
    if (!isClosed || !allNotificationsLength)
      return {
        notificationsList: [],
        notificationsDate: []
      };

    const notifications = {
      nodes: notificationsData?.nodes.filter(
        (item: { node_locale: string }) => item.node_locale === locale
      )
    };

    const allNotifications: Array<FormattedNotification | null> =
      generateSetFromArray(
        (data as INotification[])?.map((rawNotification: INotification) => {
          return NotificationBuilder(rawNotification, notifications, services);
        })
      );

    const notificationsDate = generateSetFromArray(
      (data as INotification[])?.map((item: INotification) =>
        dayjs.tz(item.dateReceived).toString()
      )
    );

    return {
      notificationsList: cleanArray(
        allNotifications
      ) as Array<FormattedNotification>,
      notificationsDate
    };
  }, [
    isClosed,
    allNotificationsLength,
    notificationsData?.nodes,
    data,
    locale,
    services
  ]);

  const handleCloseWidget = () => {
    dispatch(closeWidget());
  };

  return (
    <React.Fragment>
      <Modal
        id="updates-flyout"
        srName="updates-widget"
        isOpen={isOpened}
        size={3}
        type="flyout"
        overlayBlur
        appearance="primary"
        onCloseCb={handleCloseWidget}
      >
        <Styled.TabsContainer>
          <Tabs
            {...(theme.name === "WS10Dark" && {
              appearance: "secondary",
              inverse: true
            })}
          >
            {[
              <Tab
                text={updatesData?.tabNotification}
                id="notifications"
                key={updatesData?.tabNotification}
              >
                <Styled.TabContent>
                  <NotificationsTab
                    notificationDates={notificationsDate}
                    notificationsQuery={{
                      isLoading,
                      data: notificationsList
                    }}
                    navigateToNotification={getClickBasedOnAction}
                    closeWidget={handleCloseWidget}
                    updateWidgetData={updatesData}
                    formattedLocale={formattedLocale}
                  />
                </Styled.TabContent>
              </Tab>
            ]}
            {/* 
            // CYB-18395 : Hub Updates to Be Hidden for Soft Launch
            <Tab text="Appointments" id="appointments">
              <Styled.TabContent>
                <AppointmentsTab
                  services={services}
                  appointmentsDate={appointmentsDate}
                  appointmentsQuery={appointmentsQuery}
                  formattedLocale={formattedLocale}
                />
              </Styled.TabContent>
            </Tab> */}

            {/* <Tab text={updatesData?.tabHubReleases} id="hubUpdates">
              <Styled.TabContent>
                <HubUpdates hubUpdates={changelog} locale={locale} />
              </Styled.TabContent>
            </Tab> */}
          </Tabs>
        </Styled.TabsContainer>
      </Modal>
      <Styled.IconWrapper id="notifications">
        <ItemWithBadge number={notificationsList?.length}>
          <InteractiveIcon
            srText="Button to open the side menu"
            name="notification"
            size={4}
            onClick={() => dispatch(toggleWidget())}
          />
        </ItemWithBadge>
      </Styled.IconWrapper>
    </React.Fragment>
  );
};

export default UpdatesWidget;
