import { EventSourceDetailedStatus, OptionsType } from "./types";

const defaultDetailedStatus: EventSourceDetailedStatus = {
  isLoading: false,
  isClosed: false
};

const defaultOptions: OptionsType = {
  enabled: true,
  isSingleEntity: true,
  dataParsingFunction: undefined,
  enableLogs: false
};

export { defaultDetailedStatus, defaultOptions };
