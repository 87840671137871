import { createSlice } from "@reduxjs/toolkit";

export const pageWrapperSlice = createSlice({
  name: "pageWrapper",
  initialState: {
    isWidgetOpen: false
  },
  reducers: {
    toggleWidget: (state) => {
      state.isWidgetOpen = !state.isWidgetOpen;
    },
    closeWidget: (state) => {
      state.isWidgetOpen = false;
    }
  }
});

export const { toggleWidget, closeWidget } = pageWrapperSlice.actions;
