import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

interface StyledProps {
  theme: Theme;
}

export const EditableCell = styled.div`
  ${(props: StyledProps & { isEnabled: boolean }) => css`
    & > div {
      margin-top: ${props.theme.spacing.fixed[2]}px;
    }

    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      min-width: ${!props.isEnabled ? 180 : 0}px;
    }

    & > span {
      display: flex;
      padding: ${props.theme.spacing.fixed[2]}px
        ${props.theme.spacing.fixed[3]}px;
      white-space: none;
    }
    & > input {
      height: ${props.theme.spacing.fixed[8]}px;
    }
  `}
`;

export const TableWrapper = styled.div`
  ${(props: StyledProps) => css`
    overflow-x: scroll;

    ::-webkit-scrollbar {
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${props.theme.color.monochrome1.default};
      border-radius: ${props.theme.spacing.fixed[2]}px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: ${props.theme.spacing.fixed[2]}px;
      background-color: ${props.theme.color.monochrome6.default};
    }
    ::-webkit-scrollbar-thumb:hover {
      border-radius: ${props.theme.spacing.fixed[2]}px;
      background-color: ${props.theme.color.monochrome5.default};
    }

    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      min-width: ${props.theme.gridConfig.springWidth}px;
    }
    border: 1px solid ${props.theme.color.primary3.default};
    border-radius: ${props.theme.spacing.fixed[2]}px;

    tr {
      height: ${props.theme.spacing.fixed[8]}px;
      th {
        font-weight: initial;
      }
    }

    tr,
    td,
    td:last-child {
      min-height: ${props.theme.spacing.fixed[8]}px;
      @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
        padding: 0 ${props.theme.spacing.fixed[1]}px;
      }
    }
  `}
`;

export const TableAction = styled.div`
  ${(props: StyledProps) => css`
    padding: ${props.theme.spacing.fixed[2]}px;
    height: ${props.theme.spacing.fixed[8]}px;
    display: flex;
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  ${({ theme }: StyledProps) => css`
    min-height: ${theme.spacing.fixed[8]}px;

    @media only screen and (min-width: ${theme.breakpoints.md}px) {
      padding: 0 ${theme.spacing.fixed[3]}px;
    }
  `}
`;

export const SelectInputMFW = styled.div`
  & div {
    padding: 0;
    margin-bottom: 0;
  }
`;
