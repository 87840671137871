import { AxiosResponse } from "axios";

import { useAuth } from "../../../context/auth/useAuth";
import {
  buildApiUrl,
  createOptionsWithApiHeaders
} from "../../services/serviceUtils";

export function useFetchWithTracking() {
  const { checkTimeExpirationAuthState } = useAuth();

  const dateNow = Date.now();

  async function fetchCyberhubMiddlewareWithTracking(
    relativeUrl: string,
    options?: any,
    returnRequest: boolean = false
  ): Promise<AxiosResponse["data"] | { error: any }> {
    const requestUrl = buildApiUrl(relativeUrl, null, true);
    const prepareOptions = createOptionsWithApiHeaders({
      ...options,
      credentials: "include",
      headers: {
        Accept: "application/json; charset=utf-8",
        ...options?.headers
      }
    });

    // Check if the session is expired
    checkTimeExpirationAuthState(dateNow);

    const response = await fetch(requestUrl, { ...prepareOptions });

    if (!response.ok) {
      const error = await response.json();
      return { error };
    }

    if (returnRequest) {
      return response;
    }

    const text = await response.text();
    if (response.status === 200 || response.status === 204) {
      if (text.trim() === "") {
        return null;
      }
    }

    return JSON.parse(text);
  }

  return { fetchCyberhubMiddlewareWithTracking };
}
