import { Script } from "gatsby";
import React from "react";

const tealiumScript = (
  <Script id="second-unique-id">{`
      var utag_data={
          page_locale: "",
        event_category: "",
        event_action: "",
        event_label: "",
        journey_type: "",
        page_name: "",
        device: "",
        timestamp: ""
      }`}</Script>
);

const medalliaScript = (
  <Script
    type="text/javascript"
    src={process.env.GATSBY_MEDALLIA_JS_URL}
    async
  />
);

const ExternalLibraries = () => {
  return (
    <>
      {medalliaScript}
      {tealiumScript}
    </>
  );
};

export default ExternalLibraries;
