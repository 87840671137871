export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED"
}

interface ProductPrice {
  description: string | null;
  name: string | null;
  recurringChargePeriod: string | null;
  unitOfMeasure: string | null;
  price: {
    percentage: string | null;
    taxRate: string | null;
    dutyFreeAmount: string | null;
    taxIncludedAmount: {
      unit: string;
      value: string;
    } | null;
  } | null;
}

interface ProductOrderItem {
  id: string | null;
  quantity: number | null;
  action: string | null;
  itemPrice: ProductPrice[] | null;
  itemTotalPrice: ProductPrice[] | null;
  product: {
    id: string | null;
    href: string | null;
    name: string | null;
    description: string | null;
    status: SubscriptionStatus | null;
    isBundle: string | null;
    orderDate: string | null;
    startDate: string | null;
    geTerminationDate: string | null;
    productOrderItem: string | null; // Update this type as needed
    productPrice: string | null; // Update this type as needed
  } | null;
  state: string | null;
}

interface TotalPrice {
  description: string | null;
  name: string | null;
  recurringChargePeriod: string | null;
  unitOfMeasure: string | null;
  price: {
    percentage: string | null;
    taxRate: string | null;
    dutyFreeAmount: string | null;
    taxIncludedAmount: {
      unit: string;
      value: string;
    } | null;
  } | null;
}

interface RelatedProductOrder {
  id: string | null;
  href: string | null;
  externalId: string | null;
  cancellationDate: string | null;
  cancellationReason: string | null;
  completionDate: string | null;
  description: string | null;
  orderDate: string | null;
  orderTotalPrice: TotalPrice[] | null;
  productOrderItem: ProductOrderItem[] | null;
}

interface Product {
  id: string | null;
  href: string | null;
  name: string | null;
  description: string | null;
  status: string | null;
  isBundle: string | null;
  orderDate: string | null;
  startDate: string | null;
  geTerminationDate: string | null;
  productOrderItem: string | null; // Update this type as needed
  productPrice: string | null; // Update this type as needed
}

interface ProductTerm {
  name: string;
  validFor: {
    endDateTime: string;
    startDateTime: string;
  };
}

export interface IMiddlewareSubscription {
  id: string;
  status: SubscriptionStatus;
  orderDate: string | null;
  startDate: string;
  terminationDate: string;
  product: Product[];
  productTerm: ProductTerm[];
  relatedProductOrder: RelatedProductOrder[];
}
