import React, { FC, ReactElement } from "react";

import { LogoProps } from "./Logo.types";
import * as DarkStyled from "./styles/DarkLogo.style";
import * as Styled from "./styles/Logo.style";

export const Logo: FC<LogoProps> = ({
  onClick,
  srText,
  href
}: LogoProps): ReactElement => {
  return (
    <Styled.Logo onClick={onClick} href={href} label={srText}>
      <svg
        width="50"
        height="51"
        viewBox="0 0 50 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 50.858C38.8071 50.858 50 39.6651 50 25.858C50 12.0509 38.8071 0.858032 25 0.858032C11.1929 0.858032 0 12.0509 0 25.858C0 39.6651 11.1929 50.858 25 50.858Z"
          fill="#E60000"
        />
        <path
          d="M25.194 39.8001C18.3253 39.823 11.1779 33.9597 11.1479 24.5457C11.1321 18.3209 14.4906 12.3288 18.7839 8.76571C22.9715 5.29785 28.7077 3.07355 33.9113 3.05591C34.5816 3.05591 35.2819 3.10883 35.7105 3.25523C31.1614 4.19893 27.54 8.43233 27.5559 13.2355C27.5552 13.3719 27.5653 13.5081 27.5859 13.6429C35.199 15.4968 38.6545 20.0901 38.6756 26.4455C38.6968 32.8008 33.6802 39.7719 25.194 39.8001Z"
          fill="white"
        />
      </svg>
    </Styled.Logo>
  );
};

export const DarkLogo: FC<LogoProps> = ({
  onClick,
  srText,
  href
}: LogoProps): ReactElement => {
  return (
    <DarkStyled.DarkLogo onClick={onClick} href={href} label={srText}>
      <svg
        width="50"
        height="51"
        viewBox="0 0 50 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 50.858C38.8071 50.858 50 39.6651 50 25.858C50 12.0509 38.8071 0.858032 25 0.858032C11.1929 0.858032 0 12.0509 0 25.858C0 39.6651 11.1929 50.858 25 50.858Z"
          fill="white"
        />
        <path
          d="M25.194 39.8001C18.3253 39.823 11.1779 33.9597 11.1479 24.5457C11.1321 18.3209 14.4906 12.3288 18.7839 8.76571C22.9715 5.29785 28.7077 3.07355 33.9113 3.05591C34.5816 3.05591 35.2819 3.10883 35.7105 3.25523C31.1614 4.19893 27.54 8.43233 27.5559 13.2355C27.5552 13.3719 27.5653 13.5081 27.5859 13.6429C35.199 15.4968 38.6545 20.0901 38.6756 26.4455C38.6968 32.8008 33.6802 39.7719 25.194 39.8001Z"
          fill="#E60000"
        />
      </svg>
    </DarkStyled.DarkLogo>
  );
};
