import styled from "styled-components";

import { Theme } from "@source-web/themes";

export interface WidgetNotificationStyledType {
  theme: Theme;
  button?: boolean;
  isDark?: boolean;
}

export const NotificationHeader = styled.div`
  ${(props) => `
  display:flex;
  width:100%;
  justify-content:space-between;
  padding:${props.theme.spacing.fixed[2]}px ${props.theme.spacing.fixed[4]}px;
  border-bottom: 1px solid ${props.theme.color.primary3.default};

  & > p {
    margin-bottom:0;
  }
`}
`;

export const DateDividerContainer = styled.div`
  border-bottom: 1px solid;
  display: flex;
  width: 100%;
  padding: 20px 0;
  gap: 1rem;

  & > h5 {
    margin-bottom: 0;
    text-transform: capitalize;
  }
  & > p {
    display: flex;
    align-items: center;
  }

  &:not(:first-child) {
    border-top: 1px solid;
  }
`;

export const ButtonContainer = styled.div`
  ${(props) => `
  & > button {
    width: 80px;
    height:30px; 
  }
  width:100%;
  display:flex;
  justify-content:end;
  padding:${props.theme.spacing.fixed[2]}px ${props.theme.spacing.fixed[2]}px; 
`}
`;

export const NotificationBody = styled.div`
  ${(props) => `
  width:100%;
  justify-content:space-between;
  padding:${props.theme.spacing.fixed[3]}px ${props.theme.spacing.fixed[4]}px;
  ${
    props.theme.name !== "WS10"
      ? `& > p:last-of-type {
          color: ${props.theme.color.monochrome4.default};
      }`
      : ``
  }
`}
`;

export const NotificationContainer = styled.div`
  ${(props) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-bottom:${props.theme.spacing.fixed[1]}rem;
  height: 80vh;
  overflow: auto;  

  scrollbar-width: thin; 
  ${
    props.theme.name !== "WS10"
      ? `
        scrollbar-color: #7D7D7D #2C2C2C;      
      `
      : `scrollbar-color: #C1C1C1 #FAFAFA;`
  }
  `}
`;

export const Notification = styled.div<WidgetNotificationStyledType>`
  ${(props) => ` 
    & > div {
      padding:0;
      ${` background: ${props.theme.color.monochrome2.default};`}
    }
    button {
      display: ${!props.button ? "none" : "inline-flex"};
    }
    width: 100%;
  `}
`;

export const CloseButtonContainer = styled.div`
  cursor: pointer;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
