import { graphql, useStaticQuery } from "gatsby";

const useServiceIdQuery = () => {
  return useStaticQuery(graphql`
    query GetServiceHLId {
      notificationsData: allContentfulNotifications {
        nodes {
          buttonText
          caption
          heading
          subheading
          notificationId
          status
          href
          node_locale
        }
      }
      services: allContentfulServiceTile {
        nodes {
          heading
          serviceHlId
          isProfessionalService
        }
      }
    }
  `);
};

export default useServiceIdQuery;
