import { paramCase } from "param-case";
import React, { ReactNode, useMemo } from "react";
import { ThemeProvider } from "styled-components";

import { MinFooter } from "@source-web/min-footer";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Block, Inline } from "@contentful/rich-text-types";
import WS10Theme from "@vfuk/core-theme-ws10";

import { FooterLinksEntry, TermsAndConditionsPdfNode } from "../../../../types";
import useTermsAndConditionsPDFQuery from "./queries";
import * as Styled from "./styles/PageWrapperFooter.styles";

export function PageWrapperFooter({
  footerLinks,
  formattedLocale
}: {
  footerLinks: FooterLinksEntry;
  formattedLocale: string;
}) {
  // todo: remove commented code after auth is working
  // const currentPath = useLocation().pathname;
  // currentPath.includes("assess") ||
  // currentPath.includes("detect") ||
  // currentPath.includes("respond") ||
  // currentPath === "/en/cybershop";

  const {
    termsAndConditionsPdf
  }: { termsAndConditionsPdf: { nodes: TermsAndConditionsPdfNode[] } } =
    useTermsAndConditionsPDFQuery();

  const footer = useMemo(() => footerLinks.node, [footerLinks.node]);

  const legalLinks = useMemo(() => {
    const getNodeForLocale = (paramCaseItemText: string) => {
      if (
        formattedLocale === "en" &&
        paramCaseItemText === "website-terms-conditions"
      ) {
        return termsAndConditionsPdf.nodes?.find((item) =>
          item.file.url.includes("CyberHub_Terms_Conditions.pdf")
        );
      } else if (
        formattedLocale === "it" &&
        paramCaseItemText === "termini-e-condizioni-sito-web"
      ) {
        return termsAndConditionsPdf.nodes?.find((item) =>
          item.file.url.includes("CyberHub_Condizioni_Generali.pdf")
        );
      }
    };

    const filteredLinks =
      footer?.links?.filter((item) => item.showText !== false) || [];

    return filteredLinks.map((item) => {
      const localeNode = getNodeForLocale(paramCase(item.text));
      return {
        id: paramCase(item.text),
        text: item.text,
        href: localeNode ? localeNode.file?.url : item.href,
        dataAttributes: {
          target: item.target
        }
      };
    });
  }, [footer.links, formattedLocale, termsAndConditionsPdf.nodes]);

  const richTextDocument = JSON.parse(footer.copyrightText.raw);

  const options = {
    renderNode: {
      paragraph: (node: Block | Inline, children: ReactNode | ReactNode[]) => {
        return <Styled.FooterParagraph>{children}</Styled.FooterParagraph>;
      },
      hyperlink: (node: Block | Inline, children: ReactNode | ReactNode[]) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      }
    }
  };

  return (
    <Styled.PageWrapperFooter>
      <ThemeProvider theme={WS10Theme}>
        <Styled.Footer>
          <MinFooter legalLinks={legalLinks} copyrightText="" />
          <Styled.CopyRight>
            {documentToReactComponents(richTextDocument, options)}
          </Styled.CopyRight>
        </Styled.Footer>
      </ThemeProvider>
    </Styled.PageWrapperFooter>
  );
}
