import { graphql, useStaticQuery } from "gatsby";

const usePageWrapperHeaderQuery = () => {
  return useStaticQuery(graphql`
    query GetPageWrapperHeaderQuery {
      allContentfulPageHeader {
        nodes {
          logoutTitle
          languageTitle
          node_locale
        }
      }
    }
  `);
};

export default usePageWrapperHeaderQuery;
