import React from "react";

import { Button, ButtonProps } from "@source-web/button";

import { WithLightMode } from "../WithLightMode";

export default function PrimaryButton(
  props: ButtonProps & {
    className?: string;
    children?: any | null;
  }
) {
  return (
    <WithLightMode>
      <Button {...props} />
    </WithLightMode>
  );
}
