import React, { FC, ReactElement } from "react";
import { I18nextProvider } from "react-i18next";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import { OverlayProvider } from "@source-web/overlay-controller";

import { AuthProvider } from "../../context/auth/authProvider";
import { getStoredUserData } from "../../context/auth/authStorage";
import { CartProvider } from "../../context/cart/cartProvider";
import { CampaignsFormProvider } from "../../context/forms/formProvider";
import { getStoredFormData } from "../../context/forms/formStorage";
import { cyberhubQueryClient } from "../../store/cyberhubQueryClient";
import { store } from "../../store/store";
import { ExpirationModal } from "../../types";
import i18next from "../i18n";

export const PageWrapperProviders: FC<{
  children: ReactElement[];
  theme: any;
  formattedLocale: string;
  expirationModal: ExpirationModal;
}> = ({ children, theme, formattedLocale, expirationModal }) => {
  const initialAuthState = getStoredUserData();

  const initialFormConfig = getStoredFormData();

  return (
    <I18nextProvider i18n={i18next}>
      <QueryClientProvider client={cyberhubQueryClient}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <AuthProvider
              initialAuthState={initialAuthState}
              expirationModal={expirationModal}
            >
              <OverlayProvider>
                <CartProvider>
                  <CampaignsFormProvider
                    formConfig={initialFormConfig}
                    formattedLocale={formattedLocale}
                  >
                    {children}
                  </CampaignsFormProvider>
                </CartProvider>
              </OverlayProvider>
            </AuthProvider>
          </Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
};
