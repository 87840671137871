import { navigate } from "gatsby";
import React from "react";

import { InteractiveIcon } from "@source-web/interactive-icon";

import { useCart } from "../../context/cart/useCart";
import { priceFormatter } from "../../lib";
import { ItemWithBadge } from "../ItemWithBadge";
import { CartIconContainer } from "./Cart.styles";
import { MiniCart } from "./MiniCart/MiniCart";

export function Cart({ formattedLocale }: { formattedLocale: string }) {
  const cart = useCart();

  const amountOfItemsOnCart = cart?.getTotalItems();

  const navigateTo = (to: string) => navigate(`/${formattedLocale}/${to}`);

  const toExplore = () => navigateTo("cybershop");

  const toBasket = () => navigateTo("basket");

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const parsedEnvValue = JSON.parse(
    process.env.GATSBY_ENABLE_SHOPPING_CART || "{}"
  );
  const shouldShowIcon = Boolean(parsedEnvValue);

  return shouldShowIcon ? (
    <div>
      <CartIconContainer shouldShowIcon={shouldShowIcon}>
        <ItemWithBadge number={amountOfItemsOnCart}>
          <InteractiveIcon
            srText="Button to open the shopping cart"
            name="shopping-trolley"
            group="system"
            size={5}
            onClick={() =>
              !isMobile ? cart.openMiniCart() : navigateTo("basket")
            }
          />
        </ItemWithBadge>
      </CartIconContainer>
      <MiniCart
        cart={cart}
        formatter={priceFormatter}
        navigateToExplore={toExplore}
        navigateToBasket={toBasket}
      />
    </div>
  ) : null;
}
