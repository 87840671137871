import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

import { IContainerWithTitleThemes } from "../ContainerWithTitle";

export const ContainerWithLabelBody = styled.div`
  ${(props: { theme: Theme; bodyTheme: IContainerWithTitleThemes }) => css`
    @media (min-width: ${props.theme.breakpoints.md}px) {
      flex-wrap: nowrap;
      display: flex;
      align-items: flex-start;
      gap: 1em;
      padding: 1.25em;
      flex-direction: column;
      justify-content: space-between;
    }
    padding: ${props.theme.spacing.fixed[3]}px ${props.theme.spacing.fixed[4]}px;
    width: 100%;
    background: ${props.bodyTheme === "primary"
      ? props.theme.color.monochrome2.default
      : props.theme.color.monochrome3.default};

    border-radius: 0 0 ${props.theme.border.radius[1]}
      ${props.theme.border.radius[1]};

    @media (max-width: ${props.theme.breakpoints.md}px) {
      & > div {
        margin: ${props.theme.spacing.fixed[3]}px 0;
      }
    }
  `}
`;

export const ContainerWithLabelHeader = styled.div`
  ${(props: { theme: Theme; headerTheme: IContainerWithTitleThemes }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1em ${props.theme.spacing.fixed[4]}px;

    background: ${props.headerTheme === "primary"
      ? props.theme.color.monochrome2.default
      : props.theme.color.monochrome3.default};
    border-bottom: 1px solid ${props.theme.color.monochrome5.default};
    border-radius: ${props.theme.border.radius[1]}
      ${props.theme.border.radius[1]} 0 0;

    & > p {
      margin-bottom: 0;
    }
  `}
`;
