// export const onInitialClientRender = () => {
//   /**
//    * This is a workaround for a bug in Gatsby
//    *
//    * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
//    */
//   globalHistory._onTransitionComplete();
// };

export const onRouteUpdate = ({ location }) => {
  const { pathname } = location;

  const formattedLocale = pathname.split("/");

  if (formattedLocale[1] === "undefined")
    window.location.href = `/en/${formattedLocale[2]}`;
};
