import { createContext } from "react";

import { CartItem, ICartState } from "./types";

/* Creates React context for authentication */

export interface CartContextType {
  itemsAdded: number;
  isReady: boolean;
  isMiniCartOpen: boolean;
  isSubmitted: boolean;
  isLoading: boolean;
  openMiniCart: () => void;
  closeMiniCart: () => void;
  createShoppingCart: () => Promise<void>;
  cartState: ICartState;
  addProductToCart: (product: CartItem & { quantity: number }) => Promise<void>;
  cleanCart: () => void;
  updateCartState: (update: ICartState) => void;
  removeProductFromCart: (productId: string) => void;
  onSubmitCart: () => Promise<void>;
  getTotalItems: () => number;
}

const CartContext = createContext<CartContextType>({
  itemsAdded: 0,
  isReady: false,
  isSubmitted: false,
  isLoading: false,
  cartState: { cartId: "", price: 0, items: [], priceUpfront: 0 },
  isMiniCartOpen: false,
  openMiniCart: () => {},
  closeMiniCart: () => {},
  createShoppingCart: async () => {},
  cleanCart: () => {},
  addProductToCart: async () => {},
  onSubmitCart: async () => {},
  removeProductFromCart: () => {},
  updateCartState: () => {},
  getTotalItems: () => 0
});

export { CartContext };
