import styled, { css, keyframes } from "styled-components";

import { Theme } from "@source-web/themes";

// @ts-ignore
import darkClose from "../../../../../assets/darkTheme/darkThemecloseButton.svg";
// @ts-ignore
import lightToggler from "../../../../../assets/darkTheme/darkThemehamBurger.svg";
// @ts-ignore
import lightClose from "../../../../../assets/lightTheme/lightThemecloseButton.svg";
// @ts-ignore
import darkToggler from "../../../../../assets/lightTheme/lightThemehamBurger.svg";

// Keyframes for slide animation

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const slideAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOutAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

export const MobileMenu = styled.div`
  margin-top: 56px;
  margin-left: 24px;
`;

export const Footer = styled.div`
  position: absolute;
  @media (orientation: landscape) {
    position: relative;
    margin-left: 0px;
  }
  bottom: 0;
  left: 0;
  margin-left: 24px;
  margin-bottom: 40px;
  & > :nth-last-child(-n + 2) {
    margin: 20px 0;
  }
  & > :not(:nth-last-child(-n + 2)) {
    margin: -4px 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    & > :not(:nth-last-child(-n + 2)) {
      margin: -2px 4px;
    }
    & > :nth-last-child(-n + 3) {
      margin: 6px 0;
    }
    & > :nth-last-child(-n + 2) {
      margin: 12px -6px;
    }
    margin-bottom: 80px;
  }
`;

export const MobileMenuItems = styled.li`
  font-style: normal;
  list-style-type: none;
  font-size: 20px;
  line-height: 36px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Backdrop = styled.button(
  (props: { theme: Theme; isOpen: boolean | null }) => css`
    all: unset;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: ${props.isOpen ? "blur(5px)" : "none"};
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
    animation: ${props.isOpen
      ? css`
          ${fadeIn} 0.3s ease-in-out forwards
        `
      : css`
          ${fadeOut} 0.3s ease-in-out forwards
        `};
    transform: ${props.isOpen ? "translateX(0)" : "translateX(-100%)"};
  `
);

export const Mod = styled.div(
  (props: { theme: Theme; isOpen: boolean | null }) => css`
    @media (min-width: ${props.theme.breakpoints.lg}) {
      display: none; /* Hide the modal on medium screens and larger */
    }
    @media (orientation: landscape) {
      overflow: auto;
    }
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 263px;
    background-color: ${props.theme.name === "WS10" ? "#FFFFFF" : "#0D0D0D"};
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 11;

    ${props.isOpen !== null &&
    css`
      animation: ${props.isOpen
        ? css`
            ${slideAnimation} 0.3s ease-in-out forwards
          `
        : css`
            ${slideOutAnimation} 0.3s ease-in-out forwards
          `};
    `}
    transform: ${props.isOpen ? "translateX(0)" : "translateX(100%)"};
  `
);

export const CloseButton = styled.button(
  (props: any) => `
  margin-right: 16px;
  margin-top: 16px;
  float: right;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url(${
    props.theme.name === "WS10" ? lightClose : darkClose
  });
  background-repeat: no-repeat;
  background-color: transparent;
  display: inline-block;
`
);

export const NavBarToggler = styled.button(
  (props: any) => `
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url(${
    props.theme.name === "WS10" ? darkToggler : lightToggler
  });
  background-repeat: no-repeat;
  background-color: transparent;
  display: inline-block;

  @media (width >= ${props.theme.breakpoints.lg}px) {
    display: none;
  }
`
);

export const MobileToggler = styled.div`
  ${(props) => css`
    display: none;
    @media only screen and (max-width: ${props.theme.breakpoints.lg}px) {
      display: block;
      place-items: center;
    }
  `}
`;

export const MobileLogoutButton = styled.div``;
