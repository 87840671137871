import styled, { css } from "styled-components";

interface ThemedSectionProps {
  appearance?: "primary" | "secondary" | "tertiary";
  fullHeight?: boolean;
  fillAvailable?: boolean;
}

export const ThemedSection = styled.div<ThemedSectionProps>(
  ({ appearance = "primary", theme, ...props }) => {
    const colorByProp = {
      primary: theme.color.monochrome1.default,
      secondary: theme.color.monochrome2.default,
      tertiary: theme.color.monochrome4.default
    };

    return css`
      min-height: ${props.fillAvailable ? "100vh" : "auto"};
      height: ${props?.fullHeight ? "100%" : "auto"};
      background-color: ${colorByProp[appearance]};
      border-bottom: 1px solid transparent;
    `;
  }
);
