import { Dispatch, SetStateAction } from "react";

import { defaultOptions } from "./defaults";
import { OptionsType, setStatusCallbackType } from "./types";

function handleLogs(
  message: string,
  e: Event | MessageEvent,
  enableLogging: boolean
) {
  if (enableLogging) console.debug(message, e);
}

function onMessage<T = any[]>({
  event,
  options,
  newData,
  setDataCallback,
  setStatusCallback
}: {
  event: MessageEvent;
  options: OptionsType;
  storedData: T[] | undefined;
  newData: T[];
  setStatusCallback: setStatusCallbackType;
  setDataCallback: Dispatch<SetStateAction<T[] | undefined>>;
}) {
  const evtSourceData: T = JSON.parse(event?.data);

  handleLogs("message", event, options.enableLogs as boolean);

  if (!evtSourceData) return undefined;

  const parsedMessage = options?.dataParsingFunction
    ? options?.dataParsingFunction(evtSourceData)
    : evtSourceData;

  (newData as T[]).push(parsedMessage);
  setDataCallback(newData);

  setStatusCallback({
    isLoading: false,
    isClosed: false
  });
}

function onError({
  eventSource,
  event,
  options = defaultOptions,
  setStatusCallback
}: {
  eventSource: EventSource;
  setStatusCallback: setStatusCallbackType;
  event: Event;
  options: OptionsType;
}) {
  handleLogs("error", event, options.enableLogs as boolean);

  eventSource.close();

  setStatusCallback({
    isLoading: false,
    isClosed: true
  });
}

export { handleLogs, onMessage, onError };
