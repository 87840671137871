import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { generateIcs } from "../../components/NotificationBuilder/NotificationBuilder";
import { FormattedNotification } from "../../components/NotificationBuilder/NotificationBuilder.types";
import { notificationActions, trackNotificationClick } from "../../lib";
import { snowApi } from "../../store/apis/snow";
import { setFormError } from "../../store/slices/servicesPage";
import { store } from "../../store/store";
import { FormFlows } from "../../types";
import { DynamicJourneyModal } from "./DynamicJourneyModal/";
import { FormConfigType, UseFormQueryType } from "./form.types";
import { FormContext } from "./formContext";
import { storeFormData } from "./formStorage";
import { useFormsQuery } from "./queries";

export const defaultFormConfig: FormConfigType = {
  heading: null,
  form: null,
  show: false,
  flow: undefined
};

export const availableFormTypes = {
  serviceRequest: "serviceRequest",
  campaign: "campaign",
  vciso: "vciso"
};

export function CampaignsFormProvider({
  children,
  formConfig = defaultFormConfig,
  formattedLocale
}: {
  children: React.ReactElement | React.ReactElement[];
  formConfig?: FormConfigType;
  formattedLocale: string;
}) {
  const [formConfigState, setFormConfig] = useState<FormConfigType>(formConfig);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [hasScore, setHasScore] = useState(false);

  const formFromQuery: UseFormQueryType = useFormsQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (formConfig) setFormConfig(formConfig);
  }, [formConfig]);

  function updateFormConfig(args: FormConfigType) {
    setFormConfig(args);
    storeFormData(args);
  }

  function getServiceForm({ heading, flow }: Partial<FormConfigType>) {
    const {
      allContentfulOnboardingJourney: { edges: forms },
      allContentfulServiceRequest: { edges: formsServiceRequest }
    } = formFromQuery;

    if (flow === availableFormTypes.serviceRequest) {
      return formsServiceRequest[0].node
        .childrenContentfulServiceRequestServiceRequestFormJsonNode[0];
    }

    const formsFilteredByHeader = forms.find(({ node }) => {
      return node.heading === heading;
    });

    const { node } = formsFilteredByHeader || {};

    const formBasedOnFlow: Record<string, any> = {
      campaign: node?.childContentfulOnboardingJourneyCampaignFormJsonNode,
      walkthrough:
        node?.childContentfulOnboardingJourneyWalkthroughFormJsonNode,
      onboarding: node?.childContentfulOnboardingJourneyOnboardingFormJsonNode
    };

    if (flow) {
      return formBasedOnFlow[flow];
    }
  }

  function initialize({ heading, flow, ...rest }: FormConfigType) {
    if (flow) {
      //select form based on heading and flow
      const form = getServiceForm({ heading, flow });
      updateFormConfig({
        ...rest,
        flow,
        form,
        heading,
        show: true
      });
    }
  }

  function getClickBasedOnAction({
    action = "",
    href,
    ...rest
  }: FormattedNotification) {
    // if the notification is a normal notification, don't do anything
    trackNotificationClick({ journey_type: action });
    switch (action) {
      case notificationActions.normal:
        return;
      case notificationActions.download: // if is download, generate the ICS
        generateIcs(rest.onboardingCall);
        return;
      case notificationActions.redirect: // if is redirect, redirect to the specified service
        navigate(`/${formattedLocale}/my-services?service=${href}`);
        return;
      case notificationActions.buy: // if is buy, redirect to the specified cybershop productId (productId from API or Contentful)
        navigate(`/${formattedLocale}/cybershop#${href}`, {
          state: {
            isNotification: true
          }
        });
        return;
      default:
        initialize({
          heading: rest.serviceName,
          flow: action
        });
    }
  }

  function getFlowMethod(
    flow: FormFlows = "campaign",
    formValues: any
  ): any | null {
    const functions: Record<FormFlows, any> = {
      serviceRequest: () =>
        snowApi.endpoints.createServiceRequest.initiate(formValues),
      vciso: () => snowApi.endpoints.requestVirtualAdvisor.initiate(formValues),
      campaign: () =>
        snowApi.endpoints.createCampaign.initiate({
          ...formValues,
          heading: formConfigState.heading
        }),
      onboarding: () => console.info("onboarding triggered", formValues),
      walkthrough: () => sessionStorage.setItem("did-walkthrough", "true")
    };

    if (!functions) return null;

    return functions[flow];
  }

  function finalize() {
    updateFormConfig(defaultFormConfig);
  }

  // this is the final step, it submits the form
  // we can send the request to any endpoint  from here

  async function submitAndFinalize(formValue: any | null) {
    if (!formConfigState?.flow) return;
    try {
      console.debug(`sending to ${formConfigState.flow} endpoint:`, formValue);

      const formFlowMethod = getFlowMethod(formConfigState?.flow, formValue);

      await store.dispatch(formFlowMethod());

      setIsSubmitted(true);
      return;
    } catch {
      dispatch(setFormError());
    }
  }

  return (
    <FormContext.Provider
      value={{
        initialize,
        getClickBasedOnAction,
        finalize,
        submitAndFinalize,
        formState: formConfigState,
        isSubmitted,
        setIsSubmitted,
        isModalOpen,
        setIsModalOpen,
        hasScore,
        setHasScore
      }}
    >
      <>
        {children}

        {formConfigState.show ? (
          <DynamicJourneyModal {...formConfigState} onClose={finalize} />
        ) : null}
      </>
    </FormContext.Provider>
  );
}
