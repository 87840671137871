import i18next, { use } from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { initReactI18next } from "react-i18next";

import { english, italian } from "@source-web/language-packs";

const resources = {
  en: {
    translation: english
  },
  it: {
    translation: italian
  }
};

use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: { escapeValue: false }
  });

export default i18next;
